/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import { request } from "../../../config/helpers/axios-instance";
import Modal from '../../../components/Modal'

/**
 * Checks if the element is visible in the scroll view
 * @param {*} container 
 * @param {*} ele 
 * @returns {boolean}
 */
const isVisible = (container, ele, direction) => {
    const containerRect = container.getBoundingClientRect();
    const { bottom, top } = ele.getBoundingClientRect();

    if (direction === 'top') {
        return top >= containerRect.top;
    } else {
        return bottom <= containerRect.bottom;
    }
}


const NameAndBatchModal = ({ type, updateItem, AddNewItem, prescriptionItemId, onClose, item }) => {

    const [medicines, setMedicines] = useState(null);
    const [batches, setBatches] = useState(null);

    const [searchedBatches, setSearchedBatches] = useState(null);
    const [currentMedicine, setCurrentMedicine] = useState(null);

    const medicineRef = useRef();
    const batchRef = useRef();

    const [medicineHovered, setMedicineHovered] = useState(null);
    const [batchHovered, setBatchHovered] = useState(null);

    /**
     * Saves the item
     * @param {*} close 
     */
    function saveItem(close) {

        if (currentMedicine?.item || currentMedicine?.batch) {
            if (type == 'update') {
                updateItem(prescriptionItemId, { 'item': currentMedicine?.item, 'itemBatch': currentMedicine?.batch?.batch == 'No-Batch' ? {} : currentMedicine?.batch })
            }
            else {
                AddNewItem(currentMedicine?.item.id, currentMedicine?.batch?.id)
            }
        }

        setCurrentMedicine(null);
        medicineRef.current.value = '';
        batchRef.current.value = '';
        setSearchedBatches(null);
        setBatches(null);
        setMedicines(null)

        if (close) {
            onClose();
        }
    }

    /**
     * Fetches the medicines by the search input
     * @param {String} name 
     * @returns {} 
     */
    const fetchMedicinesByName = async (name) => {
        if (!name) {
            setMedicines(null)
            return;
        }
        const response = await request({
            url: `/item/admin`,
            method: "GET",
            params: {
                name
            }
        })
        if (response?.data?.data?.rows.length !== 0) {
            setMedicineHovered({
                id: response?.data?.data?.rows[0]?.id,
                index: 0
            });
        }
        setMedicines(response?.data?.data?.rows);
    }

    /**
     * Fetches the medicines by the search input
     * @returns {} 
     */
    const fetchBatchesByMedicine = async () => {
        const response = await request({
            url: `/itemBatch/admin/inventory/item/${currentMedicine?.item?.id}`,
            method: "GET",
        })
        if (response.data.success) {
            let batches = response?.data?.data
            setBatches([{ batch: "No-Batch", id: 'No-Batch' }, ...batches])
            setSearchedBatches([{ batch: "No-Batch", id: 'No-Batch' }, ...batches])
            setBatchHovered({
                id: "No-Batch",
                index: 0
            });
        }
    }

    /**
     * Handles changes for medicine input
     * @param {String} name 
     */
    const handleNameChange = async (name) => {
        await fetchMedicinesByName(name)
        setBatches(null)
        setSearchedBatches(null)
        batchRef.current.value = ''
    }

    /**
     * Handles key press of Up, Down and Enter key for medicine and batch lists navigation
     * @param {*} e 
     */
    const keyPressHandler = (e) => {

        const { name } = e.target;

        let container;

        if (name === 'medicine-input') {
            container = document.getElementById('medicines');
        } else {
            container = document.getElementById('batches');
        }

        if (e.key === "ArrowDown") {
            if (name === 'medicine-input') {
                if (medicines && medicines?.length !== 0) {
                    setMedicineHovered((prevState) => {
                        if (prevState?.index !== medicines?.length - 1) {
                            const ele = document.getElementById(`medicine-#${medicines[(prevState?.index + 1) % medicines?.length]?.id}`);

                            if (!isVisible(container, ele, "bottom")) {
                                ele.scrollIntoView(false);
                            }

                            return {
                                id: medicines[(prevState?.index + 1) % medicines?.length]?.id,
                                index: (prevState?.index + 1) % medicines?.length
                            }
                        } else {
                            return prevState;
                        }
                    })
                }
            } else {
                if (searchedBatches && searchedBatches?.length !== 0) {
                    setBatchHovered((prevState) => {
                        if (prevState.index !== searchedBatches?.length - 1) {
                            const ele = document.getElementById(`batch-#${searchedBatches[(prevState?.index + 1) % searchedBatches?.length]?.id}`);

                            if (!isVisible(container, ele, "bottom")) {
                                ele.scrollIntoView(false);
                            }

                            return {
                                id: searchedBatches[(prevState?.index + 1) % searchedBatches?.length]?.id,
                                index: (prevState?.index + 1) % searchedBatches?.length
                            }
                        } else {
                            return prevState;
                        }
                    })
                }
            }

        }

        if (e.key === "ArrowUp") {
            if (name === 'medicine-input') {
                if (medicines && medicines?.length !== 0) {
                    setMedicineHovered((prevState) => {
                        if (prevState.index !== 0) {
                            const ele = document.getElementById(`medicine-#${medicines[(prevState.index - 1) % medicines?.length]?.id}`);

                            if (!isVisible(container, ele, "top")) {
                                ele.scrollIntoView(true);
                            }

                            return {
                                id: medicines[(prevState.index - 1) % medicines?.length]?.id,
                                index: (prevState.index - 1) % medicines?.length
                            }
                        } else {
                            return prevState;
                        }
                    })
                }
            } else {
                if (searchedBatches && searchedBatches.length !== 0) {
                    setBatchHovered((prevState) => {
                        if (prevState.index !== 0) {
                            const ele = document.getElementById(`batch-#${searchedBatches[(prevState.index - 1) % searchedBatches?.length]?.id}`);

                            if (!isVisible(container, ele, "top")) {
                                ele.scrollIntoView(true);
                            }

                            return {
                                id: searchedBatches[(prevState.index - 1) % searchedBatches?.length]?.id,
                                index: (prevState.index - 1) % searchedBatches?.length
                            }
                        } else {
                            return prevState;
                        }
                    })
                }
            }
        }

        if (e.key === "Enter") {
            if (name === 'medicine-input') {
                const currMed = medicines && medicines?.filter((item) => item.id === medicineHovered.id)[0]
                if (currMed) {
                    setCurrentMedicine({
                        item: currMed
                    })
                    medicineRef.current.value = currMed.name
                    handleNameChange(currMed.name)
                    batchRef.current.focus()
                }
            } else {
                const currBatch = batches && batches?.filter((item) => item.id === batchHovered.id)[0]
                if (currBatch) {
                    setCurrentMedicine((prevState) => {
                        return {
                            ...prevState,
                            batch: currBatch
                        }
                    })
                    batchRef.current.value = currBatch?.batch
                }
            }
        }
    }

    /**
     * Handles pressing of ctrl + enter -> saves the item
     * @param {*} e 
     */
    const ctrlAndEnterKeyPressHandler = (e) => {
        if (e.key === 'Enter' && e.ctrlKey) {
            if (type === 'Add') {
                document.getElementById('continue-adding').click();
                medicineRef.current.focus();
            } else {
                document.getElementById('save-and-close').click();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === "Escape") {
                onClose()
            }
        })
        return () => {
            document.removeEventListener('keydown', (e) => {
                if (e.key === "Escape") {
                    onClose()
                }
            })
        }
    }, [])

    useEffect(() => {
        if (item) {
            (
                async () => {
                    await handleNameChange(item.name)
                }
            )()
            medicineRef.current.value = item.name;
            setCurrentMedicine({
                item
            })
        }
    }, [item])

    useEffect(() => {
        (
            async () => {
                await fetchBatchesByMedicine();
            }
        )()
        setCurrentMedicine((prevState) => {
            return {
                ...prevState,
                batch: null
            }
        })
    }, [currentMedicine?.item])

    useEffect(() => {
        document.addEventListener('keydown', ctrlAndEnterKeyPressHandler);
        return () => {
            document.removeEventListener('keydown', ctrlAndEnterKeyPressHandler);
        }
    }, [batchRef]);


    return (
        <Modal onClose={onClose} title={"Name and Batch"} >
            <div className='flex flex-row gap-4 h-auto max-h-[250px]' >
                <div className='grow flex flex-col gap-1' >
                    <strong>{`Medicine: ${currentMedicine?.item?.name || ''}`}</strong>
                    <input autoComplete='off' name='medicine-input' onKeyDown={keyPressHandler} autoFocus ref={medicineRef} onChange={(e) => handleNameChange(e.target.value)} className='w-full rounded-md' type='text' />
                    <div id="medicines" className='flex flex-col gap-1 overflow-y-scroll h-full' >
                        {
                            medicines?.map((medicine, idx) => <p id={`medicine-#${medicine.id}`} className={`hover:cursor-pointer hover:bg-[#eee] ${medicineHovered?.id === medicine.id && medicineHovered?.index === idx && "bg-[#eee]"}`} onClick={() => {
                                setCurrentMedicine({ item: medicine })
                                medicineRef.current.value = medicine.name
                                handleNameChange(medicine.name)
                            }} key={medicine.id}  >{medicine.name}</p>)
                        }
                    </div>
                </div>
                <div className='border-solid border-[1px] border-[#ccc]' ></div>
                <div className='grow flex flex-col gap-1' >
                    <strong>{`Batch: ${currentMedicine?.batch?.batch || ''}`}</strong>
                    <input autoComplete='off' name='batch-input' onKeyDown={keyPressHandler} className={`w-full rounded-md ${!currentMedicine?.item ? 'opacity-20' : 'opacity-100'}`} type='text' ref={batchRef} onChange={(e) => {
                        if (currentMedicine?.item) {
                            let searchedResult = [
                                { 'batch': "No-Batch", 'id': "No-Batch" }
                            ]
                            let results = batches.filter((item) => item.batch.toLowerCase().includes(e.target.value.toLocaleLowerCase()))
                            searchedResult.push(...results)
                            setSearchedBatches(searchedResult)
                            if (searchedResult.length !== 0) {
                                setBatchHovered({
                                    id: searchedResult[0].id,
                                    index: 0
                                });
                            }
                        }
                    }} />
                    {
                        batches?.length !== 0 ? (
                            <div id='batches' className='flex flex-col gap-1 overflow-y-scroll h-full' >
                                {
                                    searchedBatches?.map((batch, idx) => {
                                        return (<p id={`batch-#${batch.id}`} onClick={() => {
                                            setCurrentMedicine((prevState) => { return { ...prevState, batch: batch } })
                                            batchRef.current.value = batch.batch
                                            setSearchedBatches([batch])
                                        }} className={`hover:cursor-pointer hover:bg-[#eee] ${batchHovered?.id === batch.id && batchHovered?.index === idx && "bg-[#eee]"}`} key={batch.id}  >{batch.batch}</p>)
                                    })
                                }
                            </div>
                        ) : (
                            <p>No batches found for this item</p>
                        )
                    }
                </div>
            </div>
            <hr />
            <div className='flex flex-row gap-[10px]' >
                <button id='save-and-close' onClick={() => saveItem(true)} className='p-2 mt-2 bg-[#049079] hover:bg-[#049079]/[0.85] rounded-md text-[#fff]' >{type !== 'Add' ? "Save and close (ctrl + enter)" : "Save and close"}</button>
                {
                    type === 'Add' && <button id='continue-adding' onClick={() => saveItem(false)} className='p-2 mt-2 bg-[#049079] hover:bg-[#049079]/[0.85] rounded-md text-[#fff]' >{`Continue adding (ctrl + enter)`}</button>
                }
            </div>
        </Modal>
    )
}

export default NameAndBatchModal;
