/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import { Textarea, Button } from "flowbite-react";
import React, { useContext, useRef } from "react";
import { updateCallback } from "../../../hooks/update-hooks/update-callbacks/update-callback";
import { CallBackContext } from "../../../contexts";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import TextareaAutosize from 'react-textarea-autosize';

function CommentModal ({ callback }) {
    const { refetch } = useContext(CallBackContext)

    // eslint-disable-next-line no-unused-vars
    const commentRef = useRef()
    const updateComment = (value) => {
        try {
            updateCallback({ 'comment': value }, callback.id)
            refetch();
        } catch (error) {
            toast.error(error)
        }
    }
    const debounceOnChange = React.useCallback(debounce(updateComment, 200), []);

    return <React.Fragment>
        <TextareaAutosize minRows={2} maxRows={4} className="border border-gray-200 text-left min-w-[250px] w-full rounded-md p-2 focus:outline-none" onChange={(e) => debounceOnChange(e.target.value)} defaultValue={callback.comment} />
    </React.Fragment >
}

export { CommentModal };