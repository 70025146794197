import React from "react";
const Constants = require("./Constants");
import PropTypes from "prop-types";

function Rows (props) {
    const { name, timings, quantity, pricePerPill, discount, amount } = props;

    return (
        <tr className="h-24 bg-white-50">
            <td className={`w-[160px] border border-solid`}>
                <div className="flex items-start px-4 flex-col justify-start text-left">
                    <h2 className="text-lg font-semibold text-left font-sans mb-3">
                        {name}
                    </h2>
                </div>
            </td>

            {Object.keys(timings).map((key) => {
                return (
                    <td className="w-[200px] border border-solid" key={key}>
                        {timings[key].length > 0 && (
                            <div className="flex items-center w-full justify-center font-sans">
                                <div
                                    className={
                                        "text-left h-16 w-20  border border-solid text-white-50 font-bold text-lg "
                                    }
                                    style={{
                                        border: `1px solid ${Constants.COLORS[key]}`,
                                    }}>
                                    <div
                                        className="h-8 flex items-center justify-center"
                                        style={{
                                            backgroundColor: `${Constants.COLORS[key]}`,
                                        }}>
                                        <p className="text-black text-sm mb-3 font-sans">
                                            {timings[key][0].time}
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center h-8">
                                        <p className="text-black mb-3 text-sm font-sans">
                                            {timings[key][0].meal}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </td>
                );
            })}

            <td className="w-[50px] border border-solid">
                <div className="flex items-center w-full justify-center">
                    <div className="p-2 text-left text-xl px-4 w-16 font-sans">
                        {quantity}
                    </div>
                </div>
            </td>
            <td className="w-[50px] border border-solid">
                <div className="flex items-center w-full justify-start">
                    <div className="p-2 text-left text-sm font-bold w-16 font-sans">
                        ₹{pricePerPill}
                    </div>
                </div>
            </td>
            <td className="w-[30px] border border-solid">
                <div className="flex items-center w-full justify-center">
                    <div className="text-left text-sm font-bold  font-sans">
                        {discount}
                    </div>
                </div>
            </td>
            <td className="w-[100px] border border-solid">
                <div className="flex items-center w-full justify-start">
                    <div className="p-2 text-left text-sm font-bold  w-16 font-sans">
                        ₹{amount}
                    </div>
                </div>
            </td>
        </tr>
    );
}

Rows.propTypes = {
    name: PropTypes.string,
    timings: PropTypes.object,
    quantity: PropTypes.any,
    pricePerPill: PropTypes.any,
    discount: PropTypes.any,
    amount: PropTypes.any,
};
export { Rows };
