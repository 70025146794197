/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import MutlipleAddresses from './multipleAddresses';

import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";

export default function MutlipleAddressPrint (props) {
    let componentRef = useRef();

    return (
        <div className="h-[85vh]">
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button id="print-button" className=" bg-blue-500 text-[white] rounded-sm px-1 ">Print this out!</button>}
                    content={() => componentRef.current}
                />
                <div ref={componentRef}>
                    {MutlipleAddresses(props)}
                </div>
        </div>
    );
}

MutlipleAddressPrint.propTypes = {
    addresses: PropTypes.any
}