/* eslint-disable */

import moment from "moment";

const getDateDiffInWholeNumber = (a, b) => {
    if (moment(a).diff(moment(b), 'days', true) < 0) {
        return -1 * Math.ceil(Math.abs(moment(a).diff(moment(b), 'days', true)))
    } else {
        console.log(Math.ceil(Math.abs(moment(a).diff(moment(b), 'days', true))))
        return Math.ceil(Math.abs(moment(a).diff(moment(b), 'days', true)))
    }
}

/**
 * Check if data is empty
 *
 * @param data
 * @returns {boolean}
 */
const empty = (data) => {
    if (typeof (data) === 'number' || typeof (data) === 'boolean') {
        return false;
    }
    if (typeof (data) === 'undefined' || data === null) {
        return true;
    }
    if (typeof (data.length) !== 'undefined') {
        return data.length === 0;
    }
    for (let i in data) {
        if (data.hasOwnProperty(i)) {
            return false;
        }
    }
    return true;
};

/**
 * Get data for the PRX table
 * @param {*} meds
 * @returns
 */
export const GetPRXTableData = (meds) => {
    let adminTimes = [];
    let discount = meds.MedicineDiscount || 10;

    for (let med of meds) {
        for (let admin of med.AdministratorDetails) {
            if (!adminTimes.includes(admin.AdminTime)) {
                adminTimes.push(admin.AdminTime);
            }
        }
    }

    let headers = [
        "Medicine",
        ...adminTimes,
        "Qty",
        "Per Pill",
        "Discount",
        "Total",
    ];

    let rows = [];

    for (let med of meds) {
        let row = {
            Medicine: {
                name: med.DrugName,
                time:
                    med.AdministratorDetails[0]?.AdminDescription ||
                    "As per prescription",
            },
            Qty: getMedicineQuantity(med),
            "Per Pill": parseFloat(med.DrugPrice / med.UnitPerBlister).toFixed(2),
            Discount: discount + "%",
        };

        row.Total = row["Qty"] * row["Per Pill"] * (1 - discount / 100);

        for (let adminTime of adminTimes) {
            row[adminTime] = "";
        }

        for (let admin of med.AdministratorDetails) {
            if (adminTimes.length > 3) {
                row[admin.AdminTime] =
                    med.Quantity > 1 ? med.Quantity + " Tabs" : med.Quantity + " Tab";
            } else {
                row[admin.AdminTime] =
                    med.Quantity > 1
                        ? med.Quantity + " Tabs"
                        : med.Quantity + " Tab";
            }
        }

        let notNeeded = ["Medicine", "Qty", "Per Pill", "Discount", "Total"];
        let adminArray = [];

        for (let [key, value] of Object.entries(row)) {
            if (!notNeeded.includes(key)) {
                adminArray.push(value);
            }
        }

        row.adminArray = adminArray;

        rows.push(row);
    }

    return { rows, headers };
};

/**
 * Get data for the PRX table categorically (based on time categories - Morning, Afternoon, Evening)
 * This function is limited to medicines being taken max at 4 times a day
 * @param {*} meds
 * @returns
 */
export const GetPRXTableDataCategorically = (meds) => {
    let finalTotal = parseFloat(0);
    let adminTimes = [];
    let timeCategories = ["Morning", "Afternoon", "Evening", "Night"];
    if (meds) {
        for (let med of meds) {
            for (let admin of med?.AdministratorDetails) {
                if (!adminTimes.includes(admin.AdminTime)) {
                    adminTimes.push(admin.AdminTime);
                }
            }
        }
    }

    let headers = [
        "Medicine",
        ...adminTimes,
        "Qty",
        "Per Pill",
        "Discount",
        "Total",
    ];

    let rows = [];
    if (meds) {
        for (let med of meds) {
            let discount = med.MedicineDiscount || 10;

            let row = {
                Medicine: {
                    name: med.DrugName,
                    time:
                        med.AdministratorDetails[0]?.AdminDescription ||
                        "As per prescription",
                },
                Qty: getMedicineQuantity(med),
                Discount: discount + "%",
            };

            if (med.Pouch) {
                row["Per Pill"] = parseFloat(
                    med.DrugPrice / med.UnitPerBlister
                ).toFixed(2);
            } else {
                row["Per Pill"] = parseFloat(med.DrugPrice).toFixed(2);
            }

            row.Total = row["Qty"] * row["Per Pill"] * (1 - discount / 100);

            finalTotal = finalTotal + row.Total;

            for (let adminTime of adminTimes) {
                row[adminTime] = "";
            }

            for (let admin of med.AdministratorDetails) {
                if (adminTimes.length > 3) {
                    row[admin.AdminTime] =
                        med.doseSize > 1 ? med.doseSize + " Tabs" : med.doseSize + " Tab";
                } else {
                    row[admin.AdminTime] =
                        med.doseSize > 1
                            ? med.doseSize + " Tablets"
                            : med.doseSize + " Tablet";
                }
            }

            let notNeeded = ["Medicine", "Qty", "Per Pill", "Discount", "Total"];
            let adminObj = {};

            for (let cat of timeCategories) {
                adminObj[cat] = {};
            }

            for (let [key, value] of Object.entries(row)) {
                if (!notNeeded.includes(key) && value != "") {
                    adminObj[getTimeCategory(key)] = {
                        time: tConvert(key),
                        quantity: value,
                    };
                }
            }

            row.adminObj = adminObj;

            rows.push(row);
        }
    }

    return { rows, headers, total: parseFloat(finalTotal).toFixed(2) };
};

function arraysEqual (a1, a2) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) == JSON.stringify(a2);
}

/**
 * Get the data for table DRX
 * @param {*} meds
 */

export const getDRXTableDataCategorically = (meds) => {
    let adminTimes = [];
    let timeCategories = ["Morning", "Afternoon", "Evening", "Night"];
    if (meds) {
        for (let med of meds) {
            if (med.startDate === null || med.startDate === undefined) continue;
            for (let admin of med?.time) {
                if (!adminTimes.includes(admin)) {
                    adminTimes.push(admin);
                }
            }
        }
    }

    let headers = ["Medicine", ...timeCategories, "Qty", "Details"];

    let rows = [];
    if (meds) {
        for (let med of meds) {
            let existingRow = {};
            let row = {};

            let medicineExists = false;
            let samePeriod = false;
            if (med.startDate === null || med.startDate === undefined) continue;

            for (let i = 0; i < rows.length; i++) {
                if (med.item.name.toUpperCase() === rows[i].Medicine.name.toUpperCase()) {
                    if (med.startDate === rows[i].startDate && med.duration === rows[i].duration) {
                        samePeriod = true
                        rows[i].Qty = rows[i].Qty + getMedicineQuantity(med);
                        medicineExists = true;
                        existingRow = { ...rows[i] }
                        rows.splice(i, 1);
                        break;
                    }

                    if ((rows[i].duration === 1 || med.duration === 1) &&
                        (rows[i].doseSize === med.doseSize) &&
                        (arraysEqual(rows[i].time, med.time)) &&
                        (arraysEqual(rows[i].meal, med.meal))) {

                        if (rows[i].duration === 1) {
                            let startDateDiff = getDateDiffInWholeNumber(rows[i].startDate, med.startDate);
                            let endDateDiff = getDateDiffInWholeNumber(rows[i].startDate, med.endDate);

                            if ([1, -1].includes(startDateDiff)) {
                                rows[i].startDate = moment(med.startDate).add(startDateDiff, 'days');
                                rows[i].endDate = moment(med.endDate);
                            }
                            if ([1, -1].includes(endDateDiff)) {
                                rows[i].startDate = moment(med.startDate);
                                rows[i].endDate = moment(med.endDate).add(endDateDiff, 'days');
                            }

                            rows[i].duration = med.duration;
                        }
                        if (med.duration === 1) {
                            let startDateDiff = getDateDiffInWholeNumber(med.startDate, rows[i].startDate);
                            let endDateDiff = getDateDiffInWholeNumber(med.startDate, rows[i].endDate)

                            if ([1, -1].includes(startDateDiff)) {
                                rows[i].startDate = moment(rows[i].startDate).add(startDateDiff, 'days');
                                rows[i].endDate = moment(rows[i].endDate);
                            }
                            if ([1, -1].includes(endDateDiff)) {
                                rows[i].startDate = moment(rows[i].startDate);
                                rows[i].endDate = moment(rows[i].endDate).add(endDateDiff, 'days');
                            }

                            rows[i].duration = rows[i].duration;
                        }
                        rows[i].Qty = rows[i].Qty + getMedicineQuantity(med);
                        medicineExists = true;
                        existingRow = { ...rows[i] }
                        rows.splice(i, 1);
                        break;
                    }
                }
            }

            if (medicineExists) {
                row = { ...existingRow }
            }
            else {
                row = {
                    Medicine: {
                        name: med.item.name.toUpperCase(),
                        time:
                            `${med.meal[0]} Meal` ||
                            "As per prescription",
                    },
                    Details: {
                        BatchNumber: med.itemBatch?.batch || "",
                        Manufacturer: med.itemBatch?.manufacture || "",
                        ExpiryDate: med.itemBatch?.expireDate || "",
                    },
                    Qty: getMedicineQuantity(med),
                    adminObj: {},
                    startDate: med.startDate,
                    endDate: med.endDate,
                    duration: med.duration,
                    doseSize: med.doseSize,
                    time: med.time,
                    meal: med.meal
                };
            }

            for (let adminTime of adminTimes) {
                if (row.hasOwnProperty(adminTime)) {
                    continue;
                }
                row[adminTime] = "";
            }
            for (let admin of med.time) {
                let currentDose = med.doseSize
                if (!empty(row[admin]) && medicineExists && samePeriod) {
                    currentDose = parseFloat(med.doseSize) + parseFloat(row[admin].split(' ')[0])
                }

                if (adminTimes.length > 3) {
                    row[admin] =
                        currentDose > 1 ? currentDose + " Tabs" : currentDose + " Tab";
                } else {
                    row[admin] =
                        currentDose > 1
                            ? currentDose + " Tabs"
                            : currentDose + " Tab";
                }
            }

            let notNeeded = ["Medicine", "Details", "Qty", "startDate", "duration", "adminObj", "endDate", 'duration', 'doseSize', 'time', 'meal'];

            let adminObj = {};

            for (let cat of timeCategories) {
                adminObj[cat] = [];
            }
            for (let [key, value] of Object.entries(row)) {
                if (!notNeeded.includes(key) && value != "") {
                    adminObj[getTimeCategory(key)].push({
                        time: tConvert(key),
                        quantity: value,
                    })
                }
            }

            row.adminObj = adminObj;
            rows.push(row);
        }
    }

    rows = rows.sort((a, b) => a.Medicine.name.localeCompare(b.Medicine.name))

    return { rows, headers };
};

/**
 * Get the data for table DRX
 * @param {*} meds
 */
export const getDRXTableData = (meds) => {
    let adminTimes = [];
    for (let med of meds) {
        for (let admin of med.AdministratorDetails) {
            if (!adminTimes.includes(admin.AdminTime)) {
                adminTimes.push(admin.AdminTime);
            }
        }
    }

    let headers = ["Medicine", ...adminTimes, "Qty", "Details"];

    let rows = [];

    for (let med of meds) {
        let row = {
            Medicine: {
                name: med.DrugName,
                time: med.AdministratorDetails[0].AdminDescription,
            },
            Details: {
                BatchNumber: med.DrugBatch,
                Manufacturer: med.DrugManufacturer,
                ExpiryDate: med.DrugExpiryDate,
            },
            Qty: getMedicineQuantity(med),
        };

        for (let adminTime of adminTimes) {
            row[adminTime] = "";
        }

        for (let admin of med.AdministratorDetails) {
            if (adminTimes.length > 3) {
                row[admin.AdminTime] =
                    med.Quantity > 1 ? med.Quantity + " Tabs" : med.Quantity + " Tab";
            } else {
                row[admin.AdminTime] =
                    med.Quantity > 1
                        ? med.Quantity + " Tabs"
                        : med.Quantity + " Tab";
            }
        }

        let notNeeded = ["Medicine", "Details", "Qty"];
        let adminArray = [];

        for (let [key, value] of Object.entries(row)) {
            if (!notNeeded.includes(key)) {
                adminArray.push(value);
            }
        }

        row.adminArray = adminArray;

        rows.push(row);
    }

    return { rows, headers };
};

/**
 * Get the category of time (Morning,Afternoon, Evening or Night) based on the hour
 * @param {*} time hour and minute to be checked (In 24 hours format)
 * @returns
 */
const getTimeCategory = (time) => {
    let timeStamp = time.split(":");
    let hour = parseInt(timeStamp[0]);
    switch (true) {
        case hour > 4 && hour < 12:
            return "Morning";
        case hour >= 12 && hour < 16:
            return "Afternoon";
        case hour >= 16 && hour < 19:
            return "Evening";
        case (hour >= 19 && hour < 24) || (hour >= 0 && hour <= 4):
            return "Night";
        default:
            return "";
    }
};

/**
 * Get Quantity for Days type medicines
 * @param {*} startDate Drug start date
 * @param {*} duration Duration for which the drug is to be taken
 * @param {*} quantity Daily Quantity of the drugs
 * @param {*} days Days array as defined
 * @returns
 */

function getDaysQuantity (med) {
    const duration = parseInt(med.duration);
    const doseSize = parseFloat(med.doseSize);

    let date = new Date(med.startDate);
    let startDay = date.getDay();
    let dayData = [med.sunday, med.monday, med.tuesday, med.wednesday, med.thursday, med.friday, med.saturday];

    let dayNum = startDay;
    let finalQuantity = 0;

    for (let i = 0; i < duration; i++) {
        if (dayData[dayNum]) {
            finalQuantity = finalQuantity + doseSize;
        }
        dayNum = dayNum + 1;
        if (dayNum > 6) {
            dayNum = 0;
        }
    }

    return finalQuantity * med?.time?.length;
}

/**
 * Get the quantity for alternate type drug
 * @param {*} duration Duration for the drug
 * @param {*} quantity Quantity for which the drug is to be taken
 * @returns {Float} finalQuantity
 */

export const getAlternateQuantity = (duration, quantity) => {
    let flag = true;
    let finalQuantity = 0;
    quantity = parseFloat(quantity);

    for (let i = 0; i < duration; i++) {
        if (flag) {
            finalQuantity = finalQuantity + quantity;
            flag = false;
            continue;
        }
        if (!flag) {
            flag = true;
        }
    }

    return finalQuantity;
};

/**
 * Get function for total medicine quantity
 * @param {*} med Medicine Object
 * @returns
 */


export const getMedicineQuantity = (med) => {
    let response = 0;

    if (!med.itemBatch?.inPouch) {
        return parseFloat(med.doseSize);
    }

    switch (med.typeOfAdmin) {
        case "DAYS": {
            response = getDaysQuantity(med);
            break;
        }
        case "ALTERNATE": {
            response = getAlternateQuantity(med.duration, med.doseSize)
            break;
        }
        case "DATE": {
            response = parseFloat(med.doseSize);
            break;
        }
    }

    return response;
};

/**
 * Convert time to AM PM format
 * @param {*} time
 * @returns
 */
function tConvert (time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time,
    ];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}
