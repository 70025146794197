/* eslint-disable no-unused-vars */
import React from "react";
import { HiUserCircle } from "react-icons/hi";
import { GiBoxUnpacking } from "react-icons/gi";
import { MdInventory2 } from "react-icons/md";
import { IoCloudUpload } from "react-icons/io5";
import { HiClipboardCheck } from "react-icons/hi";
import { MdPayments } from "react-icons/md";
import { MdFileCopy } from "react-icons/md";
import { SiProgress } from "react-icons/si";
import { MdLocalShipping } from "react-icons/md";
import { NavLink, Outlet } from "react-router-dom";
import { RouteConstants } from "../../config/constants/route";
import ConfirmDigitalRx from "./confirm-digital-rx";
import ConfirmPayment from "./confirm-payment";
import InventoryCheck from "./inventory-check";
import OcsFile from "./ocs-file";
import PackagingnDispenser from "./packaging-and-dispenser";
import ProcessingOrder from "./processing-order";
import QualifiedLead from "./qualified-lead";
import Shipped from "./shipped";
import UploadPrx from "./upload-prx";
import MesssageDialog from "../../layouts/Dashboard/components/toast";

function TrackOrders () {
    const tabs = [
        {
            title: "Qualified Lead",
            icon: HiUserCircle,
            navigator: RouteConstants.QUALIFIED_LEAD,
        },
        {
            title: "Inventory Check",
            icon: MdInventory2,
            navigator: RouteConstants.INVENTORY_CHECK,
        },
        {
            title: "Upload PRX",
            icon: IoCloudUpload,
            navigator: RouteConstants.UPLOAD_PRX,
        },
        {
            title: "Confirm Digital Rx",
            icon: HiClipboardCheck,
            navigator: RouteConstants.CONFIRM_DIGITAL_RX,
        },
        {
            title: "Confirm Payment",
            icon: MdPayments,
            navigator: RouteConstants.CONFIRM_PAYMENT,
        },
        {
            title: "OCS File",
            icon: MdFileCopy,
            navigator: RouteConstants.OCS_FILE,
        },
        {
            title: "Processing Order",
            icon: SiProgress,
            navigator: RouteConstants.PROCESSING_ORDER,
        },
        {
            title: "Packaging & Dispenser",
            icon: GiBoxUnpacking,
            navigator: RouteConstants.PACKAGING_AND_DISPENSER,
        },
        {
            title: "Shipped",
            icon: MdLocalShipping,
            navigator: RouteConstants.SHIPPED,
        },
    ];

    return (
        <>
            <MesssageDialog />
            <div className="bg-white-50 h-full p-3 pt-2">
                <div className="p-2 mt-2 bg-black w-full h-14 rounded-2xl grid grid-cols-9 text-left">
                    {tabs.map((tab, index) => (
                        <NavLink
                            key={index}
                            to={tab.navigator}
                            // activeClassName="bg-cod-gray-900 rounded-2xl flex justify-center items-center font-sans text-white-50 px-2 text-xs"
                            // set the active class name to the link
                            className={(data) => {
                                return data.isActive
                                    ? "bg-elm-700 rounded-2xl flex justify-center items-center font-sans text-white-50 px-2 text-xs gap-1"
                                    : " rounded-2xl flex justify-center items-center font-sans text-white-50 px-2 text-xs gap-1";
                            }}>
                            <tab.icon className="text-2xl" />
                            {tab.title}
                        </NavLink>
                    ))}
                </div>

                <div>
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export {
    TrackOrders,
    QualifiedLead,
    InventoryCheck,
    UploadPrx,
    ConfirmDigitalRx,
    ConfirmPayment,
    OcsFile,
    ProcessingOrder,
    PackagingnDispenser,
    Shipped,
};
