import jsonData from "./data.json"
import React from 'react';
import randomcolor from 'randomcolor';
import { Line } from 'react-chartjs-2';
import moment from 'moment';


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

let randomColors = []

const getRandomColors = (count = 1) => {
    let newColors = [];
    for (let i = 0; i < count; i++) {
        let newColor = randomcolor();
        while (randomColors.includes(newColor)) newColor = randomcolor();
        newColors.push(newColor);
        randomColors.push(newColor);
    }

    return newColors;
}

let cohortData = jsonData.cohort;
let startDate = moment("2022-12-01")
let endDate = moment('2023-10-01')

let labels = Object.keys(cohortData).filter((label) => {
    let date = moment(label, 'YY-MMM');
    if (date > startDate && date < endDate) return label
});
let datasets = []
let colors = getRandomColors(labels.length);

for (let i = 0; i < labels.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(cohortData, labels[i])) continue;

    let dataset = {
        label: labels[i],
        data: [],
        borderColor: colors[i],
        backgroundColor: colors[i],
    }

    let currentData = cohortData[labels[i]].rate_percent;

    for (let month of labels) {
        if (!Object.prototype.hasOwnProperty.call(currentData, month) || currentData[month] === '0.00') {
            let labelDate = moment(labels[i], 'YY-MMM')
            let monthDate = moment(month, 'YY-MMM')
            if (monthDate < labelDate) dataset.data.push(null)
            else dataset.data.push(null);
            continue
        }

        dataset.data.push(parseFloat(currentData[month]));
    }

    datasets.push(dataset)
}


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true
            },
            title: {
                color: 'black'
            }
        },
        title: {
            display: true,
            text: 'Cohort',
        },
    },
    scales: {
        x: {
            border: {
                display: false
            },
            grid: {
                display: false
            }
        },
        y: {
            border: {
                display: false
            },
            grid: {
                display: false
            }
        }
    }
};

export const data = {
    labels,
    datasets
};

function Cohort () {

    return (
        <>
            <div className="w-full h-[40vh] p-2 shadow-soft-xl rounded-xl bg-white"
                style={{ background: "#222" }}
            >
                <Line options={options} data={data} />
            </div>
        </>
    )
}

export { Cohort }