/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './MainFields.scss'
import moment from 'moment';
import { debounce } from 'lodash';
import { getOrderStatuses } from '../../../utils/helpers';

const MainFields = ({ orderType, messages, status, updateOrderData, startDate, prescriptionDate, endDate, noOfDays, drName, userNotes }) => {
    const debounceOnChange = React.useCallback(debounce(updateOrderData, 200), []);
    const orderStatuses = getOrderStatuses();
    const [notes, setNotes] = useState('');
    useEffect(() => {
        setNotes(userNotes)
    }, [userNotes])

    return (
        <div className='bg-[white] px-5 py-5 rounded-lg border border-gray-300' >
            {
                messages?.map((message, key) => {
                    return (
                        <p key={key} className='text-[red] text-bold' > {message.text}</p>
                    )
                })
            }

            <br />
            <div className='relative bg-[white] rounded-lg flex flex-row flex-wrap gap-5' >
                <div className='flex flex-col flex-grow' >
                    <label className='main-fields_label' >Doctor Name</label>
                    <input type='text' name='drName' value={drName} onChange={(e) => updateOrderData(e.target.value, e.target.name)} className='rounded-md main-fields_input' />
                </div>
                <div className='flex flex-col flex-grow' >
                    <label className='main-fields_label' >Prescription Date</label>
                    <input type='date' name='date' value={moment(prescriptionDate).format('YYYY-MM-DD')} onChange={(e) => updateOrderData(moment(e.target.value).format(), e.target.name)} className='rounded-md main-fields_input' />
                </div>
                <div className='flex flex-col flex-grow' >
                    <label className='main-fields_label' >Start Date</label>
                    <input type='date' name='startDate' value={startDate} onChange={(e) => updateOrderData(e.target.value, e.target.name)} className='rounded-md main-fields_input' />
                </div>
                <div className='flex flex-col flex-grow' >
                    <label className='main-fields_label' >Duration</label>
                    <input type='number' min={1} name='noOfDays' value={noOfDays} onChange={(e) => updateOrderData(parseInt(e.target.value), e.target.name)} className='rounded-md main-fields_input' />
                </div>
                <div className='flex flex-col flex-grow' >
                    <label className='main-fields_label' >End Date</label>
                    <input type='date' name='endDate' disabled value={endDate} className='rounded-md main-fields_input opacity-50' />
                </div>
                <div className='flex flex-col flex-grow' >
                    <label className='main-fields_label' >Status</label>
                    <select className="rounded-lg h-10" name='status' value={status} onChange={(e) => updateOrderData(e.target.value, e.target.name)} >
                        {
                            orderStatuses && orderStatuses[orderType].map((status) => {
                                return (
                                    <option key={status} value={status}>{status}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className='flex flex-col flex-grow mt-2' >
                <label className='main-fields_label' >User Notes</label>
                <textarea name='notes' rows={4} defaultValue={notes} onChange={(e) => debounceOnChange(e.target.value, e.target.name)} className='rounded-md' />
            </div>
        </div>
    )
}

export default MainFields;