import React, { useMemo } from "react";
import TableLayout from "./Table-layout";
import PropTypes from "prop-types";
import { useSortBy, useTable } from "react-table";
import "../../../styles/Tableinstance.scss";

// eslint-disable-next-line no-undef
const Constants = require("./Constants");

function TableInstance({ tableData, pageCount, setPageSort, pageSort }) {

    const [columns, data] = useMemo(() => {
        let columns = Constants.COLUMNS;
        return [columns, tableData];
    }, [tableData]);

    const tableInstance = useTable({
        columns, data, initialState: { sortBy: pageSort },
        manualSortBy: true, autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    }, useSortBy
    );
    return (
        <>
            <TableLayout {...tableInstance}
                pageCount={pageCount}
                setPageSort={setPageSort}
            />
        </>
    );
}

export default TableInstance;

TableInstance.propTypes = {
    tableData: PropTypes.array.isRequired,
    pageCount: PropTypes.number,
    setPageIndex: PropTypes.func,
    Index: PropTypes.number,
    name: PropTypes.string,
    setName: PropTypes.any,
    setPageSort: PropTypes.any,
    pageSort: PropTypes.any
};
