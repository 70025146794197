const QNT_INDEX = {
    1: "010",
    2: "020",
    0.25: "002",
    0.33: "003",
    0.5: "005",
    0.66: "006",
    0.75: "007",
}
const DAYS = {
    "Monday": false,
    "Tuesday": false,
    "Wednesday": false,
    "Thursday": false,
    "Friday": false,
    "Saturday": false,
    "Sunday": false
}
const fields = {
    "PatientName": 30,
    "PatientID": 15,
    "NursingHomeCode": 20,
    "RoomNumber": 50,
    "BedNumber": 50,
    "WardNumber": 50,
    "RXNumber": 20,
    "RXDateTime": 20,
    "Quantity": 3,
    "DrugID": 15,
    "StartDate": 6,
    "StopDate": 6,
    "AdminTime": 8,
    "AdminDescription": 50,
    "Random1": 50,
    "Random2": 50,
    "Random3": 50,
    "Random4": 50,
    "Random5": 50,
    "BatchNumber": 50
}

const ALLOWED_TYPES = ["DATE", "ALTERNATE", "DAYS"];

const ALLOWED_MEALS = ["BEFORE", "AFTER"];

export { DAYS, ALLOWED_TYPES, QNT_INDEX, fields, ALLOWED_MEALS }
