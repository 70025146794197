import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MedicinesRowData } from "../../../../contexts";
import _ from "lodash";
import { useNavigate } from "react-router";
const Constants = import("./Constants");
const GenerateType = ({ type, data }) => {
    const daysArray = _.pick(data, Constants.days);

    if (type === Constants.TYPE_DAYS) {
        return (
            <div className="flex gap-1 ">
                {Object.keys(daysArray).map((day, index) => (
                    <div key={index} className="flex justify-center w-full">
                        <div
                            className={
                                daysArray[day] === true ? "font-bold text-green-500" : ""
                            }>
                            {day?.split("")[0].toUpperCase()}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    if (type === Constants.TYPE_DATE) {
        return <div>{new Date(data?.startDate?.split("T")[0]).toDateString()}</div>;
    }
    if (type === Constants.TYPE_ALTERNATE) {
        return <div>Alternate</div>;
    }
};

function TableLayout ({
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    changeRightBarOpen,
}) {
    const navigate = useNavigate();
    const onNameClick = (row) => {
        navigate(`/item-details/${row.item.id}`);
    };

    const { setRowData } = useContext(MedicinesRowData);
    return (
        <div className="block bg-[white]  rounded-lg mt-4  dark:hover:bg-gray-700">
            <table
                key="future-orders"
                {...getTableProps()}
                className="w-full mt-4 p-4 bg-white-50 rounded-2xl font-sans table-auto shadow-soft-xl">
                <thead className="text-left h-16 border-b-2 rounded-2xl  border-gray-100">
                    {headerGroups.map((headerGroup, id) => (
                        <tr
                            key={id}
                            {...headerGroup.getHeaderGroupProps()}
                            className=" h-16  font-sans">
                            {headerGroup.headers.map((column, id) => (
                                <th
                                    className={
                                        column.Header === "Medicine"
                                            ? "pl-3 text-left font-sans"
                                            : "pl-3 font-sans"
                                    }
                                    key={id}
                                    {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                onClick={() => {
                                    setRowData(row.original);
                                    changeRightBarOpen();
                                }}
                                className="text-left border-b border-gray-100 h-14 hover:bg-slate-200 pl-3 font-sans"
                                key={rowIndex}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <React.Fragment key={cellIndex}>
                                            {cell.column.Header === "Start Date" ||
                                                cell.column.Header === "End Date" ? (
                                                <td
                                                    key={cellIndex}
                                                    {...cell.getCellProps()}
                                                    className="px-2 pl-3 font-sans">
                                                    {cell?.value?.split("T")[0]}
                                                </td>
                                            ) : cell.column.Header === "Type" ? (
                                                <td
                                                    key={cellIndex}
                                                    {...cell.getCellProps()}
                                                    className="px-2 pl-3 font-sans">
                                                    <GenerateType
                                                        type={cell.value}
                                                        data={cell.row.original}
                                                    />
                                                </td>
                                            ) : (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={
                                                        cell.column.Header === "Medicine"
                                                            ? "text-elm-500 px-2 pl-3 text-left font-sans font-semibold uppercase text-underline underline cursor-pointer"
                                                            : "px-2 pl-3 font-sans"
                                                    }
                                                    onClick={() => {
                                                        onNameClick(cell.row.original)
                                                    }
                                                    }
                                                    key={cellIndex}>
                                                    {cell.render("Cell")}
                                                </td>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default TableLayout;

// props validation
TableLayout.propTypes = {
    getTableProps: PropTypes.any,
    getTableBodyProps: PropTypes.func.isRequired,
    headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    prepareRow: PropTypes.func.isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeRightBarOpen: PropTypes.func.isRequired,
};

GenerateType.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
};
