const COLUMNS = [
    {
        Header: "#",
        accessor: "id",
    },
    {
        Header: "Mnemonic",
        accessor: "mnemonic",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Manufacture",
        accessor: "manufacture",
    },
    {
        Header: "Inventory Id",
        accessor: "inventoryId",
    },
    {
        Header: "Generic Name",
        accessor: "genericName",
    },
    {
        Header: "Medicine Type",
        accessor: "medicineType",
    },
];


export { COLUMNS };