import { request } from "../../../config/helpers/axios-instance";

const deleteCallback = async ( id) =>

    await request({
        url: `requestCallback/admin/` + id,
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },


    })
export { deleteCallback };