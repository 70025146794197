import React, { useEffect } from 'react';
import randomcolor from 'randomcolor';
import { Bar, Line } from 'react-chartjs-2';
import { request } from "../../../../../config/helpers/axios-instance";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import { useState } from "react";

let randomColors = []

const getRandomColors = (count = 1) => {
    let newColors = [];
    for (let i = 0; i < count; i++) {
        let newColor = randomcolor();
        while (randomColors.includes(newColor)) newColor = randomcolor({
            luminosity: 'light',
            hue: 'random'
        });
        newColors.push(newColor);
        randomColors.push(newColor);
    }

    return newColors;
}

let colors = getRandomColors(2);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,

);

export const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true
            },
            title: {
                color: 'black'
            }
        }
    },
    scales: {
        x: {
            border: {
                display: false
            },
            grid: {
                display: false
            }
        },
        y: {
            border: {
                display: false
            },
            grid: {
                display: false
            }
        }
    }
};

ChartJS.defaults.color = '#FFFFFF';

function UserAges () {
    const [ageData, setAgeData] = useState(() => { return { ages: {} } });

    const getAgeData = async () => {
        let response = await request({
            method: 'GET',
            url: `stats/admin/users/ageOverview`
        })
        if (response?.data?.success) {
            setAgeData(response.data.data.patientAgeStats)
        }
    }

    useEffect(() => {
        getAgeData();
    }, []);

    let data = {
        labels: Object.keys(ageData?.ages).map((elem) => elem),
        datasets: [
            {
                label: "Age Count",
                data: Object.keys(ageData?.ages).map((elem) => ageData?.ages[elem]),
                borderColor: colors[0],
                backgroundColor: colors[0]
            }
        ]
    }

    let categoryData = {
        labels: ageData?.categories,
        datasets: [
            {
                label: "Age Count [Categorical]",
                data: ageData?.categories?.map((elem) => elem in ageData ? ageData[elem] : 0),
                borderColor: colors[1],
                backgroundColor: colors[1]
            }
        ]
    }

    return (
        <>
            <div className="mt-5">
                <label className='flex text-xl justify-end '>Average Age: {ageData?.average?.toFixed(2)}</label>

                <div className="flex flex-row gap-5" >
                    <div className="w-full h-[35vh] p-2 shadow-soft-xl rounded-xl text-white mt-5"
                        style={{ background: "#222" }}
                    >
                        < Line options={options} data={data} />
                    </div>
                    <div
                        className="w-full h-[35vh] p-2 shadow-soft-xl rounded-xl text-white mt-5"
                        style={{ background: "#222" }}
                    >
                        <Bar options={options} data={categoryData} />
                    </div>
                </div >
            </div>

        </>
    )
}

export { UserAges }