
import React from "react";
import PropTypes from "prop-types";
import "../../../../styles/drxrows.scss";
import moment from 'moment';

function Rows (props) {
    // eslint-disable-next-line no-unused-vars
    const { prescription, length, square, wbn, StartDate, EndDate } = props;
    const Manufacturer = prescription?.Details?.Manufacturer;
    const isDifferentDate = moment(prescription?.startDate).format('DD MMM') != StartDate.trim() || moment(prescription?.endDate).format('DD MMM') != EndDate.trim();
    return (
        <>
            {length >= 10 ? (
                square ? (
                    <tr
                        className="border-2 border-[#e5e7eb]"
                        style={{ borderColor: "#e5e7eb" }}
                    >
                        <td
                            className={`border-2 border-[#e5e7eb]`}
                            style={{ width: "30%" }}
                        >
                            <div className="flex mb-5 items-baseline px-4 flex-row justify-between text-left">
                                <h2
                                    className="font-semibold text-left "
                                    style={{ fontSize: "20px" }}
                                >
                                    {prescription.Medicine?.name.substring(0, 29)}
                                    {isDifferentDate && <p
                                        className="text-gray-600"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {moment(prescription.startDate).format('Do MMM YY')} to {moment(prescription.endDate).format('Do MMM YY')}
                                    </p>}
                                </h2>

                                <p
                                    className="text-gray-400  mb-2"
                                    style={{ textTransform: "capitalize", fontSize: "16px" }}
                                >
                                    {prescription.Medicine.time.toLowerCase()}
                                </p>
                            </div>
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb] h-[45px]"
                            style={{ width: "4%" }}
                        >
                            {prescription?.adminObj?.Morning?.length > 0 && prescription?.adminObj?.Morning.map((med, index) => {
                                return (
                                    <div key={index}
                                        className={
                                            "text-center border-2 text-white font-bold text-lg h-[50px] " +
                                            `border-[#F8B62D]`
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#F8B62D] relative flex justify-center">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3  font-semibold absolute top-[-2px]"
                                                }
                                            >
                                                {med?.time}
                                            </p>
                                        </div>
                                        <div className="flex justify-center relative">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3 font-semibold absolute top-[-2px] "
                                                }
                                            >
                                                {" "}
                                                {med?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb] h-[25px]"
                            style={{ width: "4%" }}
                        >
                            {prescription?.adminObj?.Afternoon?.length > 0 && prescription?.adminObj?.Afternoon?.map((med, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            "text-center  h-[50px] border-2 text-white font-bold text-lg" +
                                            `border-[#FFF400]`
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#FFF400] flex justify-center relative">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3 absolute top-[-2px]  font-semibold"
                                                }
                                            >
                                                {" "}
                                                {med?.time}
                                            </p>
                                        </div>
                                        <div className="flex  justify-center relative">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3 absolute top-[-2px]  font-semibold "
                                                }
                                            >
                                                {" "}
                                                {med?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb] h-[25px]"
                            style={{ width: "4%" }}
                        >
                            {prescription?.adminObj?.Evening?.length > 0 && prescription?.adminObj?.Evening?.map((med, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            "text-center  h-[50px] border-2 text-white font-bold text-lg" +
                                            `border-[#BBDEFB]`
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#BBDEFB] flex justify-center relative">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3 absolute top-[-2px]  font-semibold"
                                                }
                                            >
                                                {" "}
                                                {med?.time}
                                            </p>
                                        </div>
                                        <div className="flex  justify-center relative">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3 absolute top-[-2px]  font-semibold "
                                                }
                                            >
                                                {" "}
                                                {med?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb] h-[25px]"
                            style={{ width: "4%" }}
                        >
                            {prescription?.adminObj?.Night?.length > 0 && prescription?.adminObj?.Night?.map((nightMedicine, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            "text-center border-2 text-white font-bold text-lg h-[50px] border-[#b9b1b1]"
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#b9b1b1] flex justify-center relative">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3 font-semibold absolute top-[-2px]  "
                                                }
                                            >
                                                {" "}
                                                {nightMedicine?.time}
                                            </p>
                                        </div>
                                        <div className="flex justify-center relative">
                                            <p
                                                className={
                                                    "text-black text-xl text-center leading-3 font-semibold "
                                                }
                                            >
                                                {" "}
                                                {nightMedicine?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb] "
                            style={{ width: "2%" }}
                        >
                            <div className="flex items-center justify-center">
                                <div className=" text-center w-16 pb-2 ">
                                    <p className="font-semibold text-xl">{prescription?.Qty}</p>
                                </div>
                            </div>
                        </td>
                        {wbn ? null : (
                            <td
                                className="border-2 border-[#e5e7eb]"
                                style={{ width: "16%" }}
                            >
                                <div className="text-left relative text-xl  px-2">
                                    <p className=" text-xl absolute top-[-32px]  items-baseline">
                                        {" "}
                                        <span className="font-bold  text-xl ">B.No.</span>{" "}
                                        {prescription?.Details?.BatchNumber}{" "}
                                        <span className="font-bold  text-xl">Mfr.</span>{" "}
                                        {Manufacturer && Manufacturer.length > 10
                                            ? Manufacturer.slice(0, 10) + "..."
                                            : Manufacturer}{" "}
                                        <span className="font-bold  text-xl">Exp.</span>
                                        {prescription?.Details?.ExpiryDate.slice(0, 10)}{" "}
                                    </p>
                                </div>
                            </td>
                        )}
                    </tr>
                ) : (
                    <tr
                        className="border-2 border-[#e5e7eb]"
                        style={{ borderColor: "#e5e7eb" }}
                    >
                        <td
                            className={`border-2 border-[#e5e7eb]`}
                            style={{ width: "30%" }}
                        >
                            <div className="flex items-start px-4 flex-col justify-start text-center">
                                <h2
                                    className="font-semibold text-center"
                                    style={{ fontSize: "17px" }}
                                >
                                    {prescription.Medicine?.name}
                                </h2>
                                {isDifferentDate && <p
                                    className="text-gray-600 font-semibold text-xl"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {moment(prescription.startDate).format('Do MMM YY')} to {moment(prescription.endDate).format('Do MMM YY')}
                                    <br></br>
                                </p>}
                                <p
                                    className="text-gray-400  mb-2"
                                    style={{ textTransform: "capitalize", fontSize: "13px" }}
                                >
                                    {prescription.Medicine.time.toLowerCase()}
                                </p>
                            </div>
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb] h-[45px]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Morning?.length > 0 && prescription?.adminObj?.Morning?.map((morningMedicine, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex items-center w-full justify-center">
                                        <div
                                            className={
                                                "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                                `border-[#F8B62D]`
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            <div className="h-[50%] bg-[#F8B62D]">
                                                <p
                                                    className={
                                                        "text-black text-2xl leading-3 p-2 font-semibold"
                                                    }
                                                >
                                                    {morningMedicine?.time}
                                                </p>
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p
                                                    className={
                                                        "text-black text-2xl leading-3 font-semibold p-2"
                                                    }
                                                >
                                                    {" "}
                                                    {morningMedicine?.quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Afternoon?.length > 0 && prescription?.adminObj?.Afternoon?.map((afternoonMedicine, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex items-center w-full justify-center">
                                        <div
                                            className={
                                                "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                                `border-[#FFF400]`
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            <div className="h-[50%] bg-[#FFF400]">
                                                <p
                                                    className={
                                                        "text-black text-2xl leading-3 p-2 font-semibold"
                                                    }
                                                >
                                                    {" "}
                                                    {afternoonMedicine?.time}
                                                </p>
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p
                                                    className={
                                                        "text-black text-2xl leading-3 font-semibold p-1"
                                                    }
                                                >
                                                    {" "}
                                                    {afternoonMedicine?.quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Evening?.length > 0 && prescription?.adminObj?.Evening?.map((eveningMedicine, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex items-center w-full justify-center"
                                    >
                                        <div
                                            className={
                                                "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                                `border-[#BBDEFB]`
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            <div className="h-[50%] bg-[#BBDEFB]">
                                                <p
                                                    className={
                                                        "text-black text-2xl leading-3 p-2 font-semibold"
                                                    }
                                                >
                                                    {eveningMedicine?.time}
                                                </p>
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p
                                                    className={
                                                        "text-black text-2xl leading-3 font-semibold p-1"
                                                    }
                                                >
                                                    {eveningMedicine?.quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>)
                            })}
                        </td>
                        <td
                            className="border-2 border-[#e5e7eb]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Night?.length > 0 && prescription?.adminObj?.Night?.map((nightMedicine, index) => {
                                return (<div
                                    key={index}
                                    className="flex items-center w-full justify-center"
                                >
                                    <div
                                        className={
                                            "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                            `border-[#b9b1b1]`
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#b9b1b1]">
                                            <p
                                                className={
                                                    "text-black text-2xl leading-3 p-2 font-semibold"
                                                }
                                            >
                                                {" "}
                                                {nightMedicine?.time}
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <p
                                                className={
                                                    "text-black text-2xl leading-3 font-semibold p-1"
                                                }
                                            >
                                                {" "}
                                                {nightMedicine?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </td>
                        <td className="border-2 border-[#e5e7eb]" style={{ width: "6%" }}>
                            <div className="flex items-center justify-center">
                                <div className=" text-center px-4 w-16 pb-2">
                                    <p className="font-semibold text-2xl">
                                        {prescription?.Qty}
                                    </p>
                                </div>
                            </div>
                        </td>
                        {wbn ? null : (
                            <td
                                className="border-2 border-[#e5e7eb]"
                                style={{ width: "16%" }}
                            >
                                <div className="flex items-start w-full flex-col justify-center">
                                    <div className="text-left text-sm flex flex-col px-2 pb-2">
                                        <tr className="text-lg">
                                            <span className="font-bold text-lg">B.No.</span>{" "}
                                            {prescription?.Details?.BatchNumber}
                                        </tr>
                                        <tr className="text-lg">
                                            <span className="font-bold text-lg">Mfr.</span>{" "}
                                            {Manufacturer && Manufacturer.length > 10
                                                ? Manufacturer.slice(0, 10) + "..."
                                                : Manufacturer}
                                        </tr>
                                        <tr className="text-lg">
                                            <span className="font-bold text-lg">Exp.</span>{" "}
                                            {prescription?.Details?.ExpiryDate.slice(0, 10)}
                                        </tr>
                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                )
            ) : null}
            {/* Rows With Length More than 7 */}
            {length >= 7 && length < 10 ? (
                <tr
                    className="border-2 border-[#e5e7eb]"
                    style={{ height: `75px`, borderColor: "#e5e7eb" }}
                >
                    <td
                        className={`border-2 border-[#e5e7eb]`}
                        style={{ width: "30%" }}
                    >
                        <div className="flex items-start px-4 flex-col justify-start text-left">
                            <h2
                                className="font-semibold text-left"
                                style={square ? { fontSize: "23px" } : { fontSize: "18px" }}
                            >
                                {prescription.Medicine?.name}
                            </h2>
                            {isDifferentDate && <p
                                className="text-gray-600 font-semibold text-xl"
                                style={
                                    square
                                        ? { textTransform: "capitalize", fontSize: "19px" }
                                        : { textTransform: "capitalize", fontSize: "15px" }
                                }
                            >
                                {moment(prescription.startDate).format('Do MMM YY')} to {moment(prescription.endDate).format('Do MMM YY')}
                                <br></br>
                            </p>}
                            <p
                                className="text-gray-400  mb-2"
                                style={
                                    square
                                        ? { textTransform: "capitalize", fontSize: "19px" }
                                        : { textTransform: "capitalize", fontSize: "15px" }
                                }
                            >
                                {prescription.Medicine.time.toLowerCase()}
                            </p>
                        </div>
                    </td>
                    <td className="border-2 border-[#e5e7eb]" style={{ width: "12%" }}>
                        {prescription?.adminObj?.Morning?.length > 0 && prescription?.adminObj?.Morning?.map((morningMedicine, index) => {
                            return (<div
                                key={index}
                                className="flex items-center w-full justify-center"
                            >
                                <div
                                    className={
                                        "text-left h-[100px] border-2 text-white font-bold text-lg " +
                                        `border-[#F8B62D]`
                                    }
                                    style={{ width: "100%" }}
                                >
                                    <div className="h-[50%] bg-[#F8B62D]">
                                        <p
                                            className={
                                                square
                                                    ? "text-black text-3xl text-center leading-3 p-2 font-semibold"
                                                    : "text-black text-2xl text-center leading-3 p-2 font-semibold"
                                            }
                                        >
                                            {morningMedicine?.time}
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <p
                                            className={
                                                square
                                                    ? "text-black text-3xl text-center leading-3 font-semibold p-2"
                                                    : "text-black text-2xl text-center leading-3 font-semibold p-2"
                                            }
                                        >
                                            {" "}
                                            {morningMedicine?.quantity}
                                        </p>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </td>
                    <td className="border-2 border-[#e5e7eb]" style={{ width: "12%" }}>
                        {prescription?.adminObj?.Afternoon?.length > 0 && prescription?.adminObj?.Afternoon?.map((afternoonMedicine, index) => {
                            return (
                                <div
                                    className="flex items-center w-full justify-center"
                                    key={index}
                                >
                                    <div
                                        className={
                                            "text-left h-[100px] border-2 text-white font-bold text-lg " +
                                            `border-[#FFF400]`
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#FFF400]">
                                            <p
                                                className={
                                                    square
                                                        ? "text-black text-3xl text-center leading-3 p-2 font-semibold"
                                                        : "text-black text-2xl text-center leading-3 p-2 font-semibold"
                                                }
                                            >
                                                {" "}
                                                {afternoonMedicine?.time}
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <p
                                                className={
                                                    square
                                                        ? "text-black text-3xl text-center leading-3 font-semibold p-1"
                                                        : "text-black text-2xl text-center leading-3 font-semibold p-1"
                                                }
                                            >
                                                {" "}
                                                {afternoonMedicine?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                        })}
                    </td>
                    <td className="border-2 border-[#e5e7eb]" style={{ width: "12%" }}>
                        {prescription?.adminObj?.Evening?.length > 0 && prescription?.adminObj?.Evening?.map((eveningMedicine, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center w-full justify-center">
                                    <div
                                        className={
                                            "text-left h-[100px] border-2 text-white font-bold text-lg " +
                                            `border-[#BBDEFB]`
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#BBDEFB]">
                                            <p
                                                className={
                                                    square
                                                        ? "text-black text-3xl text-center leading-3 p-2 font-semibold"
                                                        : "text-black text-2xl text-center leading-3 p-2 font-semibold"
                                                }
                                            >
                                                {eveningMedicine?.time}
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <p
                                                className={
                                                    square
                                                        ? "text-black text-3xl text-center leading-3 font-semibold p-1"
                                                        : "text-black text-2xl text-center leading-3 font-semibold p-1"
                                                }
                                            >
                                                {eveningMedicine?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                        })}
                    </td>
                    <td className="border-2 border-[#e5e7eb]" style={{ width: "12%" }}>
                        {prescription?.adminObj?.Night?.length > 0 && prescription?.adminObj?.Night?.map((nightMedicine, index) => {
                            return (<div
                                key={index}
                                className="flex items-center w-full justify-center"
                            >
                                <div
                                    className={
                                        "text-left h-[100px] border-2 text-white font-bold text-lg " +
                                        `border-[#b9b1b1]`
                                    }
                                    style={{ width: "100%" }}
                                >
                                    <div className="h-[50%] bg-[#b9b1b1]">
                                        <p
                                            className={
                                                square
                                                    ? "text-black text-3xl text-center leading-3 p-2 font-semibold"
                                                    : "text-black text-2xl text-center leading-3 p-2 font-semibold"
                                            }
                                        >
                                            {" "}
                                            {nightMedicine?.time}
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <p
                                            className={
                                                square
                                                    ? "text-black text-3xl text-center leading-3 font-semibold p-1"
                                                    : "text-black text-2xl text-center leading-3 font-semibold p-1"
                                            }
                                        >
                                            {" "}
                                            {nightMedicine?.quantity}
                                        </p>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </td>
                    {/* remove */}
                    <td className="border-2 border-[#e5e7eb]" style={{ width: "6%" }}>
                        <div className="flex items-center justify-center">
                            <div className=" text-left px-4 w-16 pb-2">
                                <p className="font-semibold text-2xl">{prescription?.Qty}</p>
                            </div>
                        </div>
                    </td>
                    {wbn ? null : (
                        <td
                            className="border-2 border-[#e5e7eb]"
                            style={{ width: "16%" }}
                        >
                            <div className="flex items-start w-full flex-col justify-center">
                                <div className="text-left text-sm flex flex-col px-2 pb-2">
                                    <tr className="text-lg">
                                        <span className="font-bold text-lg">B.No.</span>{" "}
                                        {prescription?.Details?.BatchNumber}
                                    </tr>
                                    <tr className="text-lg">
                                        <span className="font-bold text-lg">Mfr.</span>{" "}
                                        {Manufacturer && Manufacturer.length > 10
                                            ? Manufacturer.slice(0, 10) + "..."
                                            : Manufacturer}
                                    </tr>
                                    <tr className="text-lg">
                                        <span className="font-bold text-lg">Exp.</span>{" "}
                                        {prescription?.Details?.ExpiryDate.slice(0, 10)}
                                    </tr>
                                </div>
                            </div>
                        </td>
                    )}
                </tr>
            ) : null}
            {length < 7 ? (
                <>
                    <tr
                        className="border-2 border-[#e5e7eb]"
                        style={{ height: `100px` }}
                    >
                        <td
                            className={`border-2 border-[#e5e7eb] pb-6`}
                            style={{ width: "30%" }}
                        >
                            <div className="flex items-start px-4 flex-col justify-start text-left">
                                <h2 className="text-3xl font-semibold text-left">
                                    {prescription.Medicine?.name}
                                </h2>

                                {isDifferentDate && <p
                                    className="text-gray-600 font-semibold text-xl"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {moment(prescription.startDate).format('Do MMM YY')} to {moment(prescription.endDate).format('Do MMM YY')}
                                    <br></br>
                                </p>}
                                <p
                                    className="text-gray-600 font-semibold text-xl"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {prescription?.Medicine.time.toLowerCase()}
                                </p>
                            </div>
                        </td>

                        <td
                            className="border-2 border-[#e5e7eb]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Morning?.length > 0 && prescription?.adminObj?.Morning?.map((med, index) => {
                                return (
                                    <div key={index} className="flex items-center w-full justify-center">
                                        <div
                                            className={
                                                "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                                `border-[#F8B62D]`
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            <div className="h-[50%] bg-[#F8B62D]">
                                                <p className="text-black text-2xl leading-3 pt-3">
                                                    {" "}
                                                    {med?.time}
                                                </p>
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-black text-2xl">
                                                    {" "}
                                                    {med?.quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="w-[140px] border-2 border-[#e5e7eb]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Afternoon?.length > 0 && prescription?.adminObj?.Afternoon?.map((afternoonMedicine, index) => {
                                return (<div
                                    key={index}
                                    className="flex items-center justify-center">
                                    <div
                                        className={
                                            "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                            `border-[#FFF400]`
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <div className="h-[50%] bg-[#FFF400]">
                                            <p className="text-black text-2xl leading-3 pt-3">
                                                {" "}
                                                {afternoonMedicine?.time}
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <p className="text-black text-2xl">
                                                {" "}
                                                {afternoonMedicine?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </td>
                        <td
                            className="w-[140px] border-2 border-[#e5e7eb]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Evening?.length > 0 && prescription?.adminObj?.Evening?.map((eveningMedicine, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex items-center w-full justify-center"
                                    >
                                        <div
                                            className={
                                                "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                                `border-[#BBDEFB]`
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            <div className="h-[50%] bg-[#BBDEFB]">
                                                <p className="text-black text-2xl leading-3 pt-3">
                                                    {eveningMedicine?.time}
                                                </p>
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-black text-2xl">
                                                    {eveningMedicine?.quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                        <td
                            className="w-[140px] border-2 border-[#e5e7eb]"
                            style={{ width: "12%" }}
                        >
                            {prescription?.adminObj?.Night?.length > 0 && prescription?.adminObj?.Night?.map((nightMedicine, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex items-center w-full justify-center">
                                        <div
                                            className={
                                                "text-center h-[100px] border-2 text-white font-bold text-lg " +
                                                `border-[#b9b1b1]`
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            <div className="h-[50%] bg-[#b9b1b1]">
                                                <p className="text-black text-2xl leading-3 pt-3">
                                                    {" "}
                                                    {nightMedicine?.time}
                                                </p>
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-black text-2xl">
                                                    {" "}
                                                    {nightMedicine?.quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>)
                            })}
                        </td>

                        <td className="border-2 border-[#e5e7eb]" style={{ width: "6%" }}>
                            <div className="items-center justify-center">
                                <div className=" text-center">
                                    <p className="font-semibold text-2xl">{prescription.Qty}</p>
                                </div>
                            </div>
                        </td>
                        {wbn ? null : (
                            <td
                                className="w-[400px] border-2 border-[#e5e7eb]"
                                style={{ width: "16%" }}
                            >
                                <div className="flex items-start w-full flex-col justify-center px-4">
                                    <div className="text-left text-xl leading-5 mb-3 flex flex-col">
                                        <tr className="text-xl">
                                            <span className="font-bold text-xl">B.No.</span>{" "}
                                            {prescription?.Details?.BatchNumber}
                                        </tr>
                                        <tr className="text-xl">
                                            <span className="font-bold text-xl">Mfr.</span>{" "}
                                            {Manufacturer && Manufacturer.length > 10
                                                ? Manufacturer.slice(0, 10) + "..."
                                                : Manufacturer}
                                        </tr>
                                        <tr className="text-xl">
                                            <span className="font-bold text-xl">Exp.</span>{" "}
                                            {prescription?.Details?.ExpiryDate.slice(0, 10)}
                                        </tr>
                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                </>
            ) : null}
        </>
    );
}

Rows.propTypes = {
    prescription: PropTypes.any, length: PropTypes.any, square: PropTypes.any, wbn: PropTypes.bool, StartDate: PropTypes.any, EndDate: PropTypes.any
}

export { Rows };
