import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { VscChromeClose } from "react-icons/vsc";
function RightBar({ rightBarCloseAction, value, children }) {
    const rightBarRef = React.useRef(null);
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (!document.getElementById("right-bar").contains(event.target)) {
                rightBarCloseAction();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [rightBarRef]);

    return (
        <div
            id="right-bar"
            style={{ zIndex: '2000000' }}
            className={
                value
                    ? "fixed right-0 top-0 h-[100vh] overflow-y-auto  z-20 shadow-soft-xl w-[25vw]  bg-white-50 transition-all p-4"
                    : "hidden transition-all"
            }>
            <div className="flex justify-end">
                <button
                    onClick={rightBarCloseAction}
                    className="hover:bg-black hover:text-white-50 p-1 rounded-2xl font-sans">
                    <VscChromeClose fontSize={"1.2rem"} />
                </button>
            </div>
            {children}
        </div>
    );
}

RightBar.propTypes = {
    rightBarCloseAction: PropTypes.func,
    value: PropTypes.bool,
    data: PropTypes.object,
    // children of type react component
    children: PropTypes.element,
};

export default RightBar;
