const Columns = [
    {
        Header: "#",
        accessor: "id",
    },
    {
        Header: "Name",
        accessor: "partner.type",
    },
    {
        Header: "Partner",
        accessor: "partner.name",
    },
    {
        Header: "Created On",
        accessor: "",
    },
    {
        Header: "Type",
        accessor: "entityType",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "NextCallback",
        accessor: "",
    },
    {
        Header: "Comment",
        accessor: "comment",
    },
    {
        Header: "Prescription",
        accessor: "",
    },


];
export { Columns };