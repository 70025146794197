/* eslint-disable no-unused-vars */
import React from "react";
import { GetOrderDetailsByOrderId } from "../../hooks/fetch-hooks/order-details.js/get-order-details-by-id";
import { RightBarContent, TableInstance } from "./components";
import PropTypes from "prop-types";
import RightBar from "../../layouts/Dashboard/components/rightbar";
import { MedicinesRightBarContent } from "./components/medicines-data-rightbar-content";
import { MedicinesRowData } from "../../contexts";
import { useParams, useNavigate } from "react-router-dom";
import { TableInstance as InvoiceTable } from "./components/table-invoices/table-instance";
import moment from "moment/moment";

const Heading = ({ heading, title }) => (
    <h2 className="flex flex-col font-sans flex-auto ">
        <span className="text-[#168c7c] text-lg font-bold font-sans">{title}</span>
        <span className="text-lg">{heading}</span>
    </h2>
);

function OrderDetails() {
    const { id } = useParams();
    const { data: orders } = GetOrderDetailsByOrderId(id);
    const [rowData, setRowData] = React.useState(null);
    const [tableData, setTableData] = React.useState(null);
    const [rightBarOpen, setRightBarOpen] = React.useState(false);
    const [invoiceData, setInvoiceData] = React.useState(null);
    const location = useNavigate()

    React.useEffect(() => {
        setTableData(orders?.data?.data?.prescription?.prescriptionItems);
        setInvoiceData(orders?.data?.data?.salesDumps);
    }, [orders]);

    const changeRightBarOpen = () => {
        setRightBarOpen({
            ...rightBarOpen,
            open: true,
        });
    };

    const changeRightBarClose = () => {
        setRightBarOpen({
            ...rightBarOpen,
            open: false,
        });
    };

    const MainStyle = {
        scrollbarWidth: "none"
    }
    return (
        <div className="px-7 bg-transparent">
            <h2
                className="mb-2 cursor-pointer font-bold text-xl mt-3 p-2 text-[#28a48b]"
                onClick={() => {
                    location(-1);
                }}>
                {"<"} Back
            </h2>
            {/* {orders && orders?.data?.data?.count < 1 && <Navigate to="/home" />} */}
            {orders?.data?.data && (
                <>
                    <RightBar
                        rightBarOpenAction={changeRightBarOpen}
                        rightBarCloseAction={changeRightBarClose}
                        value={rightBarOpen.open}
                        data={rowData}>
                        <MedicinesRightBarContent {...rowData} />
                    </RightBar>
                    {/* Main Bar */}
                    <div className="flex w-full h-full" style={MainStyle}>
                        <div className="w-[70%]">
                            <h1 className="mt-3 font-bold text-2xl font-sans">
                                #{orders?.data?.data?.id} Order Details
                            </h1>
                            <div className=" mt-2 py-2 flex gap-6 items-center">
                                <Heading
                                    heading={new Date(
                                        orders?.data?.data?.startDate
                                    ).toDateString()}
                                    title="Start Date"
                                />

                                <Heading
                                    heading={new Date(
                                        orders?.data?.data?.endDate
                                    ).toDateString()}
                                    title="End Date"
                                />

                                <Heading
                                    heading={orders?.data?.data?.status}
                                    title="Status"
                                />

                                <Heading
                                    heading={orders?.data?.data?.prescription?.drName}
                                    title="Doctor Name"
                                />

                                <Heading
                                    heading={orders?.data?.data?.noOfDays}
                                    title="Order Duration"
                                />
                            </div>

                            {invoiceData?.length > 0 && (
                                <>
                                    <h1 className="mt-10 font-bold text-2xl font-sans">
                                        Invoices
                                    </h1>
                                    <InvoiceTable tableData={invoiceData} />
                                </>
                            )}
                            <h1 className="mt-10 mb-2 font-bold text-2xl font-sans">
                                #{orders?.data?.data?.prescriptionId} Prescription Items
                            </h1>

                            {tableData && (
                                <MedicinesRowData.Provider value={{ rowData, setRowData }}>
                                    <TableInstance
                                        changeRightBarOpen={changeRightBarOpen}
                                        tableData={tableData}
                                    />
                                </MedicinesRowData.Provider>
                            )}
                        </div>
                        <div className="w-[30%] h-full  sticky top-0">
                            {orders && <RightBarContent {...orders} />}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

OrderDetails.propTypes = {};
Heading.propTypes = {
    heading: PropTypes.any,
    title: PropTypes.string.isRequired,
};
export { OrderDetails };
