import React, { useState } from "react";
import { OrderDetailsData } from "../../contexts";

import RightBar from "../../layouts/Dashboard/components/rightbar";
import AllOrderDetailsRightBar from "./components/rightsidebar/rightsidebar_details";
import { OrdersTable } from "./components";
import { PRESORTED_ORDER_TYPE } from "../../utils/constants";
// import { useModalMain } from "../../contexts/modal";
// import OrderForm from "../../components/invoice/OrderForm";

function AllOrders() {
    const [data, setData] = useState();
    const [rightBarOpen, setRightBarOpen] = useState({
        name: "all-orders",
        open: false,
    });

    // const { openModal } = useModalMain()

    const changeRightBarOpen = () => {
        if (!rightBarOpen.open) {
            setRightBarOpen({
                ...rightBarOpen,
                open: true,
            });
        }
    };
    const changeRightBarClose = () => {
        setRightBarOpen({
            ...rightBarOpen,
            open: false,
        });
    };

    // const btnstyles = {
    //   width: "130px",
    //   height: "3rem"
    // }



    return (
        <div className="p-7 h-full overflow-y-scroll bg-gray-50">
            <RightBar
                rightBarOpenAction={changeRightBarOpen}
                rightBarCloseAction={changeRightBarClose}
                value={rightBarOpen.open}
                data={data}>
                <AllOrderDetailsRightBar {...data} />
            </RightBar>
            {/* <div className="flex justify-between items-center mb-1"> */}
            {/* <button className="bg-elm-500 text-white-100 hover:bg-elm-700 font-semibold py-2 rounded-2xl" style={btnstyles} onClick={()=>openModal(OrderForm)}>Gererate Invoice</button> */}
            {/* </div> */}
            <OrderDetailsData.Provider value={{ data, setData, OrderType: PRESORTED_ORDER_TYPE }}>
                <OrdersTable changeRightBarOpen={changeRightBarOpen} />
            </OrderDetailsData.Provider>
        </div >
    );
}

export { AllOrders };
