/* eslint-disable react/prop-types */
import React from 'react'
import { MdDelete } from "react-icons/md"
export default function MedicineTimeContainer ({ updateMedicineTiming, ind, timing, deleteMedicineTiming }) {
    return (
        <div className='flex flex-row gap-2 rounded-2xl border h-14 border-gray-300 py-2 px-4 w-fit items-center'>
            <input className='rounded-xl h-10 border-none' type='time' value={timing.time} maxLength={5} name='time' onChange={(e) => { updateMedicineTiming(ind, e.target.name, e.target.value) }} />
            <label className=''>Before</label>
            <label className="relative inline-flex items-center cursor-pointer" >
                <input type="checkbox" name='meal' checked={timing.meal === "AFTER"} onChange={(e) => {
                    if (e.target.checked) {
                        updateMedicineTiming(ind, "meal", "AFTER")
                    } else {
                        updateMedicineTiming(ind, "meal", "BEFORE")
                    }
                }} className="sr-only peer" />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:bg-elm-500 peer-checked:after:bg-[white] after:h-5 after:w-5 after:transition-all peer-checked:bg-elm-500 border-elm-500 border"></div>
            </label>
            <label>After</label>
            <div className='w-fit'>
                <MdDelete className=' text-gray-400 text-2xl cursor-pointer font-bold' onClick={() => { deleteMedicineTiming(ind) }} />
            </div>
        </div>
    )
}
