import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar";
import TopBar from "./components/topbar";
import "./components/topbar/topbar.scss";

function Dashboard () {
    return (
        <div className="bg-gray-50 pl-4 h-full ">
            <div className="flex h-full">
                <div className="sticky">
                    <Sidebar />
                </div>
                <div className="w-[88vw] ">
                    <div className="sticky top-2 ">
                        <TopBar />
                    </div>
                    <div className="bg-gray-50">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
