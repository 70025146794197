import { Field } from "formik";
import { ErrorMessage } from "formik";
import { FieldArray } from "formik";
import React from "react";
import "./reorder-form.css";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form } from "formik";
import { request } from "../../config/helpers/axios-instance";
import {
    addDaysToDate,
    getFormattedDate,
} from "../../config/helpers/date-functions";
import "../../styles/ItemForms.scss";
import { toast } from "react-toastify";
function PrescriptionItems ({
    formData,
    newOrderDetails,
    previousStep,
    action,
    oldOrderDetails,
}) {
    const reorderSuccessId = "reorderSuccessId";
    const initialvalues = {
        prescriptionItems: formData?.data?.data?.map((item) => {
            if (
                oldOrderDetails.noOfDays === item.duration &&
                newOrderDetails.noOfDays !== oldOrderDetails.noOfDays
            ) {
                return {
                    ...item,
                    //add 1 day to date string and convert it to string
                    startDate: getFormattedDate(newOrderDetails.startDate),
                    duration: newOrderDetails.noOfDays,
                };
            }
            return {
                ...item,
                //add 1 day to date string and convert it to string
                startDate: getFormattedDate(newOrderDetails.startDate),
                duration: item?.duration,
            };
        }),
    };

    const onSubmit = async (values) => {
        const response = {
            ...newOrderDetails,
            prescriptionItems: values.prescriptionItems.map((item) => {
                return {
                    ...item,
                    startDate: getFormattedDate(item.startDate),

                    endDate: addDaysToDate(item.startDate, item.duration - 1),
                };
            }),
        };

        const submitResponse = await request({
            url: "userOrder/admin/reorder",
            method: "POST",
            data: response,
        });

        if (submitResponse?.data.success === true) {
            toast.success("ReOrder Placed Successfully", { reorderSuccessId });
            action();
        } else {
            toast.error(submitResponse?.data.error || "Something went wrong");
            action();
        }
    };
    return (
        <div>
            <button onClick={previousStep} className="backbtn">{"< "}Back</button>
            <h1 className="font-sans itemsheading">Items</h1>
            <Formik
                initialValues={initialvalues}
                onSubmit={onSubmit}
            // validationSchema={ReOrderSchema}
            >
                {({ values }) => (
                    <Form>
                        <FieldArray name="medicines">
                            {() => (
                                <div className="flex flex-col gap-4">
                                    <div className="grid grid-cols-4 items-center justify-start gap-12">
                                        <div className="font-semibold">Medicine</div>
                                        <div className="font-semibold">Old EndDate</div>
                                        <div className="font-semibold">New StartDate</div>
                                        <div className="font-semibold">Duration</div>
                                    </div>
                                    {values?.prescriptionItems.length > 0 &&
                                        values.prescriptionItems.map((medicine, index) => (
                                            <div
                                                className="grid grid-cols-4 items-center justify-start gap-12"
                                                key={index}>
                                                {/* Medicine Name */}

                                                <h2>
                                                    {index + 1}
                                                    {".  "}
                                                    {medicine?.item.name}
                                                </h2>
                                                {/* Old Start Date */}
                                                <h2>{new Date(medicine?.endDate).toDateString()}</h2>

                                                <div className="flex-col  flex justify-center">
                                                    <Field
                                                        className="focus:outline-none p-2 border rounded-2xl"
                                                        name={`prescriptionItems.${index}.startDate`}
                                                        type="date"
                                                    />

                                                    <ErrorMessage
                                                        name={`prescriptionItems.${index}.startDate`}
                                                        component="div"
                                                        className="field-error"
                                                    />
                                                </div>
                                                {medicine.typeOfAdmin !== "DATE" && (
                                                    <div className="flex-col  flex justify-center">
                                                        <Field
                                                            className="focus:outline-none p-2 border rounded-2xl"
                                                            name={`prescriptionItems.${index}.duration`}
                                                            type="number"
                                                        />
                                                        <ErrorMessage
                                                            name={`prescriptionItems.${index}.startDate`}
                                                            component="div"
                                                            className="field-error"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </FieldArray>
                        <button
                            type="submit"
                            className="mt-4 p-2 px-4 rounded-2xl text-white-50 font-sans orderbtn mr-auto">
                            Create Order
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default PrescriptionItems;
PrescriptionItems.propTypes = {
    formData: PropTypes.any,
    newOrderDetails: PropTypes.object,
    previousStep: PropTypes.func,
    action: PropTypes.func,
    oldOrderDetails: PropTypes.object,
};
