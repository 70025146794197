import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import { ALLOWED_TYPES, DAYS, QNT_INDEX, fields } from "./ocs-constants";

function getDaySleeve (drug) {
    let daySleeve = DAYS;
    if (drug['Days']) {
        drug['Days'].map((day) => {
            daySleeve[day['name']] = day['selected']
        })
    }
    else {
        for (const key in daySleeve) {
            daySleeve[key] = drug[key.toLowerCase()];
        }
    }
    return daySleeve

}


function alternateTypeParser (drug) {
    let startDate = moment(drug['startDate']).format();
    let stopDate;
    if (!drug["endDate"])
        stopDate = moment(drug['endDate']).add(drug["duration"] - 1, 'days').format();
    else
        stopDate = moment(drug['endDate']).format();
    let parsedArray = []
    let delta = 1;
    let drugDays = 0

    while (moment(stopDate).diff(moment(startDate), 'days') >= 0) {
        if (drugDays == 0) {
            drugDays = drugDays + 1
            let newDrug = { ...drug };
            newDrug['StartDate'] = moment(startDate).format('YYMMDD')
            newDrug['StopDate'] = moment(startDate).format('YYMMDD')
            newDrug['RXDateTime'] = moment().format('YYYYMMDDHHmmss')
            parsedArray.push(newDrug)
        }
        else
            drugDays = 0
        startDate = moment(startDate).add(delta, 'd').format();
    }
    return parsedArray
}
function dateTypeParser (drug) {
    let parsedArray = []
    let startDate = moment(drug['startDate']).format();
    let newDrug = { ...drug };
    newDrug['StartDate'] = moment(startDate).format('YYMMDD')
    newDrug['StopDate'] = moment(startDate).format('YYMMDD')
    newDrug['RXDateTime'] = moment().format('YYYYMMDDHHmmss')
    parsedArray.push(newDrug)
    return parsedArray
}

function daysTypeParser (drug) {
    let startDate = moment(drug['startDate']).format();
    let stopDate;
    if (!drug["endDate"]) {
        stopDate = moment(drug['endDate']).add(drug['duration'], 'd').format();
    }
    else {
        stopDate = moment(drug['endDate']).format();
    }
    let days = getDaySleeve(drug);
    let parsedArray = []
    let delta = 1
    let drugDays = 0
    let iterDate = startDate
    while (moment(stopDate).diff(moment(startDate), 'days', true) >= 0) {
        if (days[moment(startDate).format('dddd')]) {
            if (drugDays == 0)
                iterDate = startDate
            drugDays = drugDays + 1
        }
        else if (days[moment(startDate).format('dddd')] == false && drugDays > 0) {
            let newDrug = { ...drug };
            newDrug['StartDate'] = moment(iterDate).format('YYMMDD')
            let stoppingDate = moment(startDate).subtract(1, 'days');
            newDrug['StopDate'] = moment(stoppingDate).format('YYMMDD')
            drugDays = 0
            newDrug['RXDateTime'] = moment().format('YYYYMMDDHHmmss');
            parsedArray.push(newDrug)
        }
        if (moment(stopDate).diff(moment(startDate), 'days') === 0 && drugDays > 0) {
            let newDrug = { ...drug };
            newDrug['StartDate'] = moment(iterDate).format('YYMMDD')
            if (days[moment(startDate).format('dddd')])
                newDrug['StopDate'] = moment(startDate).format('YYMMDD')
            else {
                let stoppingDate = moment(startDate).subtract(1, 'days');
                newDrug['StopDate'] = moment(stoppingDate).format('YYMMDD')
            }
            newDrug['RXDateTime'] = moment().format('YYYYMMDDHHmmss');
            parsedArray.push(newDrug)
        }
        startDate = moment(startDate).add(delta, 'days').format();
    }
    return parsedArray;
}


function parseAdminTimes (drugs) {
    let adminTimesArray = []
    for (let i in drugs) {
        let drug = drugs[i]
        for (let i in drug['time']) {
            let newDrug = { ...drug }
            newDrug['AdminTime'] = drug['time'][i];
            let adminDesc = drug['meal'][i].charAt(0) + drug['meal'][i].slice(1).toLowerCase() + ' Meal';
            newDrug['AdminDescription'] = adminDesc;
            adminTimesArray.push(newDrug)
        }
    }
    return adminTimesArray;
}


function parsePostData (drug) {
    let result;
    if (!ALLOWED_TYPES.includes(drug.typeOfAdmin)) {
        return false;
    }
    else if (drug['typeOfAdmin'] === "DATE")
        result = dateTypeParser(drug)
    else if (drug['typeOfAdmin'] === "ALTERNATE")
        result = alternateTypeParser(drug)
    else if (drug['typeOfAdmin'] === "DAYS")
        result = daysTypeParser(drug)

    result = parseAdminTimes(result);
    return result
}

function parseData (data) {
    let finalString = ''
    let fieldString = ''
    for (const i in fields) {
        fieldString = ''
        if (data[i]) {
            fieldString = data[i] + ' '.repeat(fields[i] - data[i].length)
        }
        else
            fieldString = ' '.repeat(fields[i])
        finalString = finalString + fieldString
    }
    finalString = finalString + '\r\n';
    return finalString
}


function createTextFile (payload) {
    let lines = []
    for (let i in payload) {
        let line = parseData(payload[i])
        lines += line;
    }
    let filename = 'OCS' + "-" + payload[0]['PatientName'] + '.txt'
    const element = document.createElement("a");
    const file = new Blob([lines], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
}


/**
 * This is the main function which generates the ocs file
 * @param DrugsData
 * @param Random3
 * @returns Ocs file in .txt format which gets downloaded
 */
export function generateOcs (DrugsData, Random3) {
    let finalData = []
    let parsedData;
    let OrderData = [];
    DrugsData?.prescription?.prescriptionItems.map((item) => {
        OrderData.push(
            {
                ...item,
                PatientName: DrugsData?.mapUserPatient.Patient.name,
                PatientID: DrugsData?.mapUserPatient.Patient.id,
                DrugID: item.item.mnemonic,
                NursingHomeCode: 'NH-1',
                RXNumber: 'pillupRx-' + DrugsData.id,
                Quantity: QNT_INDEX[item.doseSize],
                Random3: Random3 ? Random3 : null
            });
    })

    for (let data in OrderData) {
        parsedData = parsePostData(OrderData[data]);
        if (!parsedData) {
            continue;
        }
        for (let d in parsedData) {
            finalData.push(parsedData[d]);
        }
    }
    return createTextFile(finalData)
}

