/* eslint-disable react/prop-types */
import moment from 'moment'
import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import { Card } from 'flowbite-react';

export default function PrescriptionTable ({ columns, rows }) {
    const [showPrescription, setShowPrescription] = useState(null);
    function closeModal () {
        setShowPrescription(null);
    }
    return (
        <div className='w-full block p-2 px-4 bg-[#ffffff] rounded-2xl shadow-soft-xl mt-4'>
            <table className='w-full font-sans pl-4'>
                <thead>
                    <tr className='border-b border-gray-100 h-14 font-sans'>
                        {columns.map((column) => {
                            return (
                                <th key={column} className="font-sans text-left pl-3">
                                    {column}
                                </th>
                            )
                        })}
                    </tr>

                </thead>
                <tbody>
                    {rows.map((row) => {
                        return (
                            <tr key={row.id} className="text-left border-t border-gray-100 h-14 hover:bg-slate-200 pl-3 font-sans">
                                <td className="py-4 text-left pl-3 font-sans">
                                    {moment(row.date).format('DD MMM YYYY')}
                                </td>
                                <td className="py-4 text-left pl-3 font-sans" >
                                    {row.drName}
                                </td>
                                <td className="py-4 text-left pl-3 font-sans" >
                                    {showPrescription === row.id && <Modal onClose={closeModal} title="Prescription" >
                                        <div className='h-[90vh] overflow-y-scroll'>
                                            {row.links.length > 0 ?
                                                row.links.map((link) => {
                                                    return (
                                                        <Card className="h-full" key={link} imgSrc={link} >
                                                        </Card>
                                                    )
                                                }) : 'No Presction Available'
                                            }
                                        </div>


                                    </Modal>}
                                    <p className='text-elm-500 font-bold cursor-pointer' onClick={() => { setShowPrescription(row.id) }}>View</p>
                                </td>
                            </tr>

                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
