import { request } from "../../../config/helpers/axios-instance";

const UpdatePrescription = async (prescriptionId, data) =>
    await request({
        url: `/prescription/admin/` + prescriptionId,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        data: data

    })
export { UpdatePrescription };