const initialValues = {
    userId: "",
    name: "",
    phone: "",
    country: "",
    pincode: "",
    district: "",
    state: "",
    line: "",
    isDefault: false,
    landmark: "",
}

const NOT_NULL_SCHEMA = [
    'userId',
    'name',
    'phone',
    'country',
    'pincode',
    'district',
    'state',
    'line',
    'isDefault',
]

const ADDRESS_SCHEMA = {
    userId: 'number',
    name: 'string',
    phone: 'string',
    country: 'string',
    pincode: 'string',
    district: 'string',
    state: 'string',
    line: 'string',
    isDefault: 'boolean',
    landmark: 'string',
}

export {
    initialValues,
    NOT_NULL_SCHEMA,
    ADDRESS_SCHEMA,
}