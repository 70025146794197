import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";


const GetAllCountries = () => {
    return useQuery(
        ["get-all-countries"],
        () =>
            request({
                url: `country/accepted`,
                method: "GET",
            })

    );
};

export default GetAllCountries;
