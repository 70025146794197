const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
];

const TYPE_DAYS = "DAYS";
const TYPE_ALTERNATE = "ALTERNATE";
const TYPE_DATE = "DATE";
const COLUMNS = [
    {
        Header: "#",
        accessor: "id",
    },
    {
        Header: "Medicine",
        accessor: "item.name",
    },
    {
        Header: "Start Date",
        accessor: "startDate",
    },
    {
        Header: "End Date",
        accessor: "endDate",
    },
    {
        Header: "Time",
        accessor: "time",
    },
    {
        Header: "Duration",
        accessor: "duration",
    },
    {
        Header: "Type",
        accessor: "typeOfAdmin",
    },
    {
        Header: "Discount",
        accessor: "discount",
    },
];

export { days, TYPE_DAYS, TYPE_ALTERNATE, TYPE_DATE, COLUMNS };
