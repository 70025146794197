import React, { useEffect } from 'react';
import randomcolor from 'randomcolor';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { request } from "../../../../../config/helpers/axios-instance";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import { useState } from "react";

let randomColors = []

const getRandomColors = (count = 1, luminosity = 'random', hue = 'random') => {
    let newColors = [];
    for (let i = 0; i < count; i++) {
        let newColor = randomcolor({
            luminosity: luminosity,
            hue: hue
        });
        while (randomColors.includes(newColor)) newColor = randomcolor({
            luminosity: luminosity,
            hue: hue
        });
        newColors.push(newColor);
        randomColors.push(newColor);
    }

    return newColors;
}

let colors = [getRandomColors(1, 'dark', 'pink')[0], getRandomColors(1, 'dark', 'yellow')[0]];

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

export const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true
            },
            title: {
                color: 'black'
            }
        }
    },
    scales: {
        x: {
            border: {
                display: false
            },
            grid: {
                display: false
            }
        },
        y: {
            border: {
                display: false
            },
            grid: {
                display: false
            }
        }
    }
};

ChartJS.defaults.color = '#FFFFFF';

function OrdersCount () {
    const [laggingCount, setLaggingCount] = useState(() => 28);
    const [ordersData, setOrdersData] = useState(() => null);

    const getOrdersData = async () => {
        let today = moment().format('YYYY-MM-DD')

        let response = await request({
            method: 'GET',
            url: `stats/admin/userOrders/count?startDate=2022-12-31&endDate=${today}&frequency=day`
        })
        if (response?.data?.success) {
            let result = [];
            for (let key in response.data.data) {
                result.push({
                    date: key,
                    count: response.data.data[key]
                });
            }

            setOrdersData(result)
        }
    }

    useEffect(() => {
        getOrdersData();
    }, []);


    let laggingData = ordersData?.map((elem) => elem.count)
    let laggingArray = [];
    for (let i = 0; i < laggingCount; i++) laggingArray.push(null);
    if (laggingData) {
        laggingData.splice(laggingData.length - laggingCount, laggingCount)
        laggingData = [...laggingArray, ...laggingData];
    }

    let data = {
        labels: ordersData?.map((elem) => moment(elem.date).format('ddd	Do MMM YY')),
        datasets: [
            {
                label: "Orders Count",
                data: ordersData?.map((elem) => elem.count),
                borderColor: colors[0],
                backgroundColor: colors[0],
            },
            {
                label: `Orders Count (Lagging ${laggingCount} days)`,
                data: laggingData,
                borderColor: colors[1],
                backgroundColor: colors[1],
            }
        ]
    }

    return (
        <>
            <div className='flex justify-end mt-5 items-center'>
                <label htmlFor="laggingCount" className='items-center'>Lagging Days: &nbsp;</label>
                <input className='input rounded-2xl w-[100px]' name='laggingCount' type="number" value={laggingCount} onChange={(e) => { setLaggingCount(e.target.value) }} />
            </div>
            <div
                className="w-full h-[42vh] p-2 shadow-soft-xl rounded-xl text-white mt-5"
                style={{ background: "#222" }}
            >
                < Line options={options} data={data} />
            </div>
        </>
    )
}

export { OrdersCount }