import React, { useState, useEffect } from "react";
import { CustomModalForm } from "../../components/modal-forms";
import CreateAddress from "../../components/modal-forms/create-address";
import { UsersData } from "../../contexts";
import useModal from "../../hooks/custom-hooks/useModal";
import { TableInstance } from "./users-table/table-instance";
import { useLocation, useSearchParams } from "react-router-dom";
import { GetSortBy } from "../../utils/sortby";
import "../../styles/Instance.scss";
import GetAllUserstable from "../../hooks/fetch-hooks/fetch-all/get-all-users-usertable";



const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};



function Users () {
    const { isShowing: isCreateFormShowing, toggle: toggleCreateForm } = useModal();
    const [selectedUser, setSelectedUser] = React.useState(null);
    const query = useQuery();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    // Main States
    const [tableData, setTableData] = React.useState([]);
    const [total, setTotal] = useState(0);
    const [pageSort, setPageSort] = React.useState([])
    //  All States for query
    const [pageindex, setPageIndex] = React.useState(query.get('page') || 1);
    // eslint-disable-next-line
    const [pageSize, setPageSize] = React.useState(50);
    const [value, setValue] = React.useState(query.get('search') || "");
    const [order, setOrder] = React.useState(undefined)
    const [orderType, setOrderType] = React.useState(undefined);

    // Main Effects

    const { data: userData, refetch } = GetAllUserstable(pageindex, pageSize, value, order, orderType)
    useEffect(() => {
        if (pageSort.length > 0) {
            setOrder(pageSort[0].desc ? "DESC" : "ASC")
            setOrderType(GetSortBy(pageSort[0].id))
        }
        else if (pageSort.length < 1) {
            setOrder(undefined)
            setOrderType(undefined)
        }
    }, [pageSort])


    useEffect(() => {
        const newTableData = userData?.data?.data?.rows.map((order) => {
            return {
                ...order,
                daysLeft: Math.floor(
                    (new Date(order.endDate) - new Date()) / (1000 * 60 * 60 * 24) + 2
                ),
            };
        });
        setTableData(newTableData);
        setTotal(userData?.data?.data?.count);
    }, [userData])

    useEffect(() => {
        refetch();
    }, [pageindex, pageSize, value, order, orderType]);

    useEffect(() => {
        setSearchParams({
            page: pageindex,
            size: pageSize,
            search: value,
        })
    }, [pageindex, pageSize, value]);


    const maxValues = Math.floor(total / pageSize)
    return (
        <>
            <div className="p-7 h-full overflow-y-scroll  bg-gray-50">
                <CustomModalForm
                    isShowing={isCreateFormShowing}
                    hide={toggleCreateForm}
                    title="Create User"
                    style={{ width: "60%", maxWidth: "60%" }}
                    className="modal-form">
                    <CreateAddress selectedUser={selectedUser} toggle={toggleCreateForm} />
                </CustomModalForm>

                <UsersData.Provider value={{ selectedUser, setSelectedUser, refetch }}>
                    <div className="flex gap-4 items-center">
                        <input
                            value={value}
                            className="px-6 w-full font-sans bg-slate-100 py-4"
                            onChange={(e) => {
                                setValue(e.target.value);
                                setPageIndex(1)
                            }}
                            placeholder={`${total} records...`}
                            style={{
                                fontSize: "1.1rem",
                                border: "0",
                            }}
                        />
                    </div>
                    {tableData?.length > 0 &&
                        <  TableInstance
                            tableData={tableData}
                            toggleModal={toggleCreateForm}
                            pageCount={total}
                            setPageSort={setPageSort}
                            pageSort={pageSort}
                        />}
                    {/* Btn Div */}
                    <div className="btn_div">
                        <button onClick={() => setPageIndex((count) => Number(count) - 1)} disabled={pageindex <= 1} className="previous">
                            Previous
                        </button>
                        <span className="pageindex">
                            {tableData?.length < 49 ? "Page 1" : `Page ${pageindex} of ${maxValues}`}
                        </span>
                        <button
                            onClick={() => setPageIndex((count) => Number(count) + 1)}
                            disabled={pageindex >= maxValues}
                            className="next"
                        >
                            Next
                        </button>
                    </div>
                </UsersData.Provider>
            </div>
        </>
    );
}

export { Users };
