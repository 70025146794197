import { request } from "../../../config/helpers/axios-instance";


const GetAllReOrders = (params, daysLeft) => {
    return request({
        url: `userOrder/admin/futureOrders/${daysLeft}`,
        method: "GET",
        params: params
    })
};

export default GetAllReOrders;
