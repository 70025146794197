import React, { useState } from "react";
import { OTPForm } from "../../layouts/Auth";
import { SignInForm } from "../../layouts/Auth";
const Contexts = require("../../contexts");
function AuthLayout() {
  const [authState, authDispatch] = useState({
    phone: "",
    step: 0,
    otp: "",
  });
  
  return (
    <section className="bg-gray-50">
      <Contexts.Auth.Provider value={{ authState, authDispatch }}>
        {authState.step === 0 && <SignInForm />}
        {authState.step === 1 && <OTPForm />}
      </Contexts.Auth.Provider>
    </section>
  );
}

export default AuthLayout;
