/* eslint-disable react/prop-types */
import React, { useMemo, useContext } from "react";
import TableLayout from "./table-layout";
import PropTypes from "prop-types";
import { useSortBy, useTable } from "react-table";
import { OldOrderDetailsData, OrderDetailsData } from "../../contexts";
import ReOrderModal from "../reorder-form/reorder-modal";
import { toast } from "react-toastify";
import "../../styles/Tableinstance.scss";
import { request } from "../../config/helpers/axios-instance";
import { BsFillTrashFill } from 'react-icons/bs'
import { MdModeEditOutline } from "react-icons/md"
import { useNavigate } from "react-router-dom";
import { getOrderStatuses } from "../../utils/helpers";
import { DIGITIZE_ORDER_TYPE, PRESORTED_ORDER_TYPE } from "../../utils/constants.js";


// eslint-disable-next-line no-undef
const Constants = require("./Constants");
const Cell = (row, reOrderModalClick, handleDeleteRow, OrderType) => {
    const navigate = useNavigate();
    const { setOldOrder } = React.useContext(OldOrderDetailsData);

    return (
        <div className="w-full h-full flex flex-row gap-[3px] items-center">
            {
                Constants.COLUMNS_FUCTIONALITIES[OrderType].includes("Reorder") &&
                <div className="flex flex-row items-center">
                    <button
                        id="create-order"
                        className="px-4 py-2 rounded-2xl font-sans create-order"
                        onClick={() => {
                            if (row.row.original.addressId == "0") {
                                toast.error('Please add address first')
                            } else {
                                setOldOrder(row.row.original);
                                reOrderModalClick();
                            }
                        }}>
                        ReOrder
                    </button>
                </div>
            }
            {Constants.COLUMNS_FUCTIONALITIES[OrderType].includes("Delete") && < button
                id="create-order"
                className="px-4 py-2 rounded-2xl font-sans create-order bg-[red]/[0.85]"
                onClick={async () => {
                    if (confirm("Do you wish to delete the order")) {
                        const res = await request({
                            url: `/userOrder/admin/id/${row?.cell?.row?.values?.id}`,
                            method: "DELETE",
                        })
                        if (res && res.data.success) {
                            // Here we have to delete the row
                            handleDeleteRow(row?.cell?.row?.values?.id);
                        }
                    }
                }}>
                <BsFillTrashFill />
            </button>}
            {Constants.COLUMNS_FUCTIONALITIES[OrderType].includes("Edit") && < a rel="noreferrer" className="px-4 py-2 rounded-2xl bg-blue-500 cursor-pointer" onClick={() => navigate(`/order/edit/${row?.cell?.row?.values?.id}?orderType=${OrderType}`, { replace: true })}>
                <MdModeEditOutline className=" text-[white] text-xl" />
            </a>}

        </div >
    );

};

// Order Status Column with ts Functions
const Cell2 = (row, type, types, refetchOrders) => {
    return (
        <div className="px-2 text-black">
            <select
                style={{
                    border: "1px solid #b0b5ba",
                    borderRadius: "6px"
                }}
                className="w-[100%] "
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={async (e) => {
                    try {
                        const response =
                            await request({
                                method: "PUT",
                                url: `/userOrder/admin/id/${row.row.original.id}`,
                                data: type === 'status' ? {
                                    status: e.target.value
                                } : {
                                    type: e.target.value
                                }
                            })

                        if (!response.data.success) {
                            toast.warn("Please Try After Sometime")
                        }
                        else {
                            refetchOrders()
                        }
                    }
                    catch (err) {
                        toast.warn("Please Try After Sometime")
                    }
                }}>
                {
                    types?.map((value) => {
                        return (
                            <option selected={row?.row?.original?.status === value} key={value} value={value}>{value}</option>
                        )
                    })
                }
            </select>
        </div>
    );
};

function TableInstance({ tableData, changeRightBarOpen, pageCount, setPageSort, pageSort, handleDeleteRow, refetchOrders }) {
    const orderStatuses = getOrderStatuses();
    const { OrderType } = useContext(OrderDetailsData);
    const [modal, showModal] = React.useState(false);
    const reOrderModalClick = () => {
        showModal(!modal);
    };
    const [oldOrder, setOldOrder] = React.useState(null);
    const [columns, data] = useMemo(() => {
        let columns;
        switch (OrderType) {
            case PRESORTED_ORDER_TYPE:
                columns = Constants.ORDERS_COLUMN
                columns = [
                    ...columns,
                    {
                        Header: "Status",
                        accessor: "status",
                        Cell: (row) => Cell2(row, 'status', orderStatuses[PRESORTED_ORDER_TYPE], refetchOrders)
                    },
                ];
                break;
            case 'ReOrder':
                columns = Constants.REORDERS_COLUMN
                break;
            case DIGITIZE_ORDER_TYPE:
                columns = Constants.DIGITIZE_COLUMN
                columns = [
                    ...columns,
                    {
                        Header: "Status",
                        accessor: "status",
                        Cell: (row) => Cell2(row, 'status', orderStatuses[DIGITIZE_ORDER_TYPE], refetchOrders)
                    },

                ];
                break;
            default:
                columns = Constants.ORDERS_COLUMN
                break;
        }
        columns = [
            ...columns,
            {
                Header: "Action",
                accessor: "action",
                Cell: (row) => Cell(row, reOrderModalClick, handleDeleteRow, OrderType),
            },
        ];
        return [columns, tableData];
    }, [tableData]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { sortBy: pageSort },
            manualSortBy: true,
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
        },
        useSortBy
    );



    return (
        <OldOrderDetailsData.Provider value={{ oldOrder, setOldOrder }}>
            {oldOrder && oldOrder.addressId != "0" && (
                <ReOrderModal
                    oldOrderDetails={oldOrder}
                    value={modal}
                    action={reOrderModalClick}
                    id={oldOrder.id}
                />
            )}

            <TableLayout {...tableInstance}
                changeRightBarOpen={changeRightBarOpen}
                pageCount={pageCount}
                setPageSort={setPageSort}
                refetchOrders={refetchOrders}
            />
        </OldOrderDetailsData.Provider>
    );
}

export default TableInstance;

TableInstance.propTypes = {
    tableData: PropTypes.array.isRequired,
    changeRightBarOpen: PropTypes.func.isRequired,
    pageCount: PropTypes.number,
    setPageSort: PropTypes.any,
    pageSort: PropTypes.any,
    RowSelectHandler: PropTypes.any,
    SelectedRows: PropTypes.any
    // setOrderChanged: PropTypes.any
};

Cell.propTypes = {
    row: PropTypes.any,
};