import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import classes from "../../styles/AddressPrint.module.scss";

import DeliveryQr from './DeliveryQr';
import { generateDeliveryQrCode } from './helper';

function MutlipleAddresses (props) {
    const [addreses, setAddressData] = useState([]);
    const getAddresesAndQrData = async (data) => {
        let qrCodeValue = await generateDeliveryQrCode(data.orderId);
        return { address: data.address, qrCodeValue }
    }
    useEffect(() => {
        (async () => {
            let addreses = await Promise.all(
                props.addresses.map((data) => {
                    return getAddresesAndQrData(data)
                })
            )
            setAddressData(addreses)
        })()
    }, [])
    return (
        <div className={classes.Container} >
            {
                addreses.map((data, ind) => {
                    return (
                        <>
                            {data?.address && <div key={ind}>
                                <div id="section-to-print" className='flex flex-row gap-1  justify-start items-start'>
                                    <div className='w-[80%]'>
                                        <h3>To,</h3>
                                        <div className='w-[100%] flex flex-col gap-2 mt-2'>
                                            <div className='flex flex-col gap-2 w-max-[95%] font-semibold'>
                                                <h5 className='text-xl'>NAME: &nbsp; {data?.address ? data?.address?.name?.toUpperCase() : ""}</h5>
                                                <h5 className='text-xl'>PHONE: &nbsp; {data?.address ? data?.address?.phone : ""}</h5>
                                            </div>
                                            <div className='flex text-xl flex-row font-semibold w-[100%]'>
                                                <h5>ADDRESS:  &nbsp;{data?.address?.line ? `${data?.address?.line} ` : ''}
                                                    {data?.address?.district ? `${data?.address?.district} ` : ''}
                                                    {data?.address?.state ? `${data?.address?.state} ` : ''}
                                                    {data?.address?.pincode ? data?.address?.pincode : ''}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-[20%]'>
                                        {data.qrCodeValue && <DeliveryQr data={data.qrCodeValue} />}
                                    </div>
                                </div>
                            </div>}
                            <br />
                        </>
                    )
                })
            }

        </div >
    )
}

export default MutlipleAddresses

MutlipleAddresses.propTypes = {
    addresses: PropTypes.any
}