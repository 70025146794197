/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import { Form, Formik, ErrorMessage } from "formik";
import React, { useState } from "react";
import dynamicDestructure from "../../../hooks/custom-hooks/create-filtered-data";
import GetAllCountries from "../../../hooks/fetch-hooks/fetch-all/get-all-countries";
import { TextField } from "../TextField";
import { checkSafe } from "../../../config/helpers/checkParseSafe";
import { request } from "../../../config/helpers/axios-instance";
import { toast } from "react-toastify";
import { CheckValidPincode } from "../../../hooks/custom-hooks/check-pincode";
// eslint-disable-next-line no-undef
const Constants = require("./Constants");

function CreateAddress({
    selectedUser,
    selectedAddress,
    toggle,
    fetchAddress,
    refetch,
    optional
}) {
    const Initialvalues = Constants.initialValues;
    Initialvalues.name = selectedUser.name;
    Initialvalues.phone = selectedUser.phone;
    const { data: countries } = GetAllCountries();
    const [allcountries, setAllCountries] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [LongLat, setLongLat] = React.useState({});
    const [isDefault, setIsDefault] = useState(selectedAddress?.isDefault ? selectedAddress?.isDefault : false);

    React.useEffect(() => {
        if (countries) {
            setAllCountries(dynamicDestructure(countries, "data,data,rows"));
        }
    }, [countries]);

    const handleSubmit = async (values) => {
        values.longitude = String(LongLat.longitude);
        values.latitude = String(LongLat.latitude);
        values.userId = parseInt(selectedUser.id);
        values['isDefault'] = isDefault;
        if (!checkSafe(values, Constants.ADDRESS_SCHEMA, Constants.NOT_NULL_SCHEMA))
            return;
        let address = await request({
            method: "POST",
            url: "address/admin",
            data: checkSafe(
                values,
                Constants.ADDRESS_SCHEMA,
                Constants.NOT_NULL_SCHEMA
            ).data,
        });
        if (address.data.success) {
            toast.success("Address Created");
            toggle();
            fetchAddress ? fetchAddress() : null
            optional ? optional() : null
        }
        else {
            toast.error(address.data.error);
        }
    }

    const handleUpdate = async (values) => {
        values['id'] = parseInt(values['id']);
        delete values['updatedAt']
        delete values['deletedAt']
        delete values['createdAt']
        values.longitude = String(LongLat.longitude ? LongLat.longitude : '');
        values.latitude = String(LongLat.latitude ? LongLat.latitude : '');

        values['isDefault'] = isDefault;

        try {
            let address = await request({
                method: "PUT",
                url: `address/admin/id/${selectedAddress.id}`,
                data: checkSafe(values, Constants.ADDRESS_SCHEMA).data,
            });
            if (address.data.success) {
                toast.success("Address Updated!");
                refetch();
                toggle();
            } else {
                toast.error(address.data.error);
            }
        } catch (error) {
            console.log(error);
            toast.error('Server Error')
        }

    };

    async function isValidPinCode(pincode, setFieldValue) {
        if (pincode.length === 6) {
            let data = await CheckValidPincode(pincode);
            if (!data.data.success) {
                toast.error("Invalid pincode");
                return;
            }
            let PincodeData = data.data.data;
            setFieldValue("state", PincodeData.StateName);
            setFieldValue("district", PincodeData.District);
            setLongLat({
                longitude: PincodeData.Longitude,
                latitude: PincodeData.Latitude,
            });
        }
    }
    return (
        <>
            <div className="pb-4">
                <div className="px-4 pt-4 pb-4">
                    <h1 className="font-semibold font-sans text-white-900">
                        New Address
                    </h1>
                </div>

                <div className="bg-white-700 w-full h-[1px] mt-2"></div>
                <Formik
                    initialValues={selectedAddress || Initialvalues}
                    enableReinitialize
                    onSubmit={(values) => {
                        if (selectedAddress) {
                            handleUpdate(values);
                        } else {
                            handleSubmit(values);
                        }
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className="mt-4 grid grid-cols-1 gap-4 items-center p-6 py-0">
                                <div className="grid grid-cols-1">
                                    <TextField
                                        required
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Flat, House no., Building, Company, Apartment"
                                        value={props.values.name}
                                    />
                                    <div className="mt-2"></div>
                                    <TextField
                                        required
                                        label="Address Line 2"
                                        name="line"
                                        type="text"
                                        placeholder="Area, Street, Sector, Village"
                                    />
                                    <div className="mt-2"></div>
                                    <div className="grid grid-cols-5 gap-2">
                                        <div className="mt-2 col-span-2">
                                            <TextField
                                                required
                                                label="Pincode"
                                                name="pincode"
                                                type="text"
                                                placeholder="Enter Pincode"
                                                maxLength="6"
                                                minLength="6"
                                                onKeyUp={(e) => {
                                                    isValidPinCode(
                                                        e.target.value,
                                                        props.setFieldValue
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="mt-2 col-span-3">
                                            <TextField
                                                required
                                                label="State"
                                                name="state"
                                                type="text"
                                                placeholder="Enter State"
                                                enableReinitialize={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-2"></div>
                                    <TextField
                                        required
                                        label="City"
                                        name="district"
                                        type="text"
                                        placeholder="Enter City"
                                        enableReinitialize={true}
                                    />

                                    {allcountries.length > 0 && (
                                        <div className="border-white-500 p-2 border-2 rounded-2xl mt-4">
                                            <h6 className="font-sans text-xs ">Country</h6>
                                            <select
                                                defaultValue={
                                                    (props.values.country = allcountries[0].name)
                                                }
                                                name="country"
                                                id=""
                                                required
                                                onChange={(e) => {
                                                    props.setFieldValue("country", e.target.value);
                                                }}
                                                className="w-full p-0 m-0 focus:outline-none active:outline-none border-none active:border-none focus:border-none focus:ring-0"
                                            >
                                                {allcountries.map((country, key) => (
                                                    <option key={key} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    <div className="mt-2"></div>
                                    <TextField
                                        label="Landmark"
                                        name="landmark"
                                        type="text"
                                        placeholder="Enter Landmark"
                                    />
                                    <div className="mt-2"></div>
                                    <TextField
                                        label="Phone Number"
                                        name="phone"
                                        required
                                        type="tel"
                                        pattern="^\d{10}$"
                                        placeholder="Enter Phone Number"
                                    />
                                    <div className="mt-2"></div>
                                    {/* // checkbox for default address */}
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={isDefault}
                                            onChange={() => setIsDefault(!isDefault)}
                                            className="w-4 h-4 border-2 border-white-500 rounded-2xl focus:outline-none focus:ring-0 focus:border-none active:outline-none active:border-none active:ring-0"
                                        />
                                        <label
                                            htmlFor="default"
                                            className="ml-2 text-white-900 font-sans text-xs"
                                        >
                                            Set as default address
                                        </label>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full bg-elm-500 text-white-100 hover:bg-elm-700 font-semibold font-sans py-2 rounded-2xl"
                                >
                                    {selectedAddress ? "Update" : "Create"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

CreateAddress.propTypes = {
    selectedUser: PropTypes.any,
};

export default CreateAddress;
