import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";

const GetPrescriptionsByUserId = (userId) => {
    return useQuery(
        ["get-prescription-by-userId"],
        () =>
            request({
                url: `prescription/admin/`,
                method: "GET",
                params: {
                    userId: userId
                }
            }),
        {
            enabled: !!userId,
        }
    );
};

export default GetPrescriptionsByUserId;
