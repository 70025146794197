import { request } from "../../config/helpers/axios-instance";

const CheckValidPincode = async (pincode) =>

    await request({
        url: `address/pincode/${pincode}`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
export { CheckValidPincode };