/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useContext } from "react";
// eslint-disable-next-line no-undef
const Constants = require("./Constants");
import { MdEditLocationAlt, MdModeEditOutline } from "react-icons/md";
import { useSortBy, useTable } from "react-table";
import TableLayout from "./table-layout";
import { UsersData } from "../../../contexts";
import { CreateUser } from "../../../components/modal-forms/create-user";
import { useModalMain } from "../../../contexts/modal";

const Cell = (row, toggleModal) => {
  const { openModal } = useModalMain();

  const { setSelectedUser, refetch } = useContext(UsersData);
  return (
    <div
      className="flex justify-start w-full gap-2"
      style={{ zIndex: "2000000" }}
      id="right-bar"
    >
      <button
        id="create-order"
        className="text-2xl font-sans flex justify-start"
        onClick={() => {
          toggleModal();
          setSelectedUser(row.cell.row.values);
        }}
      >
        <MdEditLocationAlt className="text-elm-500 font-medium" />
      </button>
      <button
        className="text-2xl font-sans flex justify-start"
        onClick={() =>
          openModal(CreateUser, "Edit User", row.row.original, true, refetch)
        }
      >
        <MdModeEditOutline className="text-elm-500 font-medium" />
      </button>
    </div>
  );
};

const TableInstance = ({
  tableData,
  toggleModal,
  pageCount,
  setPageSort,
  pageSort,
}) => {
  const [columns, data] = React.useMemo(() => {
    let columns = Constants.COLUMNS;
    columns = [
      ...columns,
      {
        Header: "Action",
        accessor: "action",
        Cell: (row) => Cell(row, toggleModal),
      },
    ];

    return [columns, tableData];
  }, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { sortBy: pageSort },
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy
  );
  return (
    <>
      <TableLayout
        {...tableInstance}
        pageCount={pageCount}
        setPageSort={setPageSort}
      />
    </>
  );
};

TableInstance.propTypes = {
  tableData: PropTypes.any,
  changeRightBarOpen: PropTypes.any,
  toggleModal: PropTypes.any,
  pageCount: PropTypes.number,
  setPageSort: PropTypes.any,
  pageSort: PropTypes.any,
};

Cell.propTypes = {
  row: PropTypes.any,
  toggleModal: PropTypes.any,
};

export { TableInstance };
