import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";


const GetBatch = (id) => {
    return useQuery(
        ["item-details-by-user-id", id],
        () => request({
            url: `/item/admin/${id}`,
            method: "GET",
        })
    )
}

export default GetBatch;