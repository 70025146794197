import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import TableLayout from "./table-layout";
const Constants = require("./Constants");

function TableInstance ({ tableData }) {
    const [columns, data] = useMemo(() => {
        let columns = Constants.COLUMNS;
        return [columns, tableData];
    }, [tableData]);

    const tableInstance = useTable({ columns, data });
    return (
        <>
            <TableLayout {...tableInstance} />
        </>
    );
}

TableInstance.propTypes = {
    tableData: PropTypes.array.isRequired,
};

export { TableInstance };
