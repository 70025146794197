import React from 'react';
import { useModalMain } from './modal';
import classes from '../styles/Modal.module.scss';
import { VscChromeClose } from "react-icons/vsc";

export default function Modal() {
    const { Component, closeModal } = useModalMain();
    return Component ? (
        <>
            <div className={classes.backdrop} onClick={closeModal}></div>
            <div className={classes.container}>
                <div className={classes.innercontainer}>
                    <button onClick={closeModal} className=' fixed top-2 right-2 p-1 text-black hover:text-white rounded-[50%]  hover:bg-black hover:bg-opacity-10 ' >
                        <VscChromeClose className='text-xl font-bold' />
                    </button>
                    <div className={classes.component}>
                        <Component />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
}
