import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";


const GetAllowedOrderStatuses = () => {
    return useQuery(
        ["get-all-allowed-statuses"],
        () =>
            request({
                url: `userOrder/admin/allowedStatus`,
                method: "GET",
            })
        , {
            staleTime: 1000000
        }

    );
};

export default GetAllowedOrderStatuses;
