// dynamic destructuring of the data object to get the data we need from the response
// const { data: { data: { users } } } = useGetAllUsers();
// alse filter the keys we need from the data object to get the data we need from the response
// if the keys are not provided, it will return the whole data object


const dynamicDestructure = (data, path = "") => {
    const pathArray = path.split(",");

    if (pathArray.length === 0) return data;
    if (data) {
        let result = { ...data };
        pathArray.forEach((key) => {
            result = result[key];
        });
        return result;
    }
    return null;
}


export default dynamicDestructure;