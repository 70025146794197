import { createSlice } from "@reduxjs/toolkit";
export const stepConstants = {
    SELECT_USER: "SELECT_USER",
    SET_ORDER_DURATION: "SET_ORDER_DURATION",
    ADD_MEDICINE: "ADD_MEDICINES",
    WATCH_CART: "WATCH_CART",
    ADD_EXISTING_DETAILS: "ADD_EXISTING_DETAILS",
    GENERATE_SPLIT_TABLES: "GENERATE_SPLIT_TABLES",
    GET_SPLIT_NUMBER: "GENERATE_SPLIT_NUMBER",
};
const initialState = {
    currentStep: 0,
    isLoading: false,
    isError: false,
    errorMessage: "",
    type: stepConstants.SELECT_USER,
};

export const StepsSlice = createSlice({
    name: "steps",
    initialState,
    reducers: {
        setCurrentStep: (state, action) => {
            state.type = action.payload;
        },
        goPrevStep: (state, action) => {
            state.type = action.payload;
        },
        goNextStep: (state, action) => {
            state.type = action.payload;
        },
        resetSteps: (state) => {
            state.type = stepConstants.SELECT_USER;
        },
    },
});

export const { setCurrentStep, goPrevStep, goNextStep, resetSteps } =
    StepsSlice.actions;
export default StepsSlice.reducer;
