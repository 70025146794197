/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Modal } from "flowbite-react";
import { deleteCallback } from "../../../hooks/update-hooks/delete-callbacks/delete-callback";
import { CallBackContext } from "../../../contexts";
import { toast } from "react-toastify";

function DeleteOrderModal ({ callback }) {
    const { refetch } = useContext(CallBackContext);
    const [modalToggle, setModal] = React.useState(false)
    function deleteOrder (id) {
        deleteCallback(parseInt(id)).then(() => {
            toast.success('Deleted Callback Successfully')
            refetch();
            setModal(false);
        }).catch((e) => { toast.error(e); })
    }

    return <React.Fragment >

        <button onClick={() => {
            setModal(true)
        }} className="bg-red-500 p-2 text-xs text-white-50 rounded-md font-sans text-md font-semibold  flex justify-center" >
            Delete
        </button>

        <Modal
            show={modalToggle}
            onClose={() => {
                setModal(false)
            }}>
            <Modal.Header style={{ backgroundColor: "white" }}>
                Delete
            </Modal.Header>
            <Modal.Footer style={{ backgroundColor: "white" }}>
                <h1>Are you sure you want to delete?</h1>
                <button onClick={() => deleteOrder(callback.id)} className="bg-red-500  text-white-50 p-3 rounded-md font-sans flex justify-center text-center">
                    Delete
                </button>
                <button onClick={() => setModal(false)} className="bg-elm-500  text-white-50 p-3 rounded-md font-sans flex justify-center text-center">
                    Cancel
                </button>

            </Modal.Footer>
        </Modal>
    </React.Fragment >
}

export { DeleteOrderModal };