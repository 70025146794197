let COLORS = {
  MORNING: "#FFC107",
  AFTERNOON: "#FF9800",
  EVENING: "#BBDEFB",
  NIGHT: "#3a5d74",
};
let STYLES = {
  date: {
    position: "relative",
    top: "125px",
    zIndex: "4",
    left: "745px",
    // height: "50px",
    width: "auto",
  },
  pillupLogo: {
    position: "relative",
    top: "70px",
    zIndex: "5",
    left: "870px",
    height: "50px",
    width: "auto",
  },
};
export { COLORS, STYLES };
