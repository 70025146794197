import moment from "moment";

const relationships = [
    { label: "Self" },
    { label: "Father" },
    { label: "Mother" },
    { label: "Brother" },
    { label: "Sister" },
    { label: "Son" },
    { label: "Daughter" },
    { label: "Other" },
];

const source = [
    { label: "Facebook" },
    { label: "Whatsapp" },
    { label: "Website" },
    { label: "B2B" },
    { label: "Doctor" },
    { label: "Other" },
];


const users = [
    { label: "Deepanshu Goel" },
    { label: "Rahul Goel" },
    { label: "Vishal Sharai" },
    { label: "Jane Doe" },
    { label: "XYZ" }
];
const USERORDER_SCHEMA = {
    addressId: 'number',
    transactionId: 'number',
    userMappingId: 'number',
    prescriptionId: 'number',
    status: 'string',
    noOfDays: 'number',
    startDate: 'string',
    endDate: 'string',
    notes: 'string',
    rxLink: 'string',
    ocsFile: 'string',
};

const NOT_NULL_SCHEMA = [
    'addressId',
    'transactionId',
    'userMappingId',
    'prescriptionId',
    'status',
    'noOfDays',
    'startDate',
    'endDate',
];


const PRESCRIPTION_SCHEMA = {
    drName: 'string',
    userId: 'number',
    date: 'date',
    userMappingId: 'number',
    isValid: 'boolean',
    link: 'string',
};

const NOT_NULL_PRESCRIPTION_SCHEMA = [
    'drName',
    'userId',
    'date',
    'userMappingId',
    'isValid',
]

const prescriptionInitialValues = {
    drName: "",
    userId: "",
    date: moment().format("YYYY-MM-DD"),
    userMappingId: "",
    isValid: true,
    links: [],
}

const initialValues = {
    addressId: "",
    userMappingId: "",
    prescriptionId: "",
    status: "ORDER_PENDING",
    noOfDays: 1,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    notes: "",
    image: []
};

export { relationships, source, users, initialValues, USERORDER_SCHEMA, NOT_NULL_SCHEMA, prescriptionInitialValues, PRESCRIPTION_SCHEMA, NOT_NULL_PRESCRIPTION_SCHEMA };