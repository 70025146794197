const COLUMNS = [
  {
    Header: "Bill Number",
    accessor: "billNumber",
  },
  {
    Header: "Patient Name",
    accessor: "patientName",
  },
  {
    Header: "Date",
    accessor: "billDate",
  },
  {
    Header: "Mobile",
    accessor: "billingForMobile",
  },
];

export { COLUMNS };
