import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "../../../styles/Instance.scss";
import { useNavigate } from "react-router-dom";

function TableLayout ({
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setPageSort
}) {
    const { sortBy } = state
    const navigate = useNavigate();

    useEffect(() => {
        setPageSort(sortBy)
    }, [sortBy])


    const onClick = (row) => {
        navigate(`/item-details/${row.original.id}`);
    };

    return (
        <div className="block p-2 px-4 bg-[#ffffff] rounded-2xl shadow-soft-xl mt-4">
            <table key="future-orders" {...getTableProps()} className="w-full font-sans table-auto">
                <thead className="h-16 border-b-2 rounded-2xl  border-gray-100">
                    {headerGroups?.map((headerGroup, id) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={id}
                            className="text-left border-b-2 border-gray-100">
                            {headerGroup.headers.map((column, id) => (
                                <>
                                    {column.Header === "#" ?
                                        <th
                                            className="font-sans text-left pl-3"
                                            key="medicine-master"
                                            {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                                            </span>
                                        </th>
                                        : <th
                                            className="pl-3 font-sans text-left"
                                            key={id}
                                            {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>}
                                </>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows?.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className=" text-left border-t border-gray-100 h-14 hover:bg-slate-200 pl-3 font-sans "
                                key={rowIndex}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <React.Fragment key={cellIndex}>
                                            {cell.column.Header === "#" ? (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="pl-3 text-left underline hover:text-black text-elm-500 font-medium cursor-pointer"
                                                    key={cellIndex}
                                                    onClick={() => {
                                                        onClick(row);
                                                    }}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            ) : (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={
                                                        cell.column.Header === "Name"
                                                            ? "pl-3 py-4 text-left  font-sans uppercase text-break"
                                                            : "pl-3 py-4 text-left  font-sans text-break"
                                                    }
                                                    key={cellIndex}>
                                                    {cell.render("Cell")}
                                                </td>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default React.memo(TableLayout);


TableLayout.propTypes = {
    getTableProps: PropTypes.any,
    getTableBodyProps: PropTypes.func.isRequired,
    headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    prepareRow: PropTypes.func.isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object),
    state: PropTypes.objectOf(PropTypes.any),
    pageOptions: PropTypes.any,
    pageCount: PropTypes.number,
    setPageIndex: PropTypes.func,
    Index: PropTypes.number,
    setPageSort: PropTypes.func,
    nextPage: PropTypes.any,
    previousPage: PropTypes.any,
    canNextPage: PropTypes.any,
    canPreviousPage: PropTypes.any,
    name: PropTypes.string,
    setName: PropTypes.func
};
