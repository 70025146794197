/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import MedicineTimeContainer from '../components/MedicineTime';
import { IoMdAddCircleOutline } from "react-icons/io"
import moment from "moment"
import NameBatchModal from '../components/NameBatchModal';
import { FiEdit2 } from 'react-icons/fi';
import BatchIcon from "../../../assets/batchicon.svg"
import { BiDuplicate } from "react-icons/bi"
import { MdDelete, MdToday } from "react-icons/md"
import UserPreferedTimingContainer from '../components/userPreferedTimings';
import { toast } from 'react-toastify';
import { DAY_PARTS } from '../Constants';
export default function PrescriptionItem({ addItem, deleteItem, updateItem, itemId, Items, userTimings }) {
    const [medicineTimings, setMedicineTimings] = useState(null);
    const [showItemmodal, setShowItemModal] = useState(false);
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    useEffect(() => {
        let timings = {};
        Items[itemId]?.meal?.map((meal, ind) => {
            timings[ind] = { meal: Items[itemId]?.meal[ind], time: Items[itemId]?.time[ind] }
        })
        setMedicineTimings(timings)
    }, [Items])

    function UpdatePrescriptionItemsApiCall(newTimings) {
        let meal = [], time = [], payLoadData = {};
        Object.keys(newTimings).map((key) => {
            meal.push(newTimings[key].meal);
            time.push(newTimings[key].time);
        })
        payLoadData['meal'] = meal;
        payLoadData['time'] = time;
        updateItem(itemId, { meal, time });
    }

    /**
        * Adds a new medicine time as same as the last one 
    */
    function AddMedicineTiming(time) {
        let newTimings = { ...medicineTimings };
        let size = Object.keys(newTimings).length;
        if (size == 0) {
            if (time) {
                newTimings[0] = { time: time, meal: 'AFTER' }

            }
            else {
                newTimings[0] = { time: "10:00", meal: "AFTER" }
            }
        }
        else {
            if (time) {
                for (let index = 0; index < size; index++) {
                    if (newTimings[index].time === time) {
                        toast.warn('Time is already added!')
                        return;
                    }
                }
                newTimings[parseInt(Object.keys(newTimings)[size - 1]) + 1] = { time: time, meal: 'AFTER' }
            }
            else {
                newTimings[parseInt(Object.keys(newTimings)[size - 1]) + 1] = { ...Object.values(newTimings)[size - 1] }
            }
        }
        setMedicineTimings(newTimings);
        UpdatePrescriptionItemsApiCall(newTimings);
    }

    /**
         * Deletes a Medicine time
         * @param id  id which is the index of timing
     */
    function deleteMedicineTiming(id) {
        let newTimings = { ...medicineTimings };
        delete newTimings[id]
        setMedicineTimings(newTimings);
        UpdatePrescriptionItemsApiCall(newTimings)
    }

    /**
        * Updates a Medicine time
        * @param id  id of the time to be updated
        * @param name name of the property that has to be updated
        * @param value value to be updated
        * 
    */
    function updateMedicineTiming(id, name, value) {
        let newTimings = { ...medicineTimings };
        newTimings[id][name] = value;
        setMedicineTimings(newTimings);
        UpdatePrescriptionItemsApiCall(newTimings);
    }

    return (
        < div className={'flex flex-col rounded-2xl w-full bg-[white] p-5 gap-5 border-solid ' + (Items[itemId]?.messages?.length > 0 ? " border-[2px] border-[red]" : "border-[1px] border-[#ccc]")} id={`prescriptionItem-${itemId}`}>
            {Items[itemId]?.messages?.map((message, key) => {
                return (
                    <p key={key} className='text-[red] text-bold' > {message.text}</p>
                )
            })}

            <div className='flex flex-row flex-wrap items-center justify-between gap-3 '>
                <div className='flex flex-row flex-grow gap-2 items-center'>
                    <p className=' px-2 py-1 h-fit bg-[pink] text-black-50 text-base rounded-xl'>#{itemId}</p>
                    <p className='text-xl font-bold max-w-[200px] overflow-hidden whitespace-nowrap'>{Items[itemId]?.item?.name}</p>
                    <p className=' px-2 py-1 h-fit bg-[#049079] text-white-50 text-base rounded-xl'>#{Items[itemId]?.itemId}</p>
                    <FiEdit2 onClick={() => setShowItemModal(true)} />
                    {showItemmodal && <NameBatchModal item={Items[itemId]?.item} onClose={() => setShowItemModal(false)} type='update' prescriptionItemId={itemId} updateItem={updateItem} modalToggle={setShowItemModal} />}
                </div>
                <div className='flex flex-row flex-grow gap-3 items-center flex-auto' >
                    <img className=' h-7' src={BatchIcon}></img>
                    <p className='text-lg font-bold'>{Items[itemId]?.itemBatch?.batch}</p>
                    <p className='text-sm text-[white] py-1 px-2 bg-[#0E2A51] rounded-lg'>#{Items[itemId]?.itemBatch?.id}</p>
                </div>
                <p className='p-2 rounded-2xl flex flex-row font-bold text-base gap-1 flex-auto'>Expiry:<span className='font-medium text-base'>{moment(Items[itemId]?.itemBatch?.expireDate).format('Do MMM YYYY')}</span></p>
                <p className='p-2 rounded-2xl flex flex-row font-bold text-base gap-1  flex-auto max-w-[200px] overflow-hidden whitespace-nowrap'>Manufac:<span className='font-medium text-base'>{Items[itemId]?.itemBatch?.manufacture}</span></p>
                <div className='flex flex-row flex-grow gap-3 justify-end flex-auto'>
                    <button className='p-2 text-lg  rounded-xl bg-[#E4DEFE] text-[#6448CE] flex flex-row items-center gap-1' onClick={() => { addItem(itemId, 'duplicate') }}><BiDuplicate className="text-2xl" /> Duplicate</button>
                    <button className='p-2 text-lg  rounded-xl bg-[#DFDFDF] text-[black] flex flex-row items-center gap-1' onClick={() => { addItem(itemId, 'today'); }}> <MdToday className="text-2xl" /> Today</button>
                    <button className='p-2 text-lg  rounded-xl bg-[#FF0000] text-[white] flex flex-row items-center gap-1' onClick={() => { deleteItem(itemId) }}><MdDelete className="text-2xl" /> Delete</button>
                </div>
            </div>

            <div className='flex flex-row  flex-wrap  gap-4 items-end' style={{ flex: "1 1 auto" }}>
                <div className='flex flex-col flex-auto'>
                    <label>Start Date</label>
                    <input className='rounded-xl h-10' onChange={(e) => { updateItem(itemId, { [e.target.name]: moment(e.target.value).format() }) }} value={moment(Items[itemId]?.startDate).format('YYYY-MM-DD')} name='startDate' type='date'></input>
                </div>
                {Items[itemId]?.typeOfAdmin !== 'DATE' && <div className='flex flex-col'>
                    <label>Duration</label>
                    <input className='rounded-xl h-10' min={1} onChange={(e) => { updateItem(itemId, { [e.target.name]: parseInt(e.target.value) }) }} value={Items[itemId]?.duration} name='duration' type='number'></input>
                </div>}
                <div className='flex flex-col flex-auto'>
                    <label>End Date</label>
                    <input className='rounded-xl h-10 opacity-50' disabled value={moment(Items[itemId]?.endDate).format('YYYY-MM-DD')} name='endDate' type='date'></input>
                </div>
                <div className='flex flex-col flex-auto'>
                    <label>Type</label>
                    <select className='rounded-xl h-10 w-full' name='typeOfAdmin' value={Items[itemId]?.typeOfAdmin} onChange={(e) => { updateItem(itemId, { [e.target.name]: e.target.value }) }}>
                        <option value="DAYS">Days</option>
                        <option value="DATE">Date</option>
                        <option value="ALTERNATE">Alternate</option>
                    </select>
                </div>
                <div className='flex flex-col gap-1 flex-auto'>
                    <label>DoseSize</label>
                    <select className='rounded-lg w-full h-10' value={Items[itemId]?.doseSize} onChange={(e) => { updateItem(itemId, { 'doseSize': parseFloat(e.target.value) }) }}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="0.5">0.5</option>
                        <option value="0.25">0.25</option>
                    </select>
                </div>
                {Items[itemId]?.typeOfAdmin === 'DAYS' && <div className='flex flex-row gap-2 flex-wrap flex-auto'>
                    {days.map((day) => {
                        return (
                            <div key={day} onClick={() => { updateItem(itemId, { [day]: !Items[itemId][day] }) }} className={`cursor-pointer text-lg rounded-lg h-10 w-10 justify-center text-center flex flex-row items-center px-3 text-[white] border-elm-600 border ${Items[itemId][day] ? ' bg-elm-500 text-[white]' : ' bg-[white] text-black'}
                            ` }>
                                {day.charAt(0).toUpperCase()}
                            </div>
                        )
                    })}

                </div>
                }
            </div>
            {
                medicineTimings &&
                <div className='flex flex-col justify-start flex-wrap items-start gap-1'>
                    {userTimings && <div className='flex flex-row gap-4 mt-2 mb-3'>  {
                        DAY_PARTS.map((key, index) => {
                            if (Object.prototype.hasOwnProperty.call(userTimings, key)) {
                                if (key === 'CUSTOM') {
                                    return userTimings[key].map((timing) => {
                                        return <UserPreferedTimingContainer key={index} dayPart={key} time={timing} AddMedicineTiming={AddMedicineTiming} />
                                    })
                                }
                                return <UserPreferedTimingContainer key={index} dayPart={key} time={userTimings[key]} AddMedicineTiming={AddMedicineTiming} />
                            }
                        })
                    }
                    </div>}
                    <div className='flex flex-row items-center justify-center gap-2'>
                        {Object.keys(medicineTimings).map((key) => {
                            return (<MedicineTimeContainer updateMedicineTiming={updateMedicineTiming} deleteMedicineTiming={deleteMedicineTiming} ind={key} key={key} timing={medicineTimings[key]} />)
                        })
                        }
                            <IoMdAddCircleOutline className='text-3xl text-elm-500 cursor-pointer' onClick={() => { AddMedicineTiming() }} />
                    </div>
                </div>
            }
        </div >
    )
}
