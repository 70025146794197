/* eslint-disable react/prop-types */
import React from "react";
import { updateCallback } from "../../../hooks/update-hooks/update-callbacks/update-callback";
import { toast } from "react-toastify";
function StatusDropdown({ callback }) {

    const [selctedStatus, setSelctedStatus] = React.useState(callback.status);
    const changeStatus = (e) => {

        setSelctedStatus(e.currentTarget.value);
        callback.status = e.currentTarget.value;
        try {
            updateCallback({
                status: e.currentTarget.value
            }, parseInt(callback.id))
        } catch (error) {
            toast.error(error)
        }

    }
    return (
        <select className="bg-white-50 rounded-md w-[150px] h-10 font-medium" onChange={(e) => changeStatus(e)} value={selctedStatus} name="status" id="status">
            <option value="NEW" >New</option>
            <option value="CALLBACK">Callback</option>
            <option value="NO_ANSWER">No Answer</option>
            <option value="LOST">Lost</option>
            <option value="CONVERTED">Converted</option>
        </select>
    );


}

export { StatusDropdown };