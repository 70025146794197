import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";

const GetOrderDetailsByOrderId = (orderId) => {
    return useQuery(
        ["get-order-details-by-order-id", orderId],
        () =>
            request({
                url: `userOrder/admin/orderDetails/${orderId}`,
                method: "GET",
            }),
        {
            enabled: !!orderId,
            refetchOnWindowFocus: false
        }
    );
};

export { GetOrderDetailsByOrderId };
