import React from 'react';
import { useAddModalMain } from './AddModal';
import classes from '../../styles/Modal.module.scss';
import { VscChromeClose } from "react-icons/vsc";

export default function Modal() {
    const { Component, AddcloseModal } = useAddModalMain();
    return Component ? (
        <>
            <div className={classes.backdrop} onClick={AddcloseModal}></div>
            <div className={classes.container} style={{ verticalAlign: 'middle' }}>
                <div className={classes.innercontainer}>
                    <button onClick={AddcloseModal} className={classes.close}>
                        <VscChromeClose />
                    </button>
                    <div className={classes.component}>
                        <Component />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
}
