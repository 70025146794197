
const ORDERS_COLUMN = [
    {
        Header: "#",
        accessor: ""
    },
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Name",
        accessor: "mapUserPatient.User.name"
    },
    {
        Header: "EndDate",
        accessor: "endDate",
    },
    {
        Header: "Duration",
        accessor: "noOfDays",
    },
    {
        Header: "Partner",
        accessor: "partner.name"
    },
    {
        Header: "Left",
        accessor: "daysLeft",
    },
    {
        Header: "Inventory No",
        accessor: "inventoryId",
    }
];
const REORDERS_COLUMN = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Name",
        accessor: "mapUserPatient.User.name",
    },
    {
        Header: "State",
        accessor: "address.state",
    },
    {
        Header: "EndDate",
        accessor: "endDate",
    },
    {
        Header: "Duration",
        accessor: "noOfDays",
    },
    {
        Header: "Left",
        accessor: "daysLeft",
    },
    {
        Header: "Note",
        accessor: "notes"
    }

];
const DIGITIZE_COLUMN = [
    {
        Header: "#",
        accessor: ""
    },
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Name",
        accessor: "mapUserPatient.User.name"
    },
    {
        Header: "EndDate",
        accessor: "endDate",
    },
    {
        Header: "Duration",
        accessor: "noOfDays",
    },
    {
        Header: "Partner",
        accessor: "partner.name"
    },
    {
        Header: "Left",
        accessor: "daysLeft",
    },
    {
        Header: "Inventory No",
        accessor: "inventoryId",
    },

];




const COLUMNS_FUCTIONALITIES = {
    "PRESORTED_ORDERS": ['PrintMultipleAddress', "Reorder", "Delete", "Edit", "Status", "DownloadExcel", "selectAllRows"],
    "ReOrder": ["Reorder", "EditOrder", "DaysLeft", "DownloadExcel"],
    "DIGITIZE": ['PrintMultipleAddress', "Delete", "Edit", "Status", "DownloadExcel"]
}



const ORDER_TYPES = [
    "NEW_ORDER",
    "SUPPORT",
    "NEW_DIGITIZE_ORDER"
]

export { ORDERS_COLUMN, REORDERS_COLUMN, COLUMNS_FUCTIONALITIES, DIGITIZE_COLUMN, ORDER_TYPES };