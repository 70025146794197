import { request } from "../../../config/helpers/axios-instance";

const UpdatePrescriptionItem = async (itemId, data) =>
    await request({
        url: `prescriptionItem/admin/` + itemId,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        data: data

    })
export { UpdatePrescriptionItem };