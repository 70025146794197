import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import PageSlice from '../pagination/pageSlice';
import stepsReducer from '../stepSlice/stepSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    page:PageSlice,
    steps : stepsReducer,
  },
});
