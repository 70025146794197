/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { RxCross1 } from 'react-icons/rx'

const Modal = ({ title, children, onClose }) => {

    const handleCloseOnBackdropClick = (e) => {
        if (e.target.id === 'backdrop') {
            onClose();
        }
    }

    return (
        <div id='backdrop' onClick={(e) => handleCloseOnBackdropClick(e)} className=' w-[100vw] h-[100vh] fixed top-0 left-0 z-[200000] bg-[black]/[0.5] flex justify-center  items-center'  >
            <div className=' rounded-lg bg-[white] p-5 opacity-100 sm:w-[80%] md:w-[50%]'>
                <div className='flex flex-row justify-between mb-2' >
                    <strong className='text-xl' >{title}</strong>
                    <button onClick={onClose} ><RxCross1 /></button>
                </div>
                <hr />
                <div className='mt-2' >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal
