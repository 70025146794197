import React from "react";
import GetBatch from "../../../hooks/fetch-hooks/get-meds/get-batch";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const Heading = ({ heading, title }) => (
    <h2 className="flex flex-col flex-auto justify-between h-12 font-sans ">
        <span className="text-[#168c7c] text-xl font-bold font-sans">{title}</span>
        <span className="text-xl">{heading}</span>
    </h2>
);

export default function BatchPage() {
    const { id } = useParams()
    const location = useNavigate()
    const { data: Batch, isLoading } = GetBatch(id);
    const Main = Batch && Batch?.data?.data[0]?.itemBatches
    const medicineDetails = Batch && Batch?.data?.data[0];

    if (isLoading) {
        return (
            <h1>Loading....</h1>
        )
    }

    return (
        <div className=" px-6 flex flex-col gap-4 h-screen bg-[#efefef29]">
            <h2
                className="mb-2 cursor-pointer font-bold text-xl mt-3 p-2 text-[#28a48b]"
                onClick={() => {
                    location(-1);
                }}>
                {"<"} Back
            </h2>
            <h1 className="mt-3 font-bold text-2xl font-sans">#{medicineDetails.id} Medicine Details</h1>
            <div className=" mt-2 py-2 flex flex-row">
                <Heading
                    heading={medicineDetails.name}
                    title="Name"
                />

                <Heading
                    heading={medicineDetails.type}
                    title="Type"
                />

                <Heading
                    heading={medicineDetails.mnemonic}
                    title="Mnemonic"
                />

                <Heading
                    heading={medicineDetails.manufacture}
                    title="Manufacturer"
                />

                <Heading
                    heading={medicineDetails.genericName}
                    title="Generic Name"
                />
            </div>

            <h1 className="mt-4 font-bold text-2xl font-sans">Medicine Batches</h1>
            <div className="block bg-white rounded-2xl shadow-soft-xl w-[100%] bg-[white] mt-4 p-2 px-4  dark:hover:bg-gray-700">
                <table className="w-full p-4 bg-white-50 rounded-2xl font-sans table-auto">
                    <thead className="pl-3 px-4 text-left h-16 border-b-2 rounded-2xl  border-gray-100">
                        <tr>
                            <th className="pl-3 px-4">#</th>
                            <th className="pl-3 px-4">Batch</th>
                            <th className="pl-3 px-4">Manufacture</th>
                            <th className="pl-3 px-4">Unit Per Blister</th>
                            <th className="pl-3 px-4">Selling Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Batch && Main?.map((val) => {
                            return (
                                <tr key={val.id} className="pl-3 px-4 text-left border-b last:border-b-0 last:rounded-2xl border-gray-100 h-14 hover:bg-slate-200 font-sans ">
                                    <td className="pl-3 px-4 text-elm-500 font-medium">{val.id}</td>
                                    <td className="pl-3 px-4">{val.batch}</td>
                                    <td className="pl-3 px-4">{val.manufacture}</td>
                                    <td className="pl-3 px-4">{val.unitPerBlister}</td>
                                    <td className="pl-3 px-4">{val.sellingPrice}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

Heading.propTypes = {
    heading: PropTypes.any,
    title: PropTypes.string.isRequired,
};