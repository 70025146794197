import { Field, FieldArray, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import dynamicDestructure from "../../hooks/custom-hooks/create-filtered-data";
import { GetOrderDetailsByOrderId } from "../../hooks/fetch-hooks/order-details.js/get-order-details-by-id";

// eslint-disable-next-line no-undef
const Constants = require("./Constants");

const Card = ({ id, mapUserPatient }) => {
    return (
        <div className="p-2 w-full min-h-20 border-2 rounded-2xl font-sans">
            <div className="flex">
                <h3 className="text-xl font-sans font-bold">#{id}</h3>
                <h3>{mapUserPatient?.User?.name}</h3>
            </div>
        </div>
    );
};

Card.propTypes = {
    id: PropTypes.any,
    mapUserPatient: PropTypes.shape({
        User: PropTypes.shape({
            name: PropTypes.any,
        }),
    }),
};

const PrescriptionItemForm = () => {
    return (
        <Formik initialValues={Constants.initialValues}>
            {({ values }) => (
                <Form>
                    <FieldArray
                        name="prescriptionItems"
                        render={(arrayHelpers) => (
                            <div>
                                {values.prescriptionItems &&
                                    values.prescriptionItems.length > 0 ? (
                                    values.prescriptionItems.map((prescriptionItem, index) => (
                                        <div key={index}>
                                            <div className="flex justify-between">
                                                <div className="flex flex-col">
                                                    <label
                                                        htmlFor={`prescriptionItems.${index}.medicineName`}>
                                                        Medicine Name
                                                    </label>
                                                    <Field
                                                        name={`prescriptionItems.${index}.medicineName`}
                                                    />
                                                </div>
                                                <div className="flex flex-col">
                                                    <label
                                                        htmlFor={`prescriptionItems.${index}.strength`}>
                                                        Strength
                                                    </label>
                                                    <Field name={`prescriptionItems.${index}.strength`} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <button type="button" onClick={() => arrayHelpers.push({})}>
                                        Add a medicine
                                    </button>
                                )}
                            </div>
                        )}></FieldArray>
                </Form>
            )}
        </Formik>
    );
};

function PrescriptionItem () {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const { data: orderDetails } = GetOrderDetailsByOrderId(orderId);
    const [order, setOrder] = React.useState(null);
    React.useEffect(() => {
        if (!orderId) {
            navigate("/track-orders", {
                replace: true,
            });
        }
    }, [orderId, navigate]);

    React.useEffect(() => {
        if (orderDetails) {
            setOrder(dynamicDestructure(orderDetails, "data,data,rows")[0]);
        }
    }, [orderDetails]);

    return (
        <>
            {!order && <div>Loading...</div>}
            <div className="p-4">
                <Card {...order} />
                <PrescriptionItemForm />
            </div>
        </>
    );
}

export { PrescriptionItem };
