import { request } from "../../../config/helpers/axios-instance";

const DeletePrescriptionItem = async (id) =>

    await request({
        url: `/prescriptionItem/admin/` + id,
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },

    })
export { DeletePrescriptionItem };