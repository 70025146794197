import React from "react";
import AllMedicines from "./components/AllMedicines";

function MedicineMaster () {

    return (
        <div className="p-7 h-full overflow-y-scroll bg-gray-50">
            <AllMedicines />
        </div>
    );
}

export { MedicineMaster };
