import React from "react";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
} from "react-router-dom";
import { LOGIN, RouteConstants } from "../config/constants/route";
import Dashboard from "../layouts/Dashboard";
import NoPageFound from "../layouts/NoPageFound";

import {
    FutureOrders,
    Home,
    AllOrders,
    OrderDetails,
    PrxPage,
    DrxPage,
    TrackOrders,
    Users,
    PrescriptionItem,
} from "../pages";

import {
    InventoryCheck,
    QualifiedLead,
    ConfirmDigitalRx,
    ConfirmPayment,
    OcsFile,
    PackagingnDispenser,
    ProcessingOrder,
    Shipped,
    UploadPrx,
} from "../pages/track-orders";
import BatchPage from "../pages/medicine-master/batch-page/index";

import UserDetails from "../pages/user-details";
import AuthLayout from "./Auth";
import ProtectedRoutes from "./Auth/PrivateRoutes";
import RequestCallbacks from "../pages/new-request-callback";
import { MedicineMaster } from "../pages/medicine-master";
import EditOrder from "../pages/edit-orders";
import { DigitizeOrders } from "../pages/digitize";
// interface RouteObject {
//   path?: string;
//   index?: boolean;
//   children?: React.ReactNode;
//   caseSensitive?: boolean;
//   id?: string;
//   loader?: LoaderFunction;
//   action?: ActionFunction;
//   element?: React.ReactNode | null;
//   errorElement?: React.ReactNode | null;
//   handle?: RouteObject["handle"];
//   shouldRevalidate?: ShouldRevalidateFunction;
// }

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/" element={<Dashboard />}>
                    <Route path={"/"} element={<Navigate to={"/home"} replace />} />
                    <Route path={RouteConstants.HOME} element={<Home />} />
                    <Route path={RouteConstants.EDIT_ORDER} element={<EditOrder />} />
                    <Route path={RouteConstants.USERS} element={<Users />} />
                    <Route path={RouteConstants.USER_PAGE} element={<UserDetails />} />
                    <Route
                        path={RouteConstants.FUTUREORDERS}
                        element={<FutureOrders />}
                    />
                    <Route
                        path={RouteConstants.Medicine_Master}
                        element={<MedicineMaster />}
                    />
                    <Route path={RouteConstants.ALLORDERS} element={<AllOrders />} />
                    <Route
                        path={RouteConstants.ORDERDETAILS}
                        errorElement={<NoPageFound />}
                        element={<OrderDetails />}
                    />
                    <Route
                        path={RouteConstants.BatchDetails}
                        errorElement={<NoPageFound />}
                        element={<BatchPage />}
                    />
                    <Route path={RouteConstants.TRACK_ORDERS} element={<TrackOrders />}>
                        <Route
                            path=""
                            element={<Navigate to={RouteConstants.QUALIFIED_LEAD} replace />}
                        />
                        <Route
                            path={RouteConstants.QUALIFIED_LEAD}
                            element={<QualifiedLead />}
                        />
                        <Route
                            path={RouteConstants.INVENTORY_CHECK}
                            element={<InventoryCheck />}
                        />
                        <Route
                            path={RouteConstants.CONFIRM_DIGITAL_RX}
                            element={<ConfirmDigitalRx />}
                        />
                        <Route
                            path={RouteConstants.CONFIRM_PAYMENT}
                            element={<ConfirmPayment />}
                        />
                        <Route path={RouteConstants.OCS_FILE} element={<OcsFile />} />
                        <Route
                            path={RouteConstants.PACKAGING_AND_DISPENSER}
                            element={<PackagingnDispenser />}
                        />
                        <Route
                            path={RouteConstants.PROCESSING_ORDER}
                            element={<ProcessingOrder />}
                        />
                        <Route
                            path="track-orders/:orderId/prescription-item"
                            element={<PrescriptionItem />}
                        />
                        <Route path={RouteConstants.SHIPPED} element={<Shipped />} />
                        <Route path={RouteConstants.UPLOAD_PRX} element={<UploadPrx />} />
                    </Route>
                    <Route path={RouteConstants.PRXPAGE} element={<PrxPage />} />
                    <Route path={RouteConstants.DRXPAGE} element={<DrxPage />} />
                    <Route path={RouteConstants.USERDETAILS} element={<UserDetails />} />
                    <Route path={RouteConstants.REQUEST_CALLBACK} element={<RequestCallbacks />} />
                    <Route path={RouteConstants.DIGITIZE_ORDERS} element={<DigitizeOrders />} />

                </Route>

                <Route path="/details" element={<>Invalid ID</>}></Route>
            </Route>
            <Route
                path={LOGIN.LOGHOME}
                index
                element={<AuthLayout />}
                errorElement={<NoPageFound />}
            />
            <Route path="*" element={<NoPageFound />} />
        </>
    )
);
