import { useNavigate } from "react-router";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import GetPrx from "../../hooks/fetch-hooks/get-prx-data/get-prx";
import { DRXTable } from "./components";
import { HorizontalTable } from "./components/drx-table/Horiztonal";
import { VerticalDRX } from "./components/drx-table/Vertical";
import { getDRXTableDataCategorically } from "../../utils/generateRows";
import { addDaystoStartDate, getStartDateEndDate } from "../../utils/getStartDateEndDate";
function DrxPage () {
    const { orderId } = useParams();
    const location = useNavigate();
    const { data: prescription } = GetPrx(orderId);
    const [prescriptionData, setPrescriptionData] = useState(null);
    const [splitNumber, setSplitNumber] = useState(1);
    const [downloadAllSmallDrx, setdownloadAllSmallDrx] = useState(null);
    const [downloadAllBigDrx, setdownloadAllBigDrx] = useState(null);

    // eslint-disable-next-line
    const [searchParams] = useSearchParams();
    const params = [];

    function flipDownloadSmallDrx () {
        if (downloadAllSmallDrx === null) setdownloadAllSmallDrx(true);
        else setdownloadAllSmallDrx((prevState) => !prevState);
    }

    function flipDownloadBigDrx () {
        if (downloadAllBigDrx === null) setdownloadAllBigDrx(true);
        else setdownloadAllBigDrx((prevState) => !prevState);
    }

    for (let entry of searchParams.entries()) {
        params.push(entry);
    }
    const query = params.length > 0

    const daysDifference = (startDate, endDate) => {
        let StartDate = new Date(startDate);
        let EndDate = new Date(endDate);

        let difference_time = EndDate.getTime() - StartDate.getTime();
        let difference_days = difference_time / (1000 * 3600 * 24);

        return difference_days + 1;
    };
    // +++++++++++++++++++++++++++++
    const generateSplit = (prescription) => {
        let dates = [];
        let StartDate = prescription?.startDate;
        let EndDate = prescription?.endDate;
        let duration = prescription?.noOfDays;
        let splitDuration = duration / splitNumber;

        for (let i = 0; i < splitNumber; i++) {
            if (i === splitNumber - 1) {
                dates.push({
                    StartDate: getStartDateEndDate(StartDate),
                    EndDate: getStartDateEndDate(EndDate),
                    Duration: daysDifference(
                        StartDate,
                        getStartDateEndDate(addDaystoStartDate(EndDate, 1))
                    ),
                });
            } else {
                dates.push({
                    StartDate: getStartDateEndDate(StartDate),
                    EndDate: getStartDateEndDate(
                        addDaystoStartDate(StartDate, splitDuration)
                    ),
                    Duration: daysDifference(
                        StartDate,
                        addDaystoStartDate(StartDate, splitDuration) + 1
                    ),
                });
            }

            StartDate = getStartDateEndDate(
                addDaystoStartDate(StartDate, splitDuration + 1)
            );
        }
        return dates;
    };



    function getData (data) {
        if (!prescription) {
            location(-1, { replace: true })
        }
        return getDRXTableDataCategorically(data)
    }

    React.useEffect(() => {
        if (prescription?.data) {
            let prescriptionValues = getData(prescription?.data?.data.prescription?.prescriptionItems);
            setPrescriptionData({
                count: prescriptionValues?.rows?.length,
                rows: prescription?.data?.data.prescription?.prescriptionItems,
                startDate: prescription?.data?.data.prescription?.prescriptionItems[0]?.startDate,
                endDate: prescription?.data?.data.prescription?.prescriptionItems[0]?.endDate,
                drName: prescription?.data?.data.prescription?.drName,
                patientName: prescription?.data?.data.mapUserPatient?.Patient?.name,
                prescriptionValues
            });

            // if split is greater than 1 then split duration of prescription
            if (splitNumber > 1) {
                const days = prescription?.data?.data.data?.days;
                const splitDays = Math.ceil(days / splitNumber);
                const newEndDate = new Date(prescription?.data?.data.data?.startDate);
                newEndDate.setDate(newEndDate.getDate() + splitDays);
                setPrescriptionData({
                    ...prescriptionData,
                    endDate: newEndDate,
                });
            }
        }
    }, [prescription]);

    return (
        <div className="max-h-[85vh] overflow-y-auto overflow-x-hidden">
            <h2
                className="mb-2 cursor-pointer font-bold text-xl mt-3 p-2 text-[#28a48b]"
                onClick={() => {
                    location(-1);
                }}>
                {"<"} Back
            </h2>
            <div className="flex items-center gap-4  ml-5 mt-8">
                <h6 className="font-sans">Enter Split Count:</h6>
                <input
                    type="number"
                    className="border border-solid border-gray-400 rounded-2xl px-4 py-2 text-left w-20"
                    placeholder="Enter Split Number...."
                    onChange={(e) => { setSplitNumber(e.target.value); }}
                    value={splitNumber}
                />
                <button
                    onClick={() => { setdownloadAllBigDrx(true) }}
                    className="mt-6 mb-6 p-2 bg-[#28A48B] text-white font-semibold px-6 rounded-2xl"
                    style={{ color: "white", display: splitNumber > 0 ? 'block' : 'none' }}>
                    Download All Big DRX
                </button>
                <button
                    onClick={() => { setdownloadAllSmallDrx(true) }}
                    className="mt-6 mb-6 p-2 bg-[#28A48B] text-white font-semibold px-6 rounded-2xl"
                    style={{ color: "white", display: splitNumber > 0 ? 'block' : 'none' }}>
                    Download All Small DRX
                </button>
            </div>

            {!prescription?.data && prescription?.data?.length < 1 && (location(-1), { replace: true })}

            {/* Main DRXS+++++++++++++ */}
            {prescriptionData?.count >= 0 && (<>
                {generateSplit(prescription?.data?.data).map((date, i) => (
                    <DRXTable {...prescriptionData} getDates={date} key={i} index={i} wbn={query} downloadAllBigDrx={downloadAllBigDrx} flipDownloadBigDrx={flipDownloadBigDrx} />
                ))}
            </>
            )}
            {prescriptionData?.count <= 7 && (<>
                {generateSplit(prescription?.data?.data).map((date, i) => (
                    <HorizontalTable {...prescriptionData} getDates={date} key={i} index={i} wbn={query} downloadAllSmallDrx={downloadAllSmallDrx} flipDownloadSmallDrx={flipDownloadSmallDrx} />
                ))}
            </>
            )}
            {prescriptionData?.count > 7 && (<>
                {generateSplit(prescription?.data?.data).map((date, i) => (
                    <VerticalDRX {...prescriptionData} getDates={date} key={i} index={i} wbn={query} downloadAllSmallDrx={downloadAllSmallDrx} flipDownloadSmallDrx={flipDownloadSmallDrx} />
                ))}
            </>
            )}
        </div>
    );
}

export { DrxPage };

