/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Header from './components/Header'
import MainFields from './components/MainFields'
import Footer from './components/Footer'
import { GetOrderDetailsByOrderId } from '../../hooks/fetch-hooks/order-details.js/get-order-details-by-id'
import { useEffect } from 'react'
import moment from 'moment/moment'
import PrescriptionItems from './prescription-items'
import { useParams, useSearchParams } from 'react-router-dom';
import { UpdateOrder } from '../../hooks/update-hooks/edit-order/order-data-update'
import { UpdatePrescriptionItem } from '../../hooks/update-hooks/prescription-item/update-prescription-item'
import { AddPrescriptionItem } from '../../hooks/update-hooks/prescription-item/add-prescription-item'
import { DeletePrescriptionItem } from '../../hooks/update-hooks/prescription-item/delete-prescription-item'
import { UpdatePrescription } from '../../hooks/update-hooks/prescription/update-prescription'
import { isNil } from 'lodash'

export default function EditOrder() {
    const [searchParams] = useSearchParams();
    const orderType = searchParams.get('orderType')
    const { orderId } = useParams()
    const { data, isFetched, refetch } = GetOrderDetailsByOrderId(orderId);
    // eslint-disable-next-line no-unused-vars
    const [orderData, setOrderData] = useState(null)
    const [userData, setuserData] = useState(null);

    useEffect(() => {
        let resOrderData = data?.data?.data;
        let prescriptionItems = {}
        resOrderData?.prescription?.prescriptionItems.map((item) => {
            let initialAPiCallPayLoadData = {};
            item.isValidStartdate = true;
            item.isValidEndDate = true;
            item.isValidDuration = true;
            if (!item.startDate) {
                initialAPiCallPayLoadData['startDate'] = moment(resOrderData?.startDate).format('YYYY-MM-DD');
            }
            if (!item.endDate) {
                initialAPiCallPayLoadData['endDate'] = moment(resOrderData?.endDate).format('YYYY-MM-DD');
            }
            if (!item.duration) {
                initialAPiCallPayLoadData['duration'] = resOrderData?.noOfDays
            }
            if (!item.doseSize) {
                initialAPiCallPayLoadData['doseSize'] = 1;
            }
            if (!item.typeOfAdmin) {
                initialAPiCallPayLoadData['typeOfAdmin'] = 'DAYS'
            }
            if (!item.meal) {
                initialAPiCallPayLoadData['meal'] = []
            }
            if (!item.time) {
                initialAPiCallPayLoadData['time'] = []
            }
            initialDataUpdate(item.id, initialAPiCallPayLoadData)
            prescriptionItems[item.id] = item;
        })

        if (data) {
            setOrderData({
                prescriptionItems: prescriptionItems,
                startDate: moment(resOrderData?.startDate).format('YYYY-MM-DD'),
                endDate: moment(resOrderData?.endDate).format('YYYY-MM-DD'),
                noOfDays: resOrderData?.noOfDays,
                drName: resOrderData?.prescription?.drName,
                date: resOrderData?.prescription?.date,
                messages: resOrderData?.messages || [],
                status: resOrderData?.status,
                prescriptionId: parseInt(resOrderData?.prescriptionId),
                notes: resOrderData?.notes,
                userTimings: resOrderData?.mapUserPatient?.Patient?.userDetail?.timings
            })
            setuserData({
                User: resOrderData?.mapUserPatient.User,
                Patient: resOrderData?.mapUserPatient.Patient,
                prescription: resOrderData?.prescription,
                evitalRx: resOrderData?.inventoryData?.print_url || "",
                address: resOrderData?.address,
                mappingId: resOrderData?.mapUserPatient.id,
            })
        }

    }, [data])

    if (!isFetched) {
        return (
            <></>
        )
    }

    function initialDataUpdate(id, payLoadData) {
        if (Object.keys(payLoadData).length > 0) {
            UpdatePrescriptionItem(id, payLoadData).then((response) => {
            }).catch((err) => { console.log(err) })
            refetch()
        }
    }
    /**
     * Scroll a prescriptionItem into view
     * @param {*} id 
     */
    const scrollToPrescriptionItemById = (id) => {
        setTimeout(() => {
            const section = document.querySelector(`#prescriptionItem-${id}`);
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200)
    }

    /**
     * Handles the change of start date or the duration for the order being edited 
     * @param {*} newStartDate 
     * @param {*} newDuration 
     */
    function handleOrderStartDateOrDurationChange(newOrderStartDate = null, newOrderNoOfDays = null, orderData) {
        if (newOrderStartDate === null) newOrderStartDate = moment(orderData.startDate, 'YYYY-MM-DD');
        if (newOrderNoOfDays === null) newOrderNoOfDays = moment(newOrderStartDate).add(orderData.noOfDays - 1, 'days').diff(moment(newOrderStartDate), 'days') + 1;

        let prevOrderStartDate = moment(orderData?.startDate, 'YYYY-MM-DD');
        let prevOrderNoOfDays = parseInt(orderData?.noOfDays);

        let prevPrescriptionItems = JSON.parse(JSON.stringify(orderData?.prescriptionItems));

        for (let id in prevPrescriptionItems) {
            let prescriptionItem = prevPrescriptionItems[id];

            let prevRxItemStartDate = moment(prescriptionItem?.startDate, 'YYYY-MM-DD');
            let prevRxItemDuration = parseInt(prescriptionItem?.duration);

            if (prevRxItemStartDate.isSame(prevOrderStartDate) && prevRxItemDuration === prevOrderNoOfDays) {
                updateItem(id,
                    {
                        startDate: newOrderStartDate.format('YYYY-MM-DD'),
                        duration: parseInt(newOrderNoOfDays)
                    }
                );
            }
        }
        return { startDate: newOrderStartDate.format('YYYY-MM-DD'), noOfDays: newOrderNoOfDays, endDate: moment(newOrderStartDate).add(newOrderNoOfDays - 1, 'days').format('YYYY-MM-DD') };
    }

    /**
     * Get Initial Values for the new prescriptionItem
     * @param {*} itemId 
     * @param {*} itemBatchId 
     * @returns 
     */
    function getInitialValuesForNewPrescriptionItem(itemId, itemBatchId) {
        let initialValues = {
            orderId: parseInt(orderId),
            prescriptionId: parseInt(orderData?.prescriptionId),
            itemId: parseInt(itemId),
            itemBatchId: parseInt(itemBatchId) || null,
            time: [],
            meal: [],
            typeOfAdmin: "DAYS",
            sunday: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            doseSize: 1,
            startDate: orderData?.startDate,
            duration: orderData?.noOfDays,
            endDate: orderData?.endDate
        }

        return initialValues;
    }

    /**
     * 
     * @param  links updates links 
     */
    function updatePrescription(links) {
        setuserData((prevState) => {
            let newPrescription = { ...prevState.prescription };
            newPrescription.links = links
            return { ...prevState, prescription: newPrescription }
        });
    }


    /**
     * Handle today item click if the item is starting today then it will start from tomorrow
     * @param {*} id 
     */
    function handleTodayItem(id) {
        let item = orderData?.prescriptionItems[id];
        if (item.startDate === moment().format('YYYY-MM-DD') && item.duration > 1) {
            updateItem(id, { startDate: moment().add(1, 'days').format('YYYY-MM-DD'), duration: item.duration - 1 })
        }
    }

    /**
         * Updates The OrderData state which have prescription item order startDate endDate noOfDays drName prscriptionDate
         *
         * @param value Value to be changed
         * @param name name of the key to be changed
     */
    function updateOrderData(value, name) {
        let newOrderData = { ...orderData };
        let payLoadData = {};
        payLoadData[name] = value
        if (name === 'noOfDays' || name === 'startDate') {
            let newData = handleOrderStartDateOrDurationChange(name === 'startDate' ? moment(value, 'YYYY-MM-DD') : null, name === 'noOfDays' ? parseInt(value) : null, orderData);
            payLoadData = newData;
            newOrderData = { ...newOrderData, ...newData }
        } else {
            newOrderData[name] = value;
            payLoadData[name] = value;
        }
        setOrderData(newOrderData);
        if (name == 'drName' || name == 'date') {
            UpdatePrescription(userData.prescription.id, payLoadData)
        }
        else {
            UpdateOrder(payLoadData, orderId)
        }
    }


    /**
          * Updates a specific prescriptionItem 
          *
          * @param id Id of the prescriptionItem to be changed
          * @param value Value to be changed
          * @param keyname name of the key to be changed
      */
    function updateItem(id, data) {
        let prevItem = JSON.parse(JSON.stringify(orderData.prescriptionItems[id]));
        let newItem = JSON.parse(JSON.stringify(orderData.prescriptionItems[id]));
        let payLoadData = {};

        for (let keyname in data) {
            let value = data[keyname];

            if (keyname == 'item') {
                payLoadData['itemId'] = parseInt(value?.id)
            }
            else if (keyname == 'itemBatch') {
                payLoadData['itemBatchId'] = parseInt(value?.id)
            }
            else {
                payLoadData[keyname] = value;
            }
            if (keyname === 'startDate') {
                newItem['startDate'] = moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
                let endDate = moment(newItem['startDate']).add(newItem['duration'] - 1, 'days').format('YYYY-MM-DD')
                newItem['endDate'] = endDate;
                payLoadData['startDate'] = moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
                payLoadData['endDate'] = endDate;
            }
            if (keyname === 'duration') {
                let endDate = moment(newItem['startDate']).add(value - 1, 'days').format('YYYY-MM-DD')
                newItem['endDate'] = endDate;
                payLoadData['endDate'] = endDate;
                payLoadData['duration'] = value;
            }
            if (keyname === 'typeOfAdmin' && value === 'DATE') {
                newItem['endDate'] = moment(newItem['startDate']).format('YYYY-MM-DD');
                newItem['duration'] = 1;
                payLoadData['endDate'] = moment(newItem['startDate']).format('YYYY-MM-DD');
                payLoadData['duration'] = 1;
            }
            newItem[keyname] = value;
        }

        setOrderData((prevState) => {
            let prescriptionItems = prevState.prescriptionItems;
            prescriptionItems[id] = newItem;
            return { ...prevState, prescriptionItems: prescriptionItems }
        });
        UpdatePrescriptionItem(id, payLoadData).then((response) => {
            if (!response?.data?.success) {
                setOrderData((prevState) => {
                    let prescriptionItems = prevState.prescriptionItems;
                    prescriptionItems[id] = prevItem;
                    return { ...prevState, prescriptionItems: prescriptionItems }
                });
            }
        });
    }

    /**
        * Add a new prescription Item either duplicate or duplicate with startdate as today
        *
        * @param id Id of the prescriptionItem from which new Item has to be created
        * @param type Either duplicate or today
    */
    function addItem(id, type) {
        let newItem = { ...orderData.prescriptionItems[id] };
        if (type == 'today') {
            newItem.startDate = moment().format();
            newItem.duration = 1;
            newItem.endDate = moment().format();
            handleTodayItem(id);
        }

        for (let key in newItem) {
            if (key.includes('Id')) newItem[key] = parseInt(newItem[key]);
            if (isNil(newItem[key])) delete newItem[key];
        }

        delete newItem.id;

        let now = moment().valueOf();

        setOrderData((prevState) => {
            let prescriptionItems = prevState.prescriptionItems;
            prescriptionItems[`${id}:${now}`] = newItem;
            return { ...prevState, prescriptionItems }
        })

        AddPrescriptionItem(newItem).then((response) => {
            if (!response?.data?.success) {
                setOrderData((prevState) => {
                    let prescriptionItems = prevState.prescriptionItems;
                    delete prescriptionItems[`${id}:${now}`];
                    return { ...prevState, prescriptionItems }
                })
            }
            setOrderData((prevState) => {
                let prescriptionItems = prevState.prescriptionItems;
                prescriptionItems[response?.data?.data?.id] = response?.data?.data;
                delete prescriptionItems[`${id}:${now}`];
                return { ...prevState, prescriptionItems }
            })
            scrollToPrescriptionItemById(response?.data?.data?.id);
        });
    }

    /**
         * Add a new fresh prescriptionItem with only name and batch info
         *
         * @param itemId Id of the item is to be added in the prescriptionItem
         * @param itemBatchId Id of the batch of item
    */
    function addNewItem(itemId, itemBatchId) {
        let payLoadData = getInitialValuesForNewPrescriptionItem(itemId, itemBatchId);
        let now = moment().valueOf();

        setOrderData((prevState) => {
            let prescriptionItems = prevState.prescriptionItems;
            prescriptionItems[`:${now}`] = payLoadData;
            return { ...prevState, prescriptionItems }
        });

        AddPrescriptionItem(payLoadData).then((response) => {
            if (!response?.data?.success) {
                setOrderData((prevState) => {
                    let prescriptionItems = prevState.prescriptionItems;
                    delete prescriptionItems[`:${now}`];
                    return { ...prevState, prescriptionItems }
                })
            }
            setOrderData((prevState) => {
                let prescriptionItems = prevState.prescriptionItems;
                prescriptionItems[response?.data?.data?.id] = response?.data?.data;
                delete prescriptionItems[`:${now}`];
                return { ...prevState, prescriptionItems: prescriptionItems }
            })
            scrollToPrescriptionItemById(response?.data?.data?.id);
        });
    }

    /**
        * Deletes a PrescriptionItem
        *
        * @param id id of the Prescription Item is to be deleted
        * @param itemBatchId Id of the batch of item
    */
    function deleteItem(id) {
        setOrderData((prevState) => {
            let prescriptionItems = prevState.prescriptionItems;
            delete prescriptionItems[id];
            return { ...prevState, prescriptionItems }
        })
        DeletePrescriptionItem(id);
    }

    return (
        <div className='py-3 px-5 flex flex-col gap-3 relative h-full overflow-y-scroll' >
            {(data?.data?.data && userData !== null) && <Header userData={userData} updatePrescription={updatePrescription} refetch={refetch} />}
            {orderData !== null && <MainFields orderType={orderType} status={orderData.status} messages={orderData?.messages} updateOrderData={updateOrderData} startDate={orderData.startDate} noOfDays={orderData.noOfDays} drName={orderData.drName} prescriptionDate={orderData.date} endDate={orderData.endDate} userNotes={orderData.notes} />}
            {orderData !== null && < PrescriptionItems order={orderData} addItem={addItem} deleteItem={deleteItem} updateItem={updateItem} Items={orderData.prescriptionItems} />}
            <Footer addNewItem={addNewItem} />
        </div>
    )
}
