/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import GetAddressByUserMappingId from '../hooks/fetch-hooks/get-address-by-userid/get-address-by-userid';
import { UpdateOrder } from '../hooks/update-hooks/edit-order/order-data-update';
import { toast } from 'react-toastify';

export default function SelectAddress({ user, orderId, closeModal, refetch, toggleCreateAddressForm }) {
    const { data: patientAddress, isFetched } = GetAddressByUserMappingId(user.mappingId);


    const [selectedAdd, setSelectedAdd] = useState(user.address.id)
    async function updateAddress() {
        if (!selectedAdd) {
            toast.warn('No address is selected!')
        }
        else {
            const res = await UpdateOrder({
                addressId: parseInt(selectedAdd),
                userMappingId: parseInt(user.mappingId),
            }, orderId);
            if (res && res.status === 200) {
                if (res.data.success) {
                    toast.success('Address Updated');
                    closeModal()
                    refetch()
                }
                else {
                    toast.error(res.data.error);
                    closeModal()
                }
            }
            else {
                toast.error('Server Error!')
                closeModal()
            }
        }


    }
    return (
        isFetched &&
        <div className='flex flex-col flex-auto gap-2 justify-center'>
            {(
                    <div className="border-white-500  rounded-2xl  flex-1">
                        <h6 className="font-sans text-xl my-2">Shipping Address</h6>
                        <select name="address" value={selectedAdd} onChange={(e) => { setSelectedAdd(e.target.value) }} required className=" w-[100%]">
                            {patientAddress.data.data.map((value, key) => {
                            return (
                                <>
                                    <option key={key} value={value.id} >{value.line}</option >
                                </>
                            )
                        })}
                    </select>
                </div>
            )
            }
                <div className='flex gap-5 flex-1'>
                    <button className='p-2 bg-elm-500 w-[50%] text-[white] rounded-xl flex-auto' onClick={() => { updateAddress() }}>Update</button>
                    <button className='p-2 bg-elm-500 w-[50%] text-[white] rounded-xl flex-auto' onClick={() => {
                        closeModal()
                        toggleCreateAddressForm()
                    }}>Add New Address</button>
                </div>


        </div >
    )
}
