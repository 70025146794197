const initialValues = {
    prescriptionItems: [{
        orderId: "",
        prescriptionId: "",
        itemId: "",
        itemBatchId: "",
        time: "",
        meal: "",
        typeOfAdmin: "",
        doseSize: "",
        startDate: "",
        endDate: "",
        duration: "",
        discount: "",
    }]
}

export { initialValues }