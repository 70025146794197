import React from "react";
import PropTypes from "prop-types";

function MedicinesRightBarContent({ item, itemBatch }) {
    return (
        <div>
            <h2 className="p-2 px-4 font-bold text-xl mt-2 rounded-2xl bg-slate-200">
                Medicine Details
            </h2>
            <div className="px-2">
                <div className="flex flex-col gap-1 mt-4">
                    <h6 className="font-sans font-bold text-sm leading-3">
                        Medicine Name
                    </h6>
                    <p className="font-sans ">{item?.genericName}</p>
                </div>
                <div className="flex flex-col gap-1 mt-2">
                    <h6 className="font-sans font-bold text-sm leading-3">
                        Batch Number
                    </h6>
                    {itemBatch?.batchNo}
                    <p className="font-sans "></p>
                </div>
                <div className="flex flex-col gap-1 mt-2">
                    <h6 className="font-sans font-bold text-sm leading-3">Expiry Date</h6>
                    <p className="font-sans ">
                        {new Date(itemBatch?.expireDate).toDateString()}
                    </p>
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex flex-col gap-1 mt-2">
                        <h6 className="font-sans font-bold text-sm leading-3">
                            Selling Price
                        </h6>
                        <p className="font-sans ">₹{itemBatch?.sellingPrice}</p>
                    </div>
                    <div className="flex flex-col gap-1 mt-2">
                        <h6 className="font-sans font-bold text-sm leading-3">Profit</h6>
                        <p className="font-sans text-green-500">
                            ₹{itemBatch?.sellingPrice - itemBatch?.price}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 mt-2 text-right">
                        <h6 className="font-sans font-bold text-sm leading-3">
                            Cost Price
                        </h6>
                        <p className="font-sans ">₹{itemBatch?.price}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-1 mt-2">
                    <h6 className="font-sans font-bold text-sm leading-3">
                        Medicine Type
                    </h6>
                    <p className="font-sans ">{item?.medicineType}</p>
                </div>
                <div className="flex flex-col gap-1 mt-2">
                    <h6 className="font-sans font-bold text-sm leading-3">
                        Mnemonic Name
                    </h6>
                    <p className="font-sans ">{item?.mnemonic}</p>
                </div>
            </div>
        </div>
    );
}

MedicinesRightBarContent.propTypes = {
    item: PropTypes.object,
    itemBatch: PropTypes.object,
};

export { MedicinesRightBarContent };
