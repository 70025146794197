import { request } from "../../../config/helpers/axios-instance";

const updateCallback = async (callback, id) =>
    
await request({
    url: `requestCallback/admin/` +id,
    method: "PUT",
    headers: {
        "Content-Type": "application/json",
    },
    data: callback

})
export { updateCallback };