import React, { useState } from "react";
import "../../App.scss";
import RightBar from "../../layouts/Dashboard/components/rightbar";
import { OrdersTable } from "../all-orders/components";
import AllOrderDetailsRightBar from "../all-orders/components/rightsidebar/rightsidebar_details";
import { OrderDetailsData } from "../../contexts";
import { DIGITIZE_ORDER_TYPE } from "../../utils/constants";

function DigitizeOrders() {
    const [data, setData] = useState();
    const [rightBarOpen, setRightBarOpen] = useState({
        name: "all-orders",
        open: false,
    });


    const changeRightBarOpen = () => {
        if (!rightBarOpen.open) {
            setRightBarOpen({
                ...rightBarOpen,
                open: true,
            });
        }
    };
    const changeRightBarClose = () => {
        setRightBarOpen({
            ...rightBarOpen,
            open: false,
        });
    };





    return (
        <div className="p-7 h-full overflow-y-scroll bg-gray-50">
            <RightBar
                rightBarOpenAction={changeRightBarOpen}
                rightBarCloseAction={changeRightBarClose}
                value={rightBarOpen.open}
                data={data}>
                <AllOrderDetailsRightBar {...data} />
            </RightBar>

            <OrderDetailsData.Provider value={{ data, setData, OrderType: DIGITIZE_ORDER_TYPE }}>
                <OrdersTable changeRightBarOpen={changeRightBarOpen} />
            </OrderDetailsData.Provider>
        </div >
    );
}

export { DigitizeOrders };

