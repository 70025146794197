import React from 'react';
import Modal from './Modal';
import PropTypes from "prop-types";
// import { createContext, useContext, useState } from 'react';


const AddressModalContext = React.createContext({
    Component: undefined,
    AddopenModal: () => null,
    AddcloseModal: () => null,
});

export const AddressModalProvider = ({ children }) => {
    const [ModalComponent, setModalComponent] = React.useState({
        Component: undefined,
    });
    return (
        <AddressModalContext.Provider
            value={{
                Component: ModalComponent.Component,
                AddopenModal: (Component) => setModalComponent({ Component }),
                AddcloseModal: () => setModalComponent({ Component: undefined }),
            }}
        >
            <>
                <Modal />
                {children}
            </>
        </AddressModalContext.Provider>
    );
};

export const useAddModalMain = () => React.useContext(AddressModalContext);

AddressModalProvider.propTypes = {
    children: PropTypes.any
}