import moment from "moment";
export const getStartDateEndDate = (startDate) => {
    return moment(startDate).format('DD MMM');
};

export const getStartDateEndDateWithYear = (startDate) => {
    const days = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    let data = "";

    const date = new Date(startDate);
    data =
        data +
        date.getDate().toString() +
        " " +
        days[date.getMonth()] +
        " " +
        date.getFullYear();

    return data;
};

export const addDaystoStartDate = (startDate, days = 0) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + parseInt(days) - 1);

    return date;
};

export const getDaysLeft = (date) => {
    const today = new Date();
    const date2 = new Date(date);

    const diffTime = (date2 - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
};

export const getDaysLeftNoAbs = (date) => {
    const today = new Date();
    const date2 = new Date(date);

    const diffTime = (date2 - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
};

export const getStyles = (date) => {
    const today = new Date();
    const date2 = new Date(date);

    const diffTime = (date2 - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < -5) {
        return {
            display: "none"
        }
    }
    else if (diffDays <= 0 && diffDays >= -5) {
        return { background: "black", color: "white" }
    }
    else if (diffDays < 6) {
        return { background: "red", color: "white" }
    }
    else {
        return { background: "#ececec", color: "black" }
    }
}