import React from 'react';
import Modal from './MainModal';
import PropTypes from "prop-types";

const ModalContext = React.createContext({
    Component: undefined,
    openModal: () => null,
    closeModal: () => null,
    modalTitle: undefined,
    formValues: undefined,
    isEdit: undefined,
    refetchUsers: undefined
});

export const ModalProvider = ({ children }) => {
    const [ModalComponent, setModalComponent] = React.useState({
        Component: undefined,
        title: undefined,
        formValues: undefined,
        isEdit: undefined,
        refetchUsers: undefined
    });
    return (
        <ModalContext.Provider
            value={{
                Component: ModalComponent.Component,
                modalTitle: ModalComponent.title,
                formValues: ModalComponent.formValues,
                isEdit: ModalComponent.isEdit,
                refetchUsers: ModalComponent.refetchUsers,
                openModal: (Component, title, formValues, isEdit, refetch) => { setModalComponent({ Component, title, formValues, isEdit, refetchUsers: refetch }) },
                closeModal: () => setModalComponent({ Component: undefined }),
            }}
        >
            <>
                <Modal />
                {children}
            </>
        </ModalContext.Provider>
    );
};

export const useModalMain = () => React.useContext(ModalContext);

ModalProvider.propTypes = {
    children: PropTypes.any
}