import { request } from "../../config/helpers/axios-instance"

export const generateDeliveryQrCode = async (orderId) => {
    let response = await request({
        url: 'userOrder/admin/generateDeliveryQRCodeURL',
        method: 'POST',
        data: {
            "orderId": orderId
        }
    })
    if (response?.data?.success) {
        return response?.data?.data
    }
    else {
        return null
    }
}