import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";

export const TextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div>
            <h6 className="font-sans text-sm font-medium mb-2">{label}</h6>
            <input
                {...field}
                {...props}
                className="border-white-z500 border-2 w-full p-2 rounded-2xl focus:outline-none active:outline-none font-sans"
            />

            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};

TextField.propTypes = {
    label: PropTypes.any,
};
