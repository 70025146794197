import moment from 'moment';

import { isNil } from 'lodash';

import { QNT_INDEX, ALLOWED_TYPES, ALLOWED_MEALS } from './ocs-constants';

const Error = {
    INVALID_ORDER_START_DATE: 'Order start date should be less than or equal to order end date',
    INVALID_ORDER_NO_OF_DAYS: 'Order no of days should be greater than 0',
    INVALID_ORDER_NO_OF_DAYS_DIFF: 'Order no of days should be equal to difference between order start date and order end date',
    INVALID_PRESCRIPTIONITEM_ITEM_ID: 'Please select item for prescription item id: {id}',
    INVALID_PRESCRIPTIONITEM_DOSE_SIZE_ZERO: 'Please enter dose size for prescription item id: {id}',
    INVALID_PRESCRIPTIONITEM_DOSE_SIZE: `Please enter valid dose size for prescription item with id: {id} it can be one of {allowedDoseSizes}`,
    PRESCRIPTIONITEM_DATES_NOT_WITHIN_ORDER_DATES: `Prescription item with id: {id} should be within {orderStartDate} and {orderEndDate}`,
    INVALID_PRESCRIPTIONITEM_DURATION: `Prescription item with id: {id} should have duration greater than 0`,
    PRESCRIPTIONITEM_DURATION_MISMATCH: `Prescription item with id: {id} should have duration of {duration}`,
    INVALID_PRESCRIPTIONITEM_TYPE_OF_ADMIN: `Please enter valid type of admin for prescription item with id: {id} it can be one of {allowedTypes}`,
    INVALID_PRESCRIPTIONITEM_MEAL: `Please enter valid meal for prescription item with id: {id} it can be one of {allowedMeals}`,
    INVALID_PRESCRIPTIONITEM_TIME: `Please enter valid time for prescription item with id: {id} it should be in HH:mm format`,
    DUPLICATE_PRESCRIPTION_TIME: `The time {time} is added multiple time for the same medicine`,
    INVALID_PRESCRIPTIONITEM_TIME_AND_MEAL_LENGTH: `Please enter valid time and meal for prescription item with id: {id} it should be in HH:mm format`,
    INVALID_PRESCRIPTIONITEM_DAY: `Please enter valid day for prescription item with id: {id}`,
    INVALID_PRESCRIPTIONITEM_DAY_ALL_FALSE: `Please select atleast one day for prescription item with id: {id}`,
    INVALID_PRESCRIPTIONITEM_TYPE_DATE_WRONG_DATES: `For type date, both dates should be the same for prescription item with id: {id}`,
}

const DAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
];

export const validateOrderAndPrescriptionItems = (order, prescriptionItems) => {
    order = JSON.parse(JSON.stringify(order));
    prescriptionItems = JSON.parse(JSON.stringify(prescriptionItems));
    let result = {
        success: true,
        messages: []
    };

    let orderStartDate = moment(moment(order.startDate).format('YYYY-MM-DD'), 'YYYY-MM-DD');
    let orderEndDate = moment(moment(order.endDate).format('YYYY-MM-DD'), 'YYYY-MM-DD');
    let orderNoOfDays = parseInt(order.noOfDays);

    if (orderStartDate.isAfter(orderEndDate)) {
        result.messages[0] = {};
        result.success = false;
        result.messages[0].text = Error.INVALID_ORDER_START_DATE;
        return result;
    }

    if (orderNoOfDays <= 0) {
        result.messages[0] = {};
        result.success = false;
        result.messages[0].text = Error.INVALID_ORDER_NO_OF_DAYS;
        return result;
    }

    if (orderNoOfDays != orderEndDate.diff(orderStartDate, 'days') + 1) {
        result.messages[0] = {};
        result.success = false;
        result.messages[0].text = Error.INVALID_ORDER_NO_OF_DAYS_DIFF;
        return result;
    }

    if (prescriptionItems.length === 0) {
        result.messages[0] = {};
        result.success = false;
        result.messages[0].text = 'Please add atleast one prescription item';
        return result;
    }

    for (let key in prescriptionItems) {
        if (key.includes(':')) continue;
        let prescriptionItem = JSON.parse(JSON.stringify(prescriptionItems[key]))

        let startDate = moment(moment(prescriptionItem.startDate).format('YYYY-MM-DD'), 'YYYY-MM-DD');
        let endDate = moment(moment(prescriptionItem.endDate).format('YYYY-MM-DD'), 'YYYY-MM-DD');
        let duration = parseInt(prescriptionItem.duration);
        // Check if the prescription item id is empty
        if (isNil(prescriptionItem.itemId)) {
            result.messages.push({
                text: Error.INVALID_PRESCRIPTIONITEM_ITEM_ID.replace('{id}', prescriptionItem.id),
                data: {
                    id: prescriptionItem.id
                }
            });
            continue;
        }

        // Check if the prescription item quantity is empty
        if (isNil(prescriptionItem.doseSize) || prescriptionItem.doseSize === 0) {
            result.messages.push({
                text: Error.INVALID_PRESCRIPTIONITEM_DOSE_SIZE.replace('{id}', prescriptionItem.id),
                data: {
                    id: prescriptionItem.id
                }
            });
            continue;
        }

        if (prescriptionItem.time?.length === 0 || prescriptionItem.meal?.length === 0) {
            result.messages.push({
                text: Error.INVALID_PRESCRIPTIONITEM_TIME_AND_MEAL_LENGTH.replace('{id}', prescriptionItem.id),
                data: {
                    id: prescriptionItem.id
                }
            });
            continue;
        }

        if (prescriptionItem.time?.length != prescriptionItem?.meal.length) {
            result.messages.push({
                text: Error.INVALID_PRESCRIPTIONITEM_TIME_AND_MEAL_LENGTH.replace('{id}', prescriptionItem.id),
                data: {
                    id: prescriptionItem.id
                }
            });
            continue;
        }

        let isTimeValid = true;
        for (let t of prescriptionItem.time) {
            if (!moment(t, 'H:mm', true).isValid()) {
                result.messages.push({
                    text: Error.INVALID_PRESCRIPTIONITEM_TIME.replace('{id}', prescriptionItem.id),
                    data: {
                        id: prescriptionItem.id,
                    }
                });
                isTimeValid = false;
                break;
            }
        }
        if (!isTimeValid) continue;

        // for checking if duplicate time exists for the same medicine
        for (let index = 0; index < prescriptionItem.time.length; index++) {
            let time = prescriptionItem.time[index];
            if (prescriptionItem.time.indexOf(time) !== index) {
                result.messages.push({
                    text: Error.DUPLICATE_PRESCRIPTION_TIME.replace('{time}', time),
                    data: {
                        id: prescriptionItem.id,
                    }
                });
                break;
            }
        }

        let isMealValid = true;
        for (let meal of prescriptionItem.meal) {
            if (!ALLOWED_MEALS.includes(meal)) {
                result.messages.push({
                    text: Error.INVALID_PRESCRIPTIONITEM_MEAL.replace('{id}', prescriptionItem.id).replace('{allowedMeals}', ALLOWED_MEALS.join(', ')),
                    data: {
                        id: prescriptionItem.id,
                    }
                });
                isMealValid = false;
                break;
            }
        }
        if (!isMealValid) continue;



        // If the prescription item is not in the allowed type
        if (!ALLOWED_TYPES.includes(prescriptionItem.typeOfAdmin)) {
            result.messages.push({
                text: Error.INVALID_PRESCRIPTIONITEM_TYPE_OF_ADMIN.replace('{id}', prescriptionItem.id).replace('{allowedTypes}', ALLOWED_TYPES.join(', ')),
                data: {
                    id: prescriptionItem.id
                }
            });
            continue;
        }

        // If the prescription item is not in the allowed dose sizes
        if (!Object.keys(QNT_INDEX).includes(prescriptionItem.doseSize.toString())) {
            result.messages.push({
                text: Error.INVALID_PRESCRIPTIONITEM_DOSE_SIZE.replace('{id}', prescriptionItem.id).replace('{allowedDoseSizes}', Object.keys(QNT_INDEX).join(', ')),
                data: {
                    id: prescriptionItem.id
                }
            });
            continue;
        }

        if (parseInt(duration) < 0) {
            result.messages.push({
                text: Error.INVALID_PRESCRIPTIONITEM_DURATION.replace('{id}', prescriptionItem.id),
                data: {
                    id: prescriptionItem.id
                }
            });
            continue;
        }

        if (parseInt(duration) != (endDate.diff(startDate, 'days') + 1)) {
            result.messages.push({
                text: Error.PRESCRIPTIONITEM_DURATION_MISMATCH.replace('{id}', prescriptionItem.id).replace('{duration}', endDate.diff(startDate, 'days')),
                data: {
                    id: prescriptionItem.id
                }
            })
            continue;
        }

        if (startDate < orderStartDate || endDate > orderEndDate) {
            result.messages.push({
                text: Error.PRESCRIPTIONITEM_DATES_NOT_WITHIN_ORDER_DATES.replace('{id}', prescriptionItem.id).replace('{orderStartDate}', orderStartDate.format('Do MMM YYYY')).replace('{orderEndDate}', orderEndDate.format('Do MMM YYYY')),
                data: {
                    id: prescriptionItem.id
                }
            })
            continue;
        }

        let isTypeDayValid = true;
        if (prescriptionItem.typeOfAdmin === 'DAYS') {
            let allFalse = true;
            for (let day of DAYS) {

                if (isNil(prescriptionItem[day])) {
                    result.messages.push({
                        text: Error.INVALID_PRESCRIPTIONITEM_DAY.replace('{id}', prescriptionItem.id),
                        data: {
                            id: prescriptionItem.id,
                        }
                    });
                    isTypeDayValid = false;
                    break;
                }
                if (allFalse === true && prescriptionItem[day]) allFalse = false;
            }
            if (allFalse) {
                result.messages.push({
                    text: Error.INVALID_PRESCRIPTIONITEM_DAY_ALL_FALSE.replace('{id}', prescriptionItem.id),
                    data: {
                        id: prescriptionItem.id,
                    }
                });
                isTypeDayValid = false;
            }
        }
        if (!isTypeDayValid) continue;

        let isTypeDateValid = true;
        if (prescriptionItem.typeOfAdmin === 'DATE') {
            if (startDate.diff(endDate, 'days') !== 0) {
                result.messages.push({
                    text: Error.INVALID_PRESCRIPTIONITEM_TYPE_DATE_WRONG_DATES.replace('{id}', prescriptionItem.id),
                    data: {
                        id: prescriptionItem.id,
                    }
                });
                isTypeDateValid = false;
            }
        }
        if (!isTypeDateValid) continue;
    }

    if (result.messages.length > 0) result.success = false;

    return result;
};
