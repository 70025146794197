import React from "react";

function QualifiedLead () {
    return (<>
        <div>
            Hello
        </div>
    </>)
}

export default QualifiedLead;
