import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState } from "react";
import GetPrx from "../../hooks/fetch-hooks/get-prx-data/get-prx";
import { PRXTable } from "./components";
import { useNavigate, useParams } from "react-router";

function PrxPage () {
    const { orderId } = useParams()
    const location = useNavigate();
    const { data: prescription, isLoading } = GetPrx(orderId);
    const divToDisplay = document.getElementById("prx-table");
    const [prescriptionData, setPrescriptionData] = useState(null);
    React.useEffect(() => {
        if (prescription?.data?.success) {
            setPrescriptionData({
                count: prescription?.data?.data?.count,
                rows: prescription?.data?.data?.data?.items,
                startDate: prescription?.data?.data?.data?.startDate,
                endDate: prescription?.data?.data?.data?.endDate,
                drName: prescription?.data?.data?.data?.drName,
                patientName: prescription?.data?.data?.data?.patientName,
                total: prescription?.data?.data?.data?.total,
            });
        }
    }, [prescription]);

    function getPDF () {
        html2canvas(divToDisplay, {
            dpi: 444,
            scale: 4,
        }).then(function (canvas) {
            var imgWidth = 210;
            var pageHeight = 290;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF("p", "mm");
            var position = 0;
            var pageData = canvas.toDataURL("image/jpeg", 2.0);
            var imgData = encodeURIComponent(pageData);
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            doc.setLineWidth(5);
            doc.setDrawColor(255, 255, 255);
            doc.rect(0, 0, 210, 295);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                doc.setLineWidth(5);
                doc.setDrawColor(255, 255, 255);
                doc.rect(0, 0, 210, 295);
                heightLeft -= pageHeight;
            }
            doc.save("file.pdf");
        });
    }
    return (
        <>
            <div className="max-h-[85vh] overflow-y-auto overflow-x-hidden">
                <button
                    onClick={getPDF}
                    className="bg-blue-500 mx-8 mb-[-10px] mt-6 text-white-50 px-4 py-2 rounded-2xl font-bold text-lg">
                    Download
                </button>
                {!prescription?.data?.success && !isLoading && (
                    location(-1)
                )}
                {prescriptionData && <PRXTable {...prescriptionData} getPDF={getPDF} />}
            </div>
        </>
    );
}

export { PrxPage };
