import React from 'react'
import QRCode from 'react-qr-code'
import PropTypes from "prop-types";


export default function DeliveryQr({ data }) {
    return (
        < QRCode
            bgColor='transparent'
            size={100}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={data}
            viewBox={`0 0 256 256`}
        />
    )
}
DeliveryQr.propTypes = {
    data: PropTypes.any
};