import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";

const GetAddressByUserMappingId = (userMappingId) => {
    return useQuery(
        ["get-address-by-user-mapping-id", userMappingId],
        () =>
            request({
                url: `/address/admin/mapUserPatient/${userMappingId}`,
                method: "GET",

            }),
        {
            enabled: !!userMappingId,
        }
    );
};

export default GetAddressByUserMappingId;
