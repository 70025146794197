/* eslint-disable react/prop-types */
import React, { useContext, useRef } from "react";
import { updateCallback } from "../../../hooks/update-hooks/update-callbacks/update-callback";
import { CallBackContext } from "../../../contexts";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import moment from "moment/moment";

function NextCallbackModal({ callback }) {
    const { refetch } = useContext(CallBackContext)
    const NextCallBackRef = useRef();
    const updateNextCallback = (value) => {
        try {
            updateCallback({ 'nextCallback': value }, callback.id)
        }
        catch (error) {
            toast.error(error)
        }


    }
    function isValidDate(value) {
        let currentDate = moment();
        let nextDate = moment(value);
        let minutesDiff = nextDate.diff(currentDate, 'minutes')
        if (minutesDiff <= 0) {
            toast.error('Not a valid time. Choose date time greater than current');
        }
        else {
            debounceOnChange(value)
            refetch()
        }
    }
    const debounceOnChange = React.useCallback(debounce(updateNextCallback, 400), []);
    return (
        <input style={{ marginTop: 0 }}
            ref={NextCallBackRef}
            id="Name"
            className=" bg-white-50 rounded-md font-medium w-[180px] h-10"
            placeholder="Next Callback"
            type={'datetime-local'}
            required={true}
            defaultValue={moment(callback.nextCallback).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) => { isValidDate(e.target.value) }}
        >
        </input>
    )
}

export { NextCallbackModal };