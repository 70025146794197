import * as moment from "moment";

const getFormattedDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const getFormattedTime = (date) => {
  return moment(date).format("hh:mm A");
};

const getFormattedDateTime = (date) => {
  return moment(date).format("DD MMM YYYY hh:mm A");
};

const getFormattedDateFromNow = (date) => {
  return moment(date).fromNow();
};

const addDaysToDate = (date, days) => {
  return moment(date).add(days, "days").format("YYYY-MM-DD");
};

const convertDMYToDateString = (date) => {
  let string = moment(date, "DD-MM-YYYY").format("D MMM YY");
  return string;
};

const getDaysDifference = (startDate, endDate) => {
  let StartDate = moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  let EndDate = moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  return moment(EndDate).diff(StartDate, "days") + 1;
};



export {
  getFormattedDate,
  getFormattedTime,
  getFormattedDateTime,
  getFormattedDateFromNow,
  addDaysToDate,
  convertDMYToDateString,
  getDaysDifference,
};
