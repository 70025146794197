/* eslint-disable react/prop-types */
import React from 'react'
import QRCode from "react-qr-code"
import GenerateQrCode from '../../../hooks/fetch-hooks/generateQrCode'

export default function DrxQr({ id }) {
    const { data, isFetched } = GenerateQrCode({ orderId: id });

    return (
        <div className='' style={{ height: "auto", margin: "0 auto", maxWidth: 140, width: "100%" }}>
            {isFetched && < QRCode
                bgColor='transparent'
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={data?.data?.data}
                viewBox={`0 0 256 256`}
            />}
        </div>
    )
}
