/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import GetAllUsers from "../../../hooks/fetch-hooks/fetch-all/get-all-users";
import GetAddressByUserMappingId from "../../../hooks/fetch-hooks/get-address-by-userid/get-address-by-userid";
import GetPatientMappingsByUserId from "../../../hooks/fetch-hooks/get-patient-mappings-by-userid/get-patient-mappings-by-userid";
import { useModalMain } from "../../../contexts/modal";
import { TextField } from "../TextField";
import moment from "moment/moment";
import { request } from "../../../config/helpers/axios-instance";
const Constants = require("./Constants");
import { toast } from "react-toastify";
import classes from "../../../styles/MedicineForm.module.scss";
import CreateAddress from "../create-address";
import { useRef } from "react";

function CreateOrder () {
    const [searchUser, setsearchUser] = useState('');
    const [userName, setUserName] = useState('')
    const { data: users } = GetAllUsers(searchUser, isNaN(searchUser) ? 'name' : 'phone');
    const [stopFetch, setStopFetch] = useState(false)
    const [filteredUsers, setFilteredUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(0);
    const { data: patients, refetch } = GetPatientMappingsByUserId(!selectedUser ? 0 : selectedUser);
    const [filteredPatients, setFilteredPatients] = React.useState([]);
    const [selectedPatient, setSelectedPatient] = React.useState(0);
    const { data: patientAddress, refetch: AddressFetch } = GetAddressByUserMappingId(selectedPatient);
    const [patientId, setPatientId] = React.useState(0);
    const [addresses, setAddresses] = React.useState([]);
    const { closeModal, openModal } = useModalMain()
    const [addressesID, setAddressesID] = React.useState(0);
    const [prescriptionPayload, setPrescriptionPayload] = React.useState(
        Constants.prescriptionInitialValues
    );
    const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
    const [selectedUserdata, setSelectedUserData] = useState();

    React.useEffect(() => {
        setFilteredPatients([]);
        refetch();
    }, [selectedUser]);

    useEffect(() => {
        AddressFetch()
    }, [selectedPatient])

    React.useEffect(() => {
        if (patientAddress?.data?.data?.length > 0) {
            let patientAddresses = patientAddress?.data?.data?.map((item) => {
                return {
                    label: item.line,
                    id: item.id,
                };
            });
            // add patient address to the list of addresses until the user or patient changes and also check if the id is not already present
            setAddresses((prev) => {
                let newAddresses = [...prev, ...patientAddresses];
                let filteredAddresses = newAddresses.filter(
                    (item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                );
                return filteredAddresses;
            });
        } else {
            setAddresses([]);
            setAddressesID(0);
        }
    }, [patientAddress]);

    React.useEffect(() => {
        if (users && !stopFetch) {
            setFilteredUsers(
                users?.data?.data?.rows.map((user) => {
                    return { label: user.name, id: user.id, phone: user.phone, drName: user.partnerUsers ? user.partnerUsers[0]?.partner?.type === 'DOCTOR' ? user.partnerUsers[0].partner.name : '' : '' };
                })
            );
        }
    }, [users]);

    React.useEffect(() => {
        if (patients && patients?.data?.data?.rows?.length > 0) {
            setFilteredPatients(
                patients?.data?.data?.rows?.map((item) => {
                    return {
                        label: item.Patient.name,
                        patientId: item.Patient.id,
                        id: item.id,
                        relationship: item.relationship,
                    };
                })
            );
        }
    }, [patients]);

    React.useEffect(() => {
        setSelectedPatient(filteredPatients[0]?.id);
        setPatientId(filteredPatients[0]?.patientId);
    }, [filteredPatients]);

    async function trackingImages (files) {

        if (files.length === 0) return [];

        let formData = new FormData();
        for (let i in files) {
            formData.append('files', files[i]);
        }

        let resUploadFiles = await request({
            url: "/file/upload/prescription/",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })

        return resUploadFiles?.data?.data.map((item) => item.image || item.pdf);
    }
    // Prescription changes Here

    const onChangePrescription = (e) => {
        setPrescriptionPayload((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    // OnSubmit Starts Here
    const onSubmit = async (values, { resetForm }) => {

        try {
            if (!addresses.length > 0 || addressesID === 0) {
                toast.error("Please add address first")
                return
            }

            prescriptionPayload["userMappingId"] = selectedPatient
            prescriptionPayload["userId"] = selectedUser

            let imagesArray = await trackingImages(values.image);
            prescriptionPayload.links = imagesArray || [];

            let prescription = await request({
                method: "POST",
                url: "prescription/admin",
                data: prescriptionPayload,
            });

            if (!prescription?.data.success) {
                toast.error(prescription?.data?.error || "Something went wrong")
                return
            }

            if (prescription?.data?.success) {
                values['prescriptionId'] = prescription?.data?.data?.id;

                try {
                    const body = {
                        addressId: parseInt(addressesID),
                        userMappingId: parseInt(selectedPatient),
                        prescriptionId: parseInt(values.prescriptionId),
                        status: values.status,
                        noOfDays: parseInt(values.noOfDays),
                        startDate: values.startDate,
                        endDate: values.endDate,
                        notes: values.notes,
                    }

                    let response = await request({ method: "POST", url: "/userOrder/admin", data: body });


                    if (response.data.success) {
                        toast.success("Success")
                        resetForm({ values: '' })
                        closeModal()
                    }
                    if (!response.data.success) {
                        toast.error(response.data.error)
                    }
                }

                catch (error) {
                    toast.error("Wrong data")
                }
            }
        }
        catch (err) {
            toast.warn(err)
        }
    };

    // Handing search
    const handleSearch = (e) => {
        setUserName(e.target.value)
        if (isNaN(e.target.value)) {
            setsearchUser(e.target.value)
            setStopFetch(false)
            setSelectedUser(e.target.id);
        }
        else if (e.target.value.length === 10) {
            setsearchUser(e.target.value)
            setStopFetch(false)
            setSelectedUser(e.target.id);
        }

    }
    function openAddressForm () {
        setIsAddressFormOpen(true);
    }
    function toggleAddressCreateForm () {
        setIsAddressFormOpen(!isAddressFormOpen);
    }

    function handlePatientClick (e) {
        let value = filteredPatients[parseInt(e.target.value)]
        setSelectedPatient(value.id);
        setPatientId(value.patientId);
    }

    return (
        <div className="pb-4">
            {isAddressFormOpen && < CreateAddress selectedUser={selectedUserdata} toggle={toggleAddressCreateForm} fetchAddress={AddressFetch} />}
            {!isAddressFormOpen && < Formik initialValues={Constants.initialValues} onSubmit={onSubmit}>
                {(props) => (
                    <Form>
                        <div className="py-2 px-4 flex justify-between items-center">
                            <h1 className="font-bold font-sans text-3xl text-white-900">
                                New Order
                            </h1>
                            <input
                                type="date"
                                name="date"
                                required
                                onChange={onChangePrescription}
                                defaultValue={prescriptionPayload.date}
                                className="border-white-z500 border-2 w-1/3 p-2 rounded-2xl font-semibold focus:outline-none active:outline-none font-sans text-sm"
                            />
                        </div>

                        <div className="mt-4 grid grid-cols-1 gap-4 items-center p-6 py-0 focus:outline-1 peer-active:outline-1">
                            {/* Dynaming Searching*/}
                            <div className="border-white-500 p-2 border-2 rounded-2xl">
                                <h6 className="font-sans text-xs ">User Name</h6>
                                <input type="text" autoComplete="off" name="user" className={classes.inputSearch2} value={userName} onChange={handleSearch} required={true} />
                                {filteredUsers.length > 0 && userName.length > 1 && (
                                    <>
                                        <div className={classes.Dropdown3}>
                                            <ul>
                                                {filteredUsers.length > 0 && filteredUsers.map((val, id) => {
                                                    return (
                                                        <li key={id} className='cursor-pointer' onClick={() => { setUserName(val.label), setFilteredUsers([]), setSelectedUser(val.id), setStopFetch(true), setSelectedUserData({ name: val.label, phone: val.phone, id: val.id }) }}><b>{val.id}</b>: {val.label} - {val.phone}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* Dynamic Searching Ends Here+= */}
                            {filteredPatients?.length > 0 && (
                                <div className="border-white-500 p-2 border-2 rounded-2xl">
                                    <h6 className="font-sans text-xs ">Patient</h6>
                                    <Field as="select" value={filteredPatients.findIndex((item) => item.id === selectedPatient)} onChange={handlePatientClick} name="patient" required className="w-full h-[2rem] p-0 m-0 focus:outline-none active:outline-none border-none active:border-none focus:border-none focus:ring-0 appearance-none">
                                        {filteredPatients.map((value, key) => {
                                            return (
                                                <>
                                                    <option key={key} value={key} onClick={() => {
                                                    }} >{value.label}
                                                        {"    - "}
                                                        {value.relationship}</option>
                                                </>
                                            )
                                        })}
                                    </Field>
                                </div>
                            )}
                            {addresses?.length > 0 && (
                                <div className="border-white-500 p-2 border-2 rounded-2xl">
                                    <h6 className="font-sans text-xs">Shipping Address</h6>
                                    { }
                                    <Field as="select" name="address" required className="w-full h-[2rem] p-0 m-0 focus:outline-none active:outline-none border-none active:border-none focus:border-none focus:ring-0 appearance-none">
                                        {addresses.map((value, key) => {
                                            return (
                                                <>
                                                    <option key={key} value={value.id} onClick={setAddressesID(value.id)}>{value.label}</option>
                                                </>
                                            )
                                        })}
                                    </Field>
                                </div>
                            )}
                            {filteredPatients?.length > 0 && <button type="button" className="bg-elm-600 rounded-2xl p-2 m-2 text-white-50 font-semibold" onClick={() => { openAddressForm() }}>Create Address</button>}
                            <div className="">
                                <TextField
                                    label="Doctor Name"
                                    name="drName"
                                    required
                                    onChange={onChangePrescription}
                                    type="text"
                                    placeholder="Enter Doctor Name"
                                />
                            </div>
                        </div>

                        <div className="mt-4 grid grid-cols-1 gap-4 items-center p-6 py-0">
                            <div className="grid grid-cols-5  gap-2 items-center">
                                <div className="col-span-2">
                                    <TextField label="Start Date" name="startDate" type="date" onChange={(e) => {
                                        props.setFieldValue('startDate', e.target.value)
                                        props.setFieldValue(
                                            "endDate",
                                            moment(e.target.value)
                                                .add(props.values.noOfDays - 1, "days")
                                                .format("YYYY-MM-DD")
                                        );
                                    }} />
                                </div>
                                <div className="">
                                    <TextField
                                        label="Duration"
                                        name="noOfDays"
                                        type="number"
                                        required
                                        value={props.values.noOfDays}
                                        onChange={(e) => {
                                            props.setFieldValue("noOfDays", e.target.value);
                                            props.setFieldValue(
                                                "endDate",
                                                moment(props.values.startDate)
                                                    .add(e.target.value - 1, "days")
                                                    .format("YYYY-MM-DD")
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <TextField
                                        label="End Date"
                                        name="endDate"
                                        type="date"
                                        required
                                        value={props.values.endDate}
                                        // Update end date when start date is changed
                                        onChange={(e) => {
                                            props.setFieldValue("endDate", e.target.value);
                                            props.setFieldValue(
                                                "noOfDays",
                                                moment(e.target.value).diff(
                                                    moment(props.values.startDate),
                                                    "days"
                                                ) + 1
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 p-6 py-0">
                            <TextField
                                label="Notes"
                                name="notes"
                                type="text"
                                placeholder="Add a Note...."
                            />
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-4 items-center p-6 py-0">
                            <div className="flex justify-center">
                                <div className="w-full rounded-2xl">
                                    <h6 className="font-sans text-sm font-medium mb-1">
                                        Upload Prescription
                                    </h6>
                                    <input
                                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white-50bg-clip-padding rounded-2xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
                                        type="file"
                                        name="image"
                                        id="image"
                                        multiple
                                        onChange={(event) => {
                                            props.setFieldValue("image", Array.from(event.target.files));
                                        }}
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full h-[3rem] bg-elm-600 font-sans text-white-50 font-semibold py-2 rounded-2xl hover:bg-elm-700">
                                Create Order
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>}
        </div>
    );
}


// }

export { CreateOrder };
