import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";


const GetPatientMappingsByUserId = (userId) => {
    return useQuery(
        ["get-patient-mappings-by-userid", userId],
        () =>
            request({
                url: `/mapUserPatient/admin/user/${userId}`,
                method: "GET",
            }),
        {
            enabled: !!userId,
            refetchOnWindowFocus: false,
        }
    );
};

export default GetPatientMappingsByUserId;
