import * as yup  from "yup";

export const initial = {
    name: "",
    medicineType: "",
    manufacture: "",
    genericName: "",
    shape: "",
    color: "",
    size: "",
    mrp:"",
    inventoryId:"",
}

export const schema = yup.object().shape({
    name: yup.string().required('Name Is Required'),
    manufacture: yup.string().required('Manufracture Is Required'),
    genericName: yup.string().required('Generic Name Is Required'),
    shape: yup.string().required('Shape Is Required'),
    color: yup.string().required('Color Is Required'),
    size: yup.number().required('Size Is Required'),
    mrp: yup.string().required('MRP Is Required'),
    medicineType: yup.string().required('Type Is Required'),
    inventoryId: yup.string().required('Medicine Id Is Required'),
    // image: yup.mixed().required('File is required')
    
});

export const TypeOptions = ["Select Tablet","Tablet", "Capsule", "Injection", "Sachet", "Box", "Syrup", "Powder", "Cream", "Ointment"]
export const ShapeOptions = ["Select Shape","Circular", "Rectangle", "Cylindrical"]