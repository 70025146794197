import { downloadExcel } from 'react-export-table-to-excel';

function DownLoadTable(FileName, SheetName, TableHeader, TableData) {
    downloadExcel({
        fileName: FileName,
        sheet: SheetName,
        tablePayload: {
            header: TableHeader,
            body: TableData
        },
    })
}
export { DownLoadTable }
