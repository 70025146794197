import React, { useState } from 'react'
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// import { invoiceRequest } from '../../hooks/fetch-hooks/invoice-calls/invoice';
import { request } from '../../config/helpers/axios-instance';
import { useModalMain } from '../../contexts/modal';


function InvoiceForm (id) {
    const [value, setvalue] = useState('')
    const { closeModal } = useModalMain()

    async function Submit (e, id) {
        e.preventDefault()
        const body = {
            eVitalRXOrderNo: value,
            userOrderId: id
        }
        const response = await request({
            method: "POST",
            url: `evitalrx/userorder`,
            data: body
        })

        if (response?.data?.success) {
            toast.success("Request Successful");
            closeModal()
        }
        if (!response?.data?.success) {
            toast.error(response?.data?.error || "Something went wrong")
        }
    }

    return (
        <>
            <div className='p-5'>
                <h1 className='text-3xl text-black my-3 font-bold'>Invoice Generation</h1>
                <form onSubmit={(e) => Submit(e, id)}>
                    <input type="text" required={true} minLength={7} placeholder='Order Number' value={value} onChange={(e) => setvalue(e.target.value)} className=" w-full border-2 border-white-500 rounded-2xl mb-3" />
                    <button type='submit' className="w-full bg-elm-500 text-white-100 hover:bg-elm-700 font-semibold font-sans py-2 rounded-2xl">Submit</button>
                </form>
            </div>
        </>
    )
}

export default InvoiceForm

InvoiceForm.propTypes = {
    id: PropTypes.number
}