import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";


const GetAllUserstable = (pageindex, pagelimit, search, order, orderBy) => {
    return useQuery(
        ["get-all-users"],
        () =>
            request({
                url: `user/admin/`,
                method: "GET",
                params: (search.length === 10 && !isNaN(search)) ? 
                 {
                    page: pageindex,
                    size: pagelimit,
                    phone: search,
                    order: order,
                    orderBy: orderBy
                }:
                 {
                    page: pageindex,
                    size: pagelimit,
                    name: search,
                    order: order,
                    orderBy: orderBy
                }
            }),
    );
};

export default GetAllUserstable;