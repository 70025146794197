
const PARTNER_DETALIS_COLUMNS = [
    {
        Header: "Name",
        accessor: "partner.name",
    },
    {
        Header: "Type",
        accessor: "partner.type",
    },

];
const ADDRESS_DETAILS_COLUMNS = [
    {
        Header: "Line",
        accessor: "line",
    },
    {
        Header: "District",
        accessor: "district",
    },
    {
        Header: "State",
        accessor: "state",
    },
    {
        Header: "PinCode",
        accessor: "pincode",
    }
];
const PRESCRIPTIONS_COLUMNS = [
    "Date", "Dr. Name", "Prescriptions"
]


export { PARTNER_DETALIS_COLUMNS, ADDRESS_DETAILS_COLUMNS, PRESCRIPTIONS_COLUMNS };