import Cookies from "js-cookie";
import React from "react";
import { useNavigate } from "react-router-dom";
import imageNotFound from "../../../src/assets/404.png"

function NoPageFound () {
    const naviagte = useNavigate();
    return (
        <div className="w-full h-[100vh] bg-elm-300 flex flex-col justify-center items-center gap-5">
            <img src={imageNotFound} className="w-[15%]" />
            <h1 className="text-5xl max-md:text-2xl font-bold">
                Oops
            </h1>
            <p className="text-2xl max-ms:text-lg">
                You have got a broken link!
            </p>
            <button
                onClick={() => {
                    Cookies.get("token") ? naviagte("/") : naviagte("/login");
                }}
                href="/login"
                className="border border-slate-200 rounded-2xl py-2 px-3 text-xl font-semibold bg-[white]">
                {Cookies.get("token") ? `Back to Home` : `Back to Login`}
            </button>
        </div>
    );
}

export default NoPageFound;
