const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const getBaseURL = () => {
    return BACKEND_URL;
};

export const getApiURL = (url) => {
    if (url) {
        return getBaseURL() + url;
    }
    return getBaseURL();
};