export const getRealtionshipTag = (id) => {

  const relationshipTag = {
    0: "",
    1: "Father",
    2: "Mother",
    3: "Brother",
    4: "Sister",
    5: "Son",
    6: "Daughter",
    7: "Other",
  };
  return relationshipTag[id];
};
