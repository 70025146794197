import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";

const GetUserDetails = (id) => {
    return useQuery(

        ["get-user-details", id],
        () =>
            request({
                url: `/user/admin/id/${id}`,
                method: "GET",
            }),
        {
            enabled: !!id,
        }
    );
};

export default GetUserDetails;
