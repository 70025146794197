import { request } from "../../../config/helpers/axios-instance";


const GetAllOrders = (params, queryParams) => {
    return request({
        url: `userOrder/admin${queryParams}`,
        method: "GET",
        params:
            params
    })
}

export default GetAllOrders;
