import BREAKFAST from "../../assets/morning.png"
import LUNCH from "../../assets/afternoon.png"
import SNACK from "../../assets/evening.png"
import DINNER from "../../assets/night.png"

const DAY_PARTS = [
    "BREAKFAST",
    "LUNCH",
    "SNACK",
    "DINNER",
    "CUSTOM",
]


const DAY_IMAGES = {
    "BREAKFAST": BREAKFAST,
    "LUNCH": LUNCH,
    "SNACK": SNACK,
    "DINNER": DINNER,
}
export { DAY_PARTS, DAY_IMAGES }
