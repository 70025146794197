import { ErrorMessage } from "formik";
import { Form } from "formik";
import { Formik } from "formik";
import { Field } from "formik";
import React from "react";
// import { ReOrderSchema } from "../../../../Schema/re-order-schema";
import PropTypes from "prop-types";
import "../../styles/ItemForms.scss";
import PrescriptionItems from "./items-form";
import { addDaysToDate } from "../../config/helpers/date-functions";

function ReOrderForm ({ formData, oldOrderDetails, action }) {
    const [step, setStep] = React.useState({
        step1: true,
        step2: false,
    });

    const [newOrderDetails, setNewOrderDetails] = React.useState({});

    const intialvalues = {
        ...oldOrderDetails,
        rxLink: "",
        ocsFile: "",
        reOrder: 0,
        startDate: addDaysToDate(oldOrderDetails.endDate, 1),
        noOfDays: oldOrderDetails.noOfDays,
    };

    const onSubmit = async (values) => {
        const response = {
            ...values,
            endDate: addDaysToDate(values.startDate, values.noOfDays - 1),
        };
        setNewOrderDetails(response);
        nextStep();
    };

    const previousStep = () => {
        setStep({ step1: true, step2: false });
    };
    const nextStep = () => {
        setStep({ step1: false, step2: true });
    };
    return (
        <div className="p-6">
            {step.step1 && (
                <Formik initialValues={intialvalues} onSubmit={onSubmit}>
                    {() => (
                        <Form>
                            <h1 className="text-4xl font-bold my-4 font-sans repeat_order text-left">
                                Repeat Order
                            </h1>
                            <div className="grid grid-cols-2 gap-4 justify-center">
                                <div className="flex-col flex justify-center">
                                    <label htmlFor={`startDate`}>Start Date</label>
                                    <Field
                                        className="focus:outline-none p-2 border rounded-2xl"
                                        name={`startDate`}
                                        placeholder={new Date()}
                                        type="date"
                                    />
                                    <ErrorMessage
                                        name={`startDate`}
                                        component="div"
                                        className="field-error"
                                    />
                                </div>

                                <div className="flex-col  flex justify-center">
                                    <label htmlFor={`noOfDays`}>Duration</label>
                                    <Field
                                        className="focus:outline-none p-2 border rounded-2xl"
                                        name={`noOfDays`}
                                        placeholder={"noOfDays"}
                                        type="number"
                                    />
                                    <ErrorMessage
                                        name={`startDate`}
                                        component="div"
                                        className="field-error"
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <Field name="notes">
                                    {({ field, form: { touched }, meta }) => (
                                        <div>
                                            <textarea
                                                type="text"
                                                placeholder="Type your notes here..."
                                                {...field}
                                                rows={3}
                                                className="focus:outline-none p-2 border rounded-2xl w-full h-[15vh] max-h-[15vh]"
                                            />
                                            {touched && meta.error && (
                                                <div className="error">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="mt-4 p-2 px-4 orderbtn rounded-2xl text-white-50 font-sans">
                                    Next Step
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}

            {/* TOOD: Steps with 0,1 convention */}
            {step.step2 && (
                <PrescriptionItems
                    formData={formData}
                    newOrderDetails={newOrderDetails}
                    previousStep={previousStep}
                    action={action}
                    oldOrderDetails={oldOrderDetails}
                />
            )}
        </div>
    );
}

ReOrderForm.propTypes = {
    formData: PropTypes.any,
    oldOrderDetails: PropTypes.object,
    action: PropTypes.func,
};

export default ReOrderForm;
