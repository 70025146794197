import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from "./redux/app/store";
import App from "./App";
import "./index.scss";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider } from './contexts/modal';
import { AddressModalProvider } from './contexts/address-modal/AddModal';

const container = document.getElementById("root");
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <ModalProvider>
                <AddressModalProvider>
                    <App />
                </AddressModalProvider>
            </ModalProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </Provider>
    </QueryClientProvider>
);