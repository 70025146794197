import React, { useRef } from 'react'
import PropTypes from "prop-types";
import { generateOcs } from '../../utils/ocs-generator';

export default function PartnerName(OrderData, AddcloseModal) {
    const partnerNameRef = useRef();
    return (
        <div className='flex flex-col gap-4 mt-2'>
            <input ref={partnerNameRef} className='p-4 focus:outline-none placeholder:opacity-50  h-10 border-gray-200 border w-full' placeholder='Partner Name' defaultValue={OrderData?.partner ? OrderData.partner.name : ''}></input>
            <button className="text-lg p-2 w-full font-medium font-sans shadow-soft-xl rounded-lg bg-[#902b8b] text-white-50" onClick={() => { generateOcs(OrderData, partnerNameRef.current.value); AddcloseModal(); }}>OCS</button>
        </div>
    )
}

PartnerName.propTypes = {
    OrderData: PropTypes.string,
    AddcloseModal: PropTypes.any
}
