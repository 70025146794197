import { createSlice } from '@reduxjs/toolkit';


const state = {
    totalpage: 0,
    pageIndex: 1
}

export const PageSlice = createSlice({
    name: "page",
    initialState: state,
    reducers: {
        increase: (state) => {
            return {
                pageIndex: state.pageIndex + 1,
                totalpage: state.totalpage
            }
        }
    }
})

export const { increase } = PageSlice.actions;
export default PageSlice.reducer