import React from "react";

import { Cohort } from "./components/charts/cohort";
import { OrdersCount } from "./components/charts/ordersCount";
import { UserAges } from "./components/charts/userAge";

function Home () {
    return (
        <div className="p-5 flex flex-col mb-5">
            <div className="flex flex-row gap-5">
                <Cohort></Cohort>
            </div>
            <div className="flex flex-col gap-0">
                <OrdersCount></OrdersCount>
            </div>
            <div className="flex flex-col gap-5">
                <UserAges></UserAges>
            </div>
        </div>
    );
}

export default Home;
