import React, { useMemo } from "react";
import TableLayout from "./table-layout";
import PropTypes from "prop-types";
import { useTable } from "react-table";
const Constants = require("./Constants");

function TableInstance ({ tableData, changeRightBarOpen }) {
    const [columns, data] = useMemo(() => {
        const columns = Constants.COLUMNS;
        return [columns, tableData];
    }, [tableData]);
    const tableInstance = useTable({ columns, data });
    return (
        <>
            <TableLayout {...tableInstance} changeRightBarOpen={changeRightBarOpen} />
        </>
    );
}

export { TableInstance };

// props validation
TableInstance.propTypes = {
    tableData: PropTypes.array.isRequired,
    changeRightBarOpen: PropTypes.func.isRequired,
};
