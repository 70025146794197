import React from "react";
import PropTypes from "prop-types";
import { IoTimeSharp } from "react-icons/io5";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import ReactModal from "react-modal";
import ReOrderForm from "./reorder-form";
import GetMedsByOrderId from "../../hooks/fetch-hooks/get-meds-by-order-id/get-meds-by-order-id";


const customStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
};

function ReOrderModal ({ action, value, id, oldOrderDetails }) {
    const { data } = GetMedsByOrderId(id);
    const { endDate, noOfDays } = oldOrderDetails;

    return (
        <>
            {data && (
                <ReactModal
                    isOpen={value}
                    ariaHideApp={false}
                    style={customStyles}
                    contentLabel="Minimal Modal Example"
                    className="w-[70vw] h-auto max-h-[70vh] bg-white-50 shadow-soft-xl  mt-[10vh] rounded-xl m-auto overflow-auto"
                    onRequestClose={action}
                    shouldCloseOnOverlayClick={true}>
                    <div className="flex justify-between items-center  p-4 rounded-2xl ">
                        <div className="flex gap-8 items-center">
                            <h1 className="font-medium text-xl font-sans">
                                Old Order Details{" "}
                            </h1>
                            <h3 className="flex items-center gap-2 font-sans">
                                <BsFillCalendarDateFill fontSize={"25px"} />
                                {new Date(endDate).toDateString()}
                            </h3>
                            <h3 className="flex items-center gap-2 font-sans">
                                <IoTimeSharp fontSize={"25px"} color="#EC4E20" />
                                {noOfDays} Days
                            </h3>
                        </div>
                        <button
                            onClick={action}
                            className="hover:bg-black hover:text-[#fff]  p-1 rounded-2xl font-sans">
                            <VscChromeClose fontSize={"1.2rem"} />
                        </button>
                    </div>
                    <ReOrderForm
                        formData={data}
                        oldOrderDetails={oldOrderDetails}
                        action={action}
                    />
                </ReactModal>
            )}
        </>
    );
}

ReOrderModal.propTypes = {
    action: PropTypes.func,
    value: PropTypes.bool,
    id: PropTypes.any,
    oldOrderDetails: PropTypes.object,
};

export default ReOrderModal;
