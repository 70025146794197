import { request } from "../../../config/helpers/axios-instance";

const AddPrescriptionItem = async (data) =>
    await request({
        url: `/prescriptionItem/admin`,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: data

    })
export { AddPrescriptionItem };