/* eslint-disable react/prop-types */
import React from 'react'
import { useState } from 'react'
import { GrAdd } from 'react-icons/gr'
import NameAndBatchModal from './NameBatchModal';
import { useEffect } from 'react';

export default function Footer ({ updateItem, addNewItem }) {
    const [ModalShow, setModalShow] = useState(false);
    function modalClose () {
        setModalShow(false);
    }

    const handleShortcut = (e) => {
        if (e.keyCode === 32 && e.ctrlKey) {
            setModalShow((prevState) => { return !prevState })
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleShortcut)
        return () => {
            document.removeEventListener('keydown', handleShortcut)
        }
    }, [handleShortcut])

    return (
        <div className='px-10 py-5 rounded-lg bg-[white] flex items-center justify-between' >
            <button onClick={() => setModalShow(true)} className='p-3 rounded-lg bg-[#05907A]/[0.17] hover:bg-[#05907A]/[0.3]' ><GrAdd /></button>
            {ModalShow && <NameAndBatchModal type="Add" AddNewItem={addNewItem} updateItem={updateItem} onClose={modalClose} />}
        </div>
    )
}
