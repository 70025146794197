import React from 'react'
import { IoMdAddCircle } from 'react-icons/io'
import { DAY_IMAGES } from '../Constants'


// eslint-disable-next-line react/prop-types
export default function UserPreferedTimingContainer({ dayPart, time, AddMedicineTiming }) {
    return (
        <div className='flex flex-row gap-2 border border-gray-300 px-2 py-1  rounded-lg items-center' onClick={() => AddMedicineTiming(time)}>
            {dayPart === 'CUSTOM' ? <p className='text-blue-500 font-bold'>{dayPart}</p> : <img src={DAY_IMAGES[dayPart]} className='h-8'></img>}
            <p className='text-[black]'>{time}
            </p>
            <IoMdAddCircle className='text-2xl text-[black] cursor-pointer' />
        </div>
    )
}