import { request } from "../../../config/helpers/axios-instance";

const GetPatientAddressById = async (id) =>
    await request({
        url: `address/admin/mapUserPatient/`+id,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },

    })



export { GetPatientAddressById };