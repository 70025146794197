import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";

export const GetPartners = (type, name) => {
    return useQuery(["get-partners", type, name], () => {
        return request({
            url: `/partner/admin?type=${type}&name=${name}`,
            method: "GET"
        })
    }, {
        enabled: false
    })
}