import React from "react";
import morning from "../../../../assets/morning.png";
import evening from "../../../../assets/evening.png";
import afternoon from "../../../../assets/afternoon.png";
import night from "../../../../assets/night.png";
import pillup from "../../../../assets/logo.png";
import { Rows } from "./rows";
const Constants = require("./Constants");
import PropTypes from "prop-types";

import { convertDMYToDateString } from "../../../../config/helpers/date-functions";
const customStyles = Constants.STYLES;
function PRXTable (props) {
    const { startDate, endDate, rows, drName, patientName, total } = props;
    return (
        <div id="prx-table" className="h-full  w-[1050px]  pl-6 mt-[-60px]">
            <h2
                className="text-xl  font-semibold font-sans text-left"
                style={customStyles.date}>
                {convertDMYToDateString(startDate)} to <br />
                {convertDMYToDateString(endDate)}
            </h2>
            <img
                src={pillup}
                alt="pillup logo"
                className="text-2xl  font-black font-sans  text-left"
                style={customStyles.pillupLogo}
            />

            <table className="w-[1000px] border border-solid  mr-3 ">
                <tr className="bg-[#dbdbdb] w-[250px] h-32 border border-solid">
                    <th className="h-full border border-solid">
                        <div className="text-left px-4 flex flex-col gap-2">
                            <h2 className="text-2xl"> {patientName}</h2>

                            <h2 className="text-md  text-gray-600 capitalize">{drName}</h2>
                        </div>
                    </th>
                    <th className="border border-solid">
                        <div className="flex flex-col items-center">
                            <img src={morning} alt="morning" className="object-fit w-[65%]" />
                            <h2 className="font-sans">Morning</h2>
                        </div>
                    </th>
                    <th className="border border-solid">
                        <div className="flex flex-col items-center">
                            <img
                                src={afternoon}
                                alt="afternoon"
                                className="object-fit w-[60%]"
                            />
                            <h2 className="font-sans">Afternoon</h2>
                        </div>
                    </th>
                    <th className="border border-solid">
                        <div className="flex flex-col items-center">
                            <img src={evening} alt="evening" className="object-fit w-[78%]" />
                            <h2 className="font-sans">Evening</h2>
                        </div>
                    </th>
                    <th className="border border-solid">
                        <div className="flex flex-col items-center">
                            <img src={night} alt="night" className="object-fit w-[60%]" />
                            <h2 className="font-sans">Night</h2>
                        </div>
                    </th>

                    <th className="">
                        <div className="flex  justify-center items-end h-24">
                            <h2 className="font-sans">Qty</h2>
                        </div>
                    </th>
                    <th className="">
                        <div className="flex  justify-center items-end h-24">
                            <h2 className="font-sans">Per Pill</h2>
                        </div>
                    </th>
                    <th className="w-[30px] ">
                        <div className="flex  justify-center items-end h-24 ">
                            <h2 className="font-sans">Discount</h2>
                        </div>
                    </th>
                    <th className="p-1 ">
                        <div className="flex items-end justify-center h-24">
                            <div className="flex flex-col items-center justify-between">
                                <h2 className="mt-1 text-left font-sans">Total</h2>
                            </div>
                        </div>
                    </th>
                </tr>
                {rows.map((row, index) => (
                    <Rows {...row} key={index} />
                ))}
                <tr>
                    <td colSpan="9" className="text-right p-2">
                        <div className="flex justify-end items-center mb-1">
                            <h2 className="text-lg font-sans font-bold">Total</h2>
                            <h2 className="text-lg ml-4 font-sans font-bold mr-3">
                                ₹{total}
                            </h2>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    );
}

PRXTable.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    rows: PropTypes.array,
    drName: PropTypes.string,
    patientName: PropTypes.string,
    total: PropTypes.any,
};
export { PRXTable };
