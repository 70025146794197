import React from "react";
const Auth = React.createContext(null);
const FutureOrdersData = React.createContext(null);
const OrderDetailsData = React.createContext(null);
const OldOrderDetailsData = React.createContext(null);
const MedicinesRowData = React.createContext(null);
const MapOrderInvoice = React.createContext(null);
const UsersData = React.createContext(null);
const MedicineDeatilsData = React.createContext(null);
const CallBackContext = React.createContext(null);

export {
  FutureOrdersData,
  OrderDetailsData,
  MedicinesRowData,
  Auth,
  OldOrderDetailsData,
  MapOrderInvoice,
  UsersData,
  MedicineDeatilsData,
  CallBackContext
};
