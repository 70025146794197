import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";


const GetAllUsers = (value, searchType) => {
    return useQuery(
        ["get-all-users", value],
        () =>
            request({
                url: `user/admin`,
                method: "GET",
                params: searchType === 'name' ? {
                    name: value
                } : {
                    phone: value
                }
            }),
    );
};

export default GetAllUsers;