import { request } from "../../../config/helpers/axios-instance";

const UpdateOrder = async (data, id) =>

    await request({
        url: `userOrder/admin/id/` + id,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        data: data

    })
export { UpdateOrder };