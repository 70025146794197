import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";

const GetPrx = (orderId) => {
    return useQuery(
        ["get-prx", orderId],
        () =>
            request({
                url: `userOrder/admin/orderDetails/${orderId}`,
                method: "GET",
            }),
        {
            enabled: !!orderId,
        }
    );
};

export default GetPrx;
