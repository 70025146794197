/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PrescriptionLogo from '../../../assets/Prescription.png'
import InvoiceLogo from '../../../assets/invoice1.png'
import { TbHeartPlus } from 'react-icons/tb'
import { GrLocationPin } from 'react-icons/gr'
import './Header.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment } from 'react';
import PrescriptionModal from './PrescriptionModal';
import moment from 'moment';
import { generateOcs } from '../../../utils/ocs-generator';
import { request } from '../../../config/helpers/axios-instance';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal'
import SelectAddress from '../../../components/SelectAddress';
import { CustomModalForm } from '../../../components/modal-forms';
import CreateAddress from '../../../components/modal-forms/create-address';
import useModal from '../../../hooks/custom-hooks/useModal';


const Header = ({ userData, updatePrescription, refetch }) => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
    const [modal, setModal] = useState(false);
    const handleShowPrescriptionModal = () => setShowPrescriptionModal(true);
    const handleClosePrescriptionModal = () => { setShowPrescriptionModal(false) };
    const { isShowing: isCreateFormShowing, toggle: toggleCreateAddressForm } = useModal();
    const handleOcsClick = async () => {
        try {
            let orderData = await request({
                url: `userOrder/admin/orderDetails/${orderId}`,
                method: "GET",
            });
            generateOcs(orderData?.data?.data)
            toast.success("OCS File Downloaded Successfully")
        } catch (err) {
            toast.error("Check order data and try again")
        }
    }
    function toggleModal() {
        setModal(!modal);
    }

    return (
        <Fragment>
            {modal && <Modal title={"Edit Address"} onClose={toggleModal}>
                <SelectAddress user={userData} orderId={orderId} closeModal={toggleModal} refetch={refetch} toggleCreateAddressForm={toggleCreateAddressForm} />
            </Modal>
            }
            <CustomModalForm
                isShowing={isCreateFormShowing}
                hide={toggleCreateAddressForm}
                title="Create User"
                style={{ width: "60%", maxWidth: "60%" }}
                className="modal-form">
                <CreateAddress refetch={refetch} selectedUser={userData.User} toggle={toggleCreateAddressForm} optional={toggleModal} />
            </CustomModalForm>
            <PrescriptionModal updatePrescription={updatePrescription} onClose={handleClosePrescriptionModal} show={showPrescriptionModal} prescription={userData.prescription} />

            <div className='bg-[white] px-10 py-3 rounded-lg border border-gray-300' >
                <div className='flex flex-row flex-grow basis-0 justify-around items-center gap-4' >
                    <div className='header_order-number' >
                        #{orderId}
                    </div>
                    <div className='flex justify-center gap-3 flex-auto' >
                        <div className='relative flex justify-around items-center gap-3 border-2 px-4 py-3 rounded-lg'>
                            <div className='name-logo' >
                                {userData.User.name.split(' ')
                                    .map(word => word.charAt(0))
                                    .join('')
                                }
                            </div>
                            <div >
                                <strong className='text-md' >{userData.User.name}</strong>
                                <p className='text-sm'>{userData.User.phone}</p>
                                <p className='text-sm'>{moment().diff(moment(userData.User.dob), 'years') + ' years'}</p>
                                <p className='text-sm'>{userData.User.gender}</p>
                            </div>
                            <div className='absolute right-2 bottom-2' >
                                <TbHeartPlus />
                            </div>
                        </div>
                        <div className='flex justify-around items-center gap-3 border-2 px-8 py-3 rounded-lg' >
                            <div className='name-logo' >
                                {userData.Patient.name.split(' ')
                                    .map(word => word.charAt(0))
                                    .join('')
                                }
                            </div>
                            <div>
                                <strong className='text-md' >{userData.Patient.name}</strong>
                                <p className='text-sm'>{userData.Patient.phone}</p>
                                <p className='text-sm'>{moment().diff(moment(userData.Patient.dob), 'years') + ' years'}</p>
                                <p className='text-sm'>{userData.Patient.gender}</p>
                            </div></div>
                        <div className='border-2 px-8 py-3 rounded-lg relative'  >
                            {
                                userData?.address ? <div className='cursor-pointer' onClick={() => { setModal(true) }}>
                                    <strong>{userData?.address?.line}</strong>
                                    <p className='text-sm'>{userData?.address?.state}</p>
                                    <p className='text-sm'>{userData?.address?.district}</p>
                                    <p style={{
                                        border: '1px solid #049079',
                                        width: 'max-content'
                                    }} className='rounded-xl text-xs  px-2 py-1' >{userData?.address?.pincode}</p>
                                    <div className='absolute right-2 bottom-2' >
                                        <GrLocationPin />
                                    </div>
                                </div> : <p>No Address Found</p>
                            }

                        </div>
                    </div>
                    <div className='flex flex-row gap-x-10' >
                        <button onClick={handleShowPrescriptionModal} className={(userData?.prescription?.links?.length > 0 ? "" : "animate-bounce")}>
                            <img style={{ height: '60px' }} src={PrescriptionLogo} />
                        </button>
                        <a href={userData.evitalRx} target='_blank' rel="noreferrer" className={(userData?.evitalRx?.length > 0 ? "" : "animate-bounce")}>
                            <img style={{ height: '60px' }} src={InvoiceLogo} />
                        </a>
                    </div>
                    <div className='flex flex-row flex-grow flex-wrap basis-0 gap-5'>
                        <buton className="p-2 bg-elm-500 rounded-xl text-[white] text-center text-xl flex-auto cursor-pointer" onClick={() => handleOcsClick()} >Ocs</buton>
                        <buton className="p-2 bg-[#001524] rounded-xl text-[white] text-center text-xl flex-auto cursor-pointer" onClick={() => navigate(`/drx/${orderId}`)} >DRX</buton>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Header;