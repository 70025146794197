import { useQuery } from "react-query";
import { request } from "../../config/helpers/axios-instance";


const GenerateQrCode = (data) => {
    return useQuery(
        ["generate-qr-code"],
        () =>
            request({
                url: `/userOrder/admin/generateQRCodeURL`,
                method: "POST",
                data
            })

    );
};

export default GenerateQrCode;
