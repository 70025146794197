import React, { useEffect, useState } from "react";
import TableInstance from "./Table-instance";
import "../../../styles/Tableinstance.scss";
import { GetSortBy } from "../../../utils/sortby"
import { useSearchParams, useLocation } from "react-router-dom";
import { request } from "../../../config/helpers/axios-instance";
import "../../../styles/Instance.scss";


async function Response (pageindex, pagelimit, search, order, orderBy) {
    const response = await request({
        url: "item/admin",
        method: "GET",
        params: {
            page: pageindex,
            size: pagelimit,
            name: search,
            order: order,
            orderBy: orderBy
        }
    })
    return response
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};


function AllMedicines () {
    const query = useQuery();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    // Main States
    const [tableData, setTableData] = React.useState([]);
    const [total, setTotal] = useState(0);
    const [pageSort, setPageSort] = React.useState([])
    //  All States for query
    const [pageindex, setPageIndex] = React.useState(query.get('page') || 1);
    // eslint-disable-next-line
    const [pageSize, setPageSize] = React.useState(50);
    const [value, setValue] = React.useState(query.get('search') || "");
    const [order, setOrder] = React.useState(undefined)
    const [orderType, setOrderType] = React.useState(undefined);

    // Effect Starts Here
    useEffect(() => {
        if (pageSort.length > 0) {
            setOrder(pageSort[0].desc ? "DESC" : "ASC")
            setOrderType(GetSortBy(pageSort[0].id))
        }
        else if (pageSort.length < 1) {
            setOrder(undefined)
            setOrderType(undefined)
        }
    }, [pageSort])


    useEffect(() => {
        const getData = async () => {
            const fetchedData = await Response(pageindex, pageSize, value, order, orderType);
            const newTableData = fetchedData?.data?.data?.rows.map((order) => {
                return {
                    ...order,
                    daysLeft: Math.floor(
                        (new Date(order.endDate) - new Date()) / (1000 * 60 * 60 * 24) + 2
                    ),
                };
            });
            setTableData(newTableData);
            setTotal(fetchedData?.data?.data?.count);
        };

        getData();

    }, [pageindex, pageSize, value, order, orderType]);


    useEffect(() => {
        setSearchParams({
            page: pageindex,
            size: pageSize,
            search: value,
        })
    }, [pageindex, pageSize, value]);


    const maxValues = Math.floor(total / pageSize)


    return (
        <>
            <div className="flex gap-4 items-center">
                <input
                    value={value}
                    className="px-6 w-full font-sans bg-slate-100 py-4"
                    onChange={(e) => {
                        setValue(e.target.value);
                        setPageIndex(1)
                    }}
                    placeholder={`${total} records...`}
                    style={{
                        fontSize: "1.1rem",
                        border: "0",
                    }}
                />
            </div>

            <TableInstance
                tableData={tableData}
                pageCount={total}
                setPageSort={setPageSort}
                pageSort={pageSort}
            />
            {/* BTNS */}
            <div className="btn_div">
                <button onClick={() => setPageIndex((count) => Number(count) - 1)} disabled={pageindex <= 1} className="previous">
                    Previous
                </button>
                <span className="pageindex">
                    {tableData.length < 49 ? "Page 1" : `Page ${pageindex} of ${maxValues}`}
                </span>
                <button
                    onClick={() => setPageIndex((count) => Number(count) + 1)}
                    disabled={pageindex >= maxValues}
                    className="next"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default AllMedicines;
