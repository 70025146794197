import { useQuery } from "react-query";
import { request } from "../../../config/helpers/axios-instance";

const GetMedsByOrderId = (id) => {
  return useQuery(
    ["get-meds-by-order-id", id],
    () =>
      request({
        url: `prescriptionItem/admin/userOrder/${id}`,
        method: "GET",
      }),
    {
      enabled: !!id,
    }
  );
};

export default GetMedsByOrderId;
