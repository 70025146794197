import React from "react";
import PropTypes from "prop-types";

function TableLayout ({
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
}) {
    return (
        <div>
            {" "}
            <table key="future-orders" {...getTableProps()} className="w-full">
                <thead className="font-sans">
                    {headerGroups.map((headerGroup, id) => (
                        <tr
                            key={id}
                            {...headerGroup.getHeaderGroupProps()}
                            className=" h-auto">
                            {headerGroup.headers.map((column, id) => (
                                <th
                                    className="text-left font-sans"
                                    key={id}
                                    {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="hover:bg-slate-200 text-left font-sans odd:bg-slate-100 even:bg-white"
                                key={rowIndex}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <React.Fragment key={cellIndex}>
                                            {cell.column.Header === "Date" ? (
                                                <td
                                                    key={cellIndex}
                                                    {...cell.getCellProps()}
                                                    className="py-3 text-left font-sans">
                                                    {cell?.value?.split("T")[0]}
                                                </td>
                                            ) : (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="py-3 text-left  font-sans"
                                                    key={cellIndex}>
                                                    {cell.render("Cell")}
                                                </td>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

TableLayout.propTypes = {
    getTableProps: PropTypes.func.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    headerGroups: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    prepareRow: PropTypes.func.isRequired,
};

export default TableLayout;
