/* eslint-disable react/prop-types */
import React from 'react'
import PrescriptionItem from './PrescriptionItem';
import { validateOrderAndPrescriptionItems } from '../../../utils/orderUtils';
import { isNil } from 'lodash';

export default function PrescriptionItems ({ order, refetch, addItem, deleteItem, Items, updateItem }) {
    if (order && Items) {
        for (let key in Items) Items[key].messages = [];
        let verify = validateOrderAndPrescriptionItems(order, Items);
        if (!verify.success) {
            for (let message of verify.messages) {
                if (message.data?.id?.includes(':')) continue;
                if (isNil(message?.data)) continue;
                if (Items[message.data.id]?.messages?.length > 0) Items[message.data.id].messages.push(message)
                else Items[message.data.id].messages = [message]
            }
        }
    }

    return (
        <div id="prescriptionItems" className='grow flex flex-col gap-5'>
            {
                Object.keys(Items).sort((a, b) => { return parseInt(Items[a]?.itemId) - parseInt(Items[b]?.itemId) }).map((itemId) => {
                    return (
                        <PrescriptionItem refetch={refetch} addItem={addItem} deleteItem={deleteItem} updateItem={updateItem} key={itemId} itemId={itemId} Items={Items} userTimings={order.userTimings} />
                    )
                })
            }
        </div>
    )
}
